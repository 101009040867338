import { Checkbox, Col, Form, Modal, Row } from "antd";
import { MDBCard, MDBCol } from "mdb-react-ui-kit";
import { MDBDataTableV5 } from "mdbreact";
import moment, { duration } from "moment";
import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import AxiosInstance from "../../../../service/AxiosInstance";
// import MeBar from '../../me/MeBar';

const ProjectTotalHours = () => {
  const params = useParams();
  const [datatable, setDatatable] = useState({});
  const [departmentDatatable, setDepartmentDatatable] = useState({});

  const [datatable2, setDatatable2] = useState({});
  const [empId, setEmpId] = useState([]);
  const [ProjectWH, setProjectWH] = useState([]);
  const [value, setValue] = useState("");
  const [projectId, setProjectId] = useState(params.id);
  const [TotalHours, setTotalHours] = useState();
  const [visible, setVisible] = useState(false);
  const [formodaldynamics, setformodaldynamics] = useState();

  useEffect(() => {
    ProjectDATA();
  }, [empId]);

  const ProjectDATA = () => {
    const formData = new URLSearchParams();
    formData.append("Project_id", params.id);

    AxiosInstance.post("auth/tasks/timer-list-for-project", formData).then(
      async (res) => {
        // console.log(res.data.data, 322222)
        setProjectWH(res.data.data);
      }
    );
  };
  function msToTime(duration) {
    var seconds = Math.floor((duration / 1000) % 60),
      minutes = Math.floor((duration / (1000 * 60)) % 60),
      hours = Math.floor(duration / 3600000);

    hours = hours < 10 ? "0" + hours : hours;
    minutes = minutes < 10 ? "0" + minutes : minutes;
    seconds = seconds < 10 ? "0" + seconds : seconds;

    return hours + "h " + minutes + "m " + seconds + "s";
  }
  const DepartmentWiseData = () => {
    const departmentName = [];
    let totalHr = 0;
    const result = moment.duration();
    let totalhrss = 0;

    ProjectWH.forEach((item) => {
      // console.log(item,"obj");
      const obj = departmentName.find((o) => o.Role == item.Employee_id.Role);
      if (obj) {
        if (projectId == item.Project_id) {
          var duration = moment.duration(
            moment(item?.Check_Out).diff(moment(item.Check_In))
          );
          totalHr = totalHr.add(duration);
          duration = duration.add(obj.duration);
          var seconds = duration.asSeconds();

          let minutes = Math.floor(seconds) % 3600;
          let durationTime =
            Math.floor(seconds / 3600) +
            "h " +
            Math.floor(minutes / 60) +
            "m " +
            Math.floor(seconds % 60) +
            "s";
        }
        var seconds = duration.asSeconds();

        let minutes = Math.floor(seconds) % 3600;
        obj.TotalHours =
          Math.floor(seconds / 3600) +
          "h " +
          Math.floor(minutes / 60) +
          "m " +
          Math.floor(seconds % 60) +
          "s ";
        obj.duration = duration;
      } else {
        if (projectId == item.Project_id) {
          var duration = moment.duration(
            moment(item?.Check_Out).diff(moment(item.Check_In))
          );
          console.log(duration, "duration");
          var seconds = duration.asSeconds();

          let minutes = Math.floor(seconds) % 3600;
          let durationTime =
            Math.floor(seconds / 3600) +
            "h " +
            Math.floor(minutes / 60) +
            "m " +
            Math.floor(seconds % 60) +
            "s";
        }
        var seconds = duration.asSeconds();

        let minutes = Math.floor(seconds) % 3600;
        totalhrss = totalhrss + Math.floor(seconds / 3600);
        let dataObj = {
          name: `${item.Employee_id.First_name}  (${item.Employee_id.Role})`,
          originalname: item.Employee_id.First_name,
          Role: item.Employee_id.Role,
          h: Math.floor(seconds / 3600),
          m: Math.floor(minutes / 60),
          s: Math.floor(seconds % 60),
          duration: duration,
          TotalHours:
            Math.floor(seconds / 3600) +
            "h " +
            Math.floor(minutes / 60) +
            "m " +
            Math.floor(seconds % 60) +
            "s ",
          action: (
            <Link
              onClick={() => {
                setVisible(true);
                EmployeetaskDATA(item.Employee_id._id);
              }}
            >
              View
            </Link>
          ),
        };

        if (totalHr) {
          totalHr = totalHr.add(duration);
        } else {
          totalHr = duration;
        }

        departmentName.push(dataObj);
      }
    });

    setDepartmentDatatable({
      columns: [
        {
          label: "Name",
          field: "Role",
          width: 50,
          sort: "disabled",
        },
        {
          label: "Total Hours",
          field: "TotalHours",
          width: 50,
          sort: "disabled",
        },
      ],
      rows: departmentName,
    });
  };

  const ProjectmodifiedDATA = () => {
    const employeename = [];
    let totalHr = 0;
    const result = moment.duration();
    let totalhrss = 0;

    ProjectWH.forEach((item) => {
      // console.log(item,"obj");
      const obj = employeename.find(
        (o) => o.originalname == item.Employee_id.First_name
      );
      if (obj) {
        if (projectId == item.Project_id) {
          var duration = moment.duration(
            moment(item?.Check_Out).diff(moment(item.Check_In))
          );
          totalHr = totalHr.add(duration);
          duration = duration.add(obj.duration);
          var seconds = duration.asSeconds();

          let minutes = Math.floor(seconds) % 3600;
          let durationTime =
            Math.floor(seconds / 3600) +
            "h " +
            Math.floor(minutes / 60) +
            "m " +
            Math.floor(seconds % 60) +
            "s";

          setValue(durationTime);
        }
        var seconds = duration.asSeconds();

        let minutes = Math.floor(seconds) % 3600;
        obj.TotalHours =
          Math.floor(seconds / 3600) +
          "h " +
          Math.floor(minutes / 60) +
          "m " +
          Math.floor(seconds % 60) +
          "s ";
        obj.duration = duration;
      } else {
        if (projectId == item.Project_id) {
          var duration = moment.duration(
            moment(item?.Check_Out).diff(moment(item.Check_In))
          );
          console.log(duration, "duration");
          var seconds = duration.asSeconds();

          let minutes = Math.floor(seconds) % 3600;
          let durationTime =
            Math.floor(seconds / 3600) +
            "h " +
            Math.floor(minutes / 60) +
            "m " +
            Math.floor(seconds % 60) +
            "s";

          setValue(durationTime);
        }
        var seconds = duration.asSeconds();

        let minutes = Math.floor(seconds) % 3600;
        totalhrss = totalhrss + Math.floor(seconds / 3600);
        let dataObj = {
          name: `${item.Employee_id.First_name}  (${item.Employee_id.Role})`,
          originalname: item.Employee_id.First_name,
          h: Math.floor(seconds / 3600),
          m: Math.floor(minutes / 60),
          s: Math.floor(seconds % 60),
          duration: duration,
          TotalHours:
            Math.floor(seconds / 3600) +
            "h " +
            Math.floor(minutes / 60) +
            "m " +
            Math.floor(seconds % 60) +
            "s ",
          action: (
            <Link
              onClick={() => {
                setVisible(true);
                EmployeetaskDATA(item.Employee_id._id);
              }}
            >
              View
            </Link>
          ),
        };

        if (totalHr) {
          totalHr = totalHr.add(duration);
        } else {
          totalHr = duration;
        }

        employeename.push(dataObj);

        console.log(duration, "8888a");
        const sumHoras = [0, 0];
      }
      setTotalHours(totalHr);
    });
    function msToTime(duration) {
      var seconds = Math.floor((duration / 1000) % 60),
        minutes = Math.floor((duration / (1000 * 60)) % 60),
        hours = Math.floor(duration / 3600000);

      hours = hours < 10 ? "0" + hours : hours;
      minutes = minutes < 10 ? "0" + minutes : minutes;
      seconds = seconds < 10 ? "0" + seconds : seconds;

      return hours + "h " + minutes + "m " + seconds + "s";
    }
    console.log(msToTime(11331715811), "kushPatel");
    if (totalHr !== 0) {
      console.log(totalHr._milliseconds, "kushPatel");
      let modifyHour = msToTime(totalHr?._milliseconds);
      employeename.push({
        name: <span style={{ fontWeight: "bold" }}>Total Hours</span>,
        TotalHours: <span style={{ fontWeight: "bold" }}>{modifyHour}</span>,
      });

      console.log(modifyHour, 12312, totalHr._milliseconds);
    }

    setDatatable({
      columns: [
        {
          label: "Name",
          field: "name",
          width: 50,
          sort: "disabled",
        },
        {
          label: "Total Hours",
          field: "TotalHours",
          width: 50,
          sort: "disabled",
        },
        {
          label: "Action",
          field: "action",
          width: 50,
          sort: "disabled",
        },
      ],
      rows: employeename,
    });
  };

  useEffect(() => {
    ProjectmodifiedDATA();
    DepartmentWiseData();
  }, [ProjectWH]);

  const handleCancel = (errorInfo) => {
    setVisible(false);
  };

  const EmployeetaskDATA = (id) => {
    const employeename2 = [];
    console.log(ProjectWH, 2003);
    let totalHr = 0;
    const result = moment.duration();
    let totalhrss = 0;

    ProjectWH.forEach((item) => {
      if (id == item.Employee_id._id) {
        var duration = moment.duration(
          moment(item?.Check_Out).diff(moment(item.Check_In))
        );
        console.log(duration, "duration");
        var seconds = duration.asSeconds();

        let minutes = Math.floor(seconds) % 3600;
        let durationTime =
          Math.floor(seconds / 3600) +
          "h " +
          Math.floor(minutes / 60) +
          "m " +
          Math.floor(seconds % 60) +
          "s";

        setValue(durationTime);

        let checkOutTable = item?.Check_Out;
        let checkOutTime = moment(checkOutTable).format("HH:mm:ss");
        let checkOutDate = moment(checkOutTable).format("DD-MM-YYYY");

        let checkInTable = item?.Check_In;
        let checkInTime = moment(checkInTable).format("HH:mm:ss");
        let checkInDate = moment(checkInTable).format("DD-MM-YYYY");

        let dataObj = {
          Start_Date: `${checkInDate} - ${checkOutDate}`,
          Start_Time: `${checkInTime} - ${checkOutTime}`,

          Task_key: `${item.Task_id.Task_key}`,
          name: item.Employee_id.First_name,

          duration: duration,
          TaskHRS:
            (Math.floor(seconds / 3600) > 0
              ? Math.floor(seconds / 3600) + "h "
              : " ") +
            (Math.floor(minutes / 60) > 0
              ? Math.floor(minutes / 60) + "m "
              : " ") +
            Math.floor(seconds % 60) +
            "s ",
        };

        setformodaldynamics(dataObj.name);

        if (totalHr) {
          totalHr = totalHr.add(duration);
        } else {
          totalHr = duration;
        }
        employeename2.push(dataObj);
        console.log(employeename2, "dataObjKSP");
      }
    });

    setDatatable2({
      columns: [
        // {
        //   label: "Employee Name",
        //   field: "name",
        //   width: 50,
        //   sort: "disabled",
        // },
        {
          label: "Task Key",
          field: "Task_key",
          width: 50,
          sort: "disabled",
        },

        {
          label: "start date-End date  ",
          field: "Start_Date",
          width: 50,
          sort: "disabled",
        },
        {
          label: "start Time-End time  ",
          field: "Start_Time",
          width: 50,
          sort: "disabled",
        },
        {
          label: "TASK HOURS",
          field: "TaskHRS",
          width: 50,
          sort: "disabled",
        },

        // {
        //   label: "End Date ",
        //   field: "End_Date",
        //   width: 50,
        //   sort: "disabled",
        // },
        // {
        //   label: "End Time",
        //   field: "End_Time",
        //   width: 50,
        //   sort: "disabled",
        // },
      ],
      rows: employeename2,
    });
  };

  return (
    <>
      <div className="project-hours-content">
        <MDBCard className="page-content">
          <div>
            <div className="create-project">
              <MDBCol size="md-6">
                <MDBDataTableV5
                  // hover
                  entriesOptions={[50, 50]}
                  entries={20}
                  pagesAmount={4}
                  data={datatable}
                  searchTop
                  searchBottom={false}
                  className=""
                />
              </MDBCol>
              <MDBCol size="md-2"></MDBCol>
              <MDBCol size="md-4">
                <MDBDataTableV5
                  // hover
                  entriesOptions={[50, 50]}
                  entries={20}
                  pagesAmount={4}
                  data={departmentDatatable}
                  searchTop
                  searchBottom={false}
                  className=""
                />
              </MDBCol>
            </div>
          </div>
        </MDBCard>

        <Modal
          title={`${formodaldynamics} Tasks List`}
          centered
          // visible={visible}
          open={visible}
          onOk={() => setVisible(false)}
          onCancel={handleCancel}
          //width={550}
          // height={640}
          className="leave-form modal-back modal-title close-btn"
          footer={null}
        >
          <Form
            // form={form}
            name="control-hooks"
            layout="vertical"
            // name="basic"
            labelCol={{
              span: 24,
            }}
            wrapperCol={{
              span: 24,
            }}
            initialValues={{
              remember: true,
            }}
            autoComplete="off"
            className="left-margin working-hour-modal"
          >
            <Row className="mx-0">
              <Col className="date-module">
                <Form.Item
                  className="date-input"
                  name="emergency"
                  rules={[
                    {
                      required: false,
                    },
                  ]}
                >
                  <MDBDataTableV5
                    // hover
                    entriesOptions={[50, 50]}
                    entries={20}
                    pagesAmount={4}
                    data={datatable2}
                    searchTop
                    searchBottom={false}
                    className="task-list-working-hour"
                  />
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </Modal>
      </div>
    </>
  );
};

export default ProjectTotalHours;
