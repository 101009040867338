import React from "react";
import { MDBNavbarItem, MDBNavbarLink, MDBNavbarNav } from "mdb-react-ui-kit";
import { Link } from "react-router-dom";

const ManualAttendanceBar = () => {
  let userData = JSON.parse(localStorage.getItem("userdata"));

  return (
    <>
      <MDBNavbarNav className="page-menu-nav">
        <MDBNavbarItem>
          <Link to="/checkInRequest" active>
            {" "}
            <MDBNavbarLink active aria-current="page" href="#">
              Check-In Request
            </MDBNavbarLink>
          </Link>
        </MDBNavbarItem>
        <MDBNavbarItem>
       <Link to="/checkOutRequest" active>
            {" "}
            <MDBNavbarLink active aria-current="page" href="#">
              Check-Out Request
            </MDBNavbarLink>
          </Link>

        </MDBNavbarItem>
        <MDBNavbarItem>
        {
           ( userData[0]._id === "637b62187c22a305936a917f" || userData[0]._id=="61b727ca37a0b8573dde2d97" ) && <Link to="/cpo-leave" active>
            {" "}
            <MDBNavbarLink active aria-current="page" href="#">
              Emergency Leave REPORT
            </MDBNavbarLink>
          </Link>
}
        </MDBNavbarItem>
      </MDBNavbarNav>
    </>
  );
};

export default ManualAttendanceBar;
