import React, { useEffect, useState } from "react";
import { MDBDataTableV5, MDBCard } from "mdbreact";
import { MDBRow, MDBCol, MDBIcon } from "mdb-react-ui-kit";
import { Modal, Form, Button, Row, Col, Select, Spin, message } from "antd";
import AxiosInstance from "../../../service/AxiosInstance";
import { useHistory, useParams } from "react-router-dom";
import Permission from "../Permission";
import ProjectBar from "./ProjectBar";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";

// Add Member model
export const AddMemberModel = ({ id, setFlage, flage }) => {
  const [form] = Form.useForm();
  const [visible, setVisible] = useState(false);
  const [projectData, setProjectData] = useState({});
  let userData = JSON.parse(localStorage.getItem("userdata"));

  const [selectedMemberId, setSelectedMemberId] = useState("");
  const [employees, setEmployees] = useState([]);
  const [options, setOptions] = useState([]);

  const rolePermissions = useSelector((state) => state.Permissions.Permissions);
  // const [rolePermissions, setRolepermissions] = useState(permssionsState);
  useEffect(() => {
    const formData = new URLSearchParams();
    formData.append("id", id);

    //API
    AxiosInstance.post("auth/projects/detail?addmember", formData).then(
      async (res) => {
        if (res.data.status == 1) {
          setProjectData(res.data.data);
        }
      }
    );
  }, [visible, flage]);

  useEffect(() => {
    if (projectData?.Project_member_id) {
      setSelectedMemberId(projectData?.Project_member_id);
    }
  }, [projectData]);

  // employee list
  useEffect(() => {
    AxiosInstance.get("auth/employees").then(async (res) => {
      if (res.data.status == 1) {
        setEmployees(res.data.data);

        let EmList = await res.data.data.map((item) => {
          return {
            label:
              item.First_name + " " + item.Last_name + " (" + item.Role + ")",
            value: item._id,
            id: item._id,
          };
        });
        setOptions(EmList);
      }
    });
  }, []);
  const handleCancel = (errorInfo) => {
    form.resetFields();
    setVisible(false);
  };
  const onFinish = (values) => {
    //console.log("Success:", values);
    if (values) {
      const formData = new URLSearchParams();

      let ProjectMemberNames = employees
        ?.filter((item) => {
          return selectedMemberId.includes(item._id);
        })
        .map((item) => {
          return item.First_name + " " + item.Last_name;
        });

      formData.append("Project_member", ProjectMemberNames);
      formData.append("Project_member_id", selectedMemberId);
      formData.append("id", id);

      AxiosInstance.post("auth/projects/edit", formData)
        .then((res) => {
          // toast.success("Member Added Successfully");
          message.success("Member Added Successfully");
          setFlage((pre) => pre + 1);
          if (res.data.status == 1) {
            const formData1 = new URLSearchParams();
            formData1.append("Project_id", id);
            formData1.append(
              "Updated_by",
              userData[0]?.First_name + " " + userData[0]?.Last_name
            );
            formData1.append(
              "Action",
              `${res.data.data[0].Project_name} Member`
            );
            AxiosInstance.post("auth/history/add", formData1).then((res) => {
              if (res.data.status == 1) {
                // toast.success("Task created Successfully");
                message.success("Task created Successfully");
              }
            });
          }
        })
        .catch((err) => console.log(err, "err"));
    }
    form.resetFields();
    setVisible(false);
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };
  const handleSearch = (newValue) => {
    if (newValue) {
      let newOptions = employees
        ?.filter((item) => {
          let name =
            item.First_name + " " + item.Last_name + " (" + item.Role + ")";

          return name
            .toLocaleLowerCase()
            .includes(newValue.toLocaleLowerCase());
        })
        .map((item) => {
          return {
            label:
              item.First_name + " " + item.Last_name + " (" + item.Role + ")",
            value: item._id,
            id: item._id,
          };
        });
      setOptions(newOptions);
    } else {
      setOptions([]);
    }
  };

  useEffect(() => {
    form.resetFields();
  }, [visible]);
  return (
    <>
      {/* {rolePermissions?.Project?.Update_Member == 1 ? (
        <Button
          type="primary"
          onClick={() => setVisible(true)}
          className="pms-same-btn"
        >
          Update Member
        </Button>
      ) : (
        ""
      )} */}

      {/* Delete model */}
      <Modal
        title="Add new members"
        centered
        // visible={visible}
        open={visible}
        onOk={() => setVisible(false)}
        onCancel={handleCancel}
        width={800}
        className="add-member-body"
        bodyStyle={{ paddingTop: "20px", paddingBottom: "0px !important" }}
        footer={null}
      >
        <Form
          form={form}
          name="control-hooks"
          layout="vertical"
          // name="basic"
          labelCol={{
            span: 24,
          }}
          wrapperCol={{
            span: 24,
          }}
          initialValues={{
            remember: true,
          }}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          autoComplete="off"
          className="left-margin"
        >
          <Row>
            <Col span={14} className="m-left">
              <Form.Item
                label="Project Member:"
                name="projectMember"
                initialValue={
                  projectData.Project_member_id &&
                  projectData.Project_member_id !== "undefined"
                    ? projectData?.Project_member_id.split(",")
                    : undefined
                }
              >
                <Select
                  mode="multiple"
                  // showSearch
                  onChange={(e) => {
                    setSelectedMemberId(e);
                  }}
                  filterOption={false}
                  onSearch={handleSearch}
                  placeholder="Please Select Project Members"
                  // options={options}
                  options={options.map((d) => ({
                    value: d.value,
                    label: d.label,
                  }))}
                />
              </Form.Item>
            </Col>
            <Col span={6} className="m-left ml">
              <Form.Item>
                {/* <div style={{ textAlign: 'end', marginRight: '60px' }}> */}
                <Button
                  type="primary"
                  className="margin-left-5"
                  htmlType="submit"
                  style={{ marginRight: "10px" }}
                >
                  Add
                </Button>
                <Button onClick={handleCancel}>Cancel</Button>
                {/* </div> */}
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Modal>
    </>
  );
};

// main component
const Members = () => {
  const [rows, setRows] = useState([]);
  const [flage, setFlage] = useState(0);
  const [memberlist, setMemberList] = useState([]);
  let history = useHistory();
  const params = useParams();
  let PID = params.id;
  const [datatable, setDatatable] = useState({});
  const [projectDetail, setProjectDetail] = useState([]);
  const [loading, setLoading] = useState(false);

  // Project Details
  useEffect(() => {
    const formData = new URLSearchParams();
    formData.append("id", PID);
    AxiosInstance.post("auth/projects/detail", formData).then(async (res) => {
      if (res.data.status == 1) {
        setProjectDetail(res.data.data);
      }
    });
    // const formData = new URLSearchParams();
    // formData.append('id', id);
    setLoading(true);
    AxiosInstance.get(`auth/projectemployeelist/${PID}`).then(async (res) => {
      if (res.data.status == 1) {
        setLoading(false);
        // setProjectData(res.data.data);
        let employee = await res.data.data.filter((element) => {
          return `${element.First_name} ${element.Last_name}`;
        });
        setMemberList(employee);
        let list1 = res.data.data.map((item) => {
          // console.log(item);
          return {
            icon: <MDBIcon fas icon="plus" style={{ color: "green" }} />,
            name: item?.First_name + " " + item?.Last_name,
            email: item?.Email,
            department: item?.Role,
            position: item?.Position,
          };
        });
        setRows(list1);
      }
    });
  }, [PID, flage]);

  useEffect(() => {
    setDatatable({
      columns: [
        {
          label: <MDBIcon fas icon="hashtag" />,
          field: "icon",
          sort: "disabled",
          width: 10,
        },
        {
          label: "Name",
          field: "name",
          width: 150,
          sort: "disabled",
          attributes: {
            "aria-controls": "DataTable",
            "aria-label": "Name",
          },
        },
        {
          label: "Email",
          field: "email",
          width: 270,
          sort: "disabled",
        },
        {
          label: "Department",
          field: "department",
          width: 270,
          sort: "disabled",
        },

        {
          label: "Position",
          field: "position",
          width: 200,
          sort: "disabled",
        },
        // {
        //   label: 'Projejct Manager',
        //   field: 'projejct_manager',
        //   sort: 'disabled',
        //   width: 100,
        // },
      ],
      rows: rows,
    });
  }, [rows]);

  return (
    <>
      <div className="project-main-details">
        {/* <MDBRow className="project-title-div" style={{ marginBottom: 20 }}>
          <MDBCol size="md" className="col-example float-right">
            <span
              className="btn-right"
            // style={{ float: 'right', padding: '5px' }}
            >
              {Permission()[0]?.All == "1" ? (
                <AddMemberModel id={PID} flage={flage} setFlage={setFlage} />
              ) : (
                <AddMemberModel id={PID} flage={flage} setFlage={setFlage} />
              )}

              <Button
                  onClick={() => history.goBack()}
                  // style={{ marginLeft: '10px' }}
                >
                  <MDBIcon
                    // style={{ paddingRight: '5px' }}
                    className="pr-5"
                    fas
                    icon="long-arrow-alt-left"
                  />
                  Back
                </Button>
            </span>
          </MDBCol>
        </MDBRow> */}
        <div className="">
          <MDBCard>
            <div className="tasklisttbl resource-member-tbl">
              <Spin spinning={loading}>
                <MDBDataTableV5
                  hover
                  entriesOptions={[10, 20, 25]}
                  entries={10}
                  pagesAmount={4}
                  data={datatable}
                  searchTop
                  searchBottom={false}
                />
              </Spin>
            </div>
          </MDBCard>
        </div>
      </div>
    </>
  );
};

export default Members;
