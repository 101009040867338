import React, { useEffect } from "react";
import { useState } from "react";
import Chart from "react-apexcharts";
import { useParams } from "react-router-dom";
import AxiosInstance from "../../../service/AxiosInstance";
import ProjectBar from "../projects/ProjectBar";
import { Space, Table, Tag } from "antd";
import ReactLoader from "../../../components/common/ReactLoader";
import { Progress } from "antd";
import StoryTable from "../projects/StoryTable";

const EmployeeChart = ({projectData}) => {
  // console.log("projectData",projectData)
  // console.log("projectData",projectData)
  const params = useParams();
  const [taskAnalytics, setTaskAnalytics] = useState({
    series: [0, 0, 0],
    labels: ["Task", "Story", "Bugs"],
  });
 


  // const option = {
  //   series: [44, 55, 13, 33, 10],
  //   labels: ["InProgress", "Development Done", "Reopen", "close", "Rejected"],
  // };
  // const option2 = {
  //   series: [44, 55, 13, 33, 10],
  //   labels: ["InProgress", "Development Done", "Reopen", "close", "Rejected"],
  // };
  const [loading, setLoading] = useState(false);
  const [mainStatistics, setMainStatistics] = useState([]);
  
  const [taskStatistics, setTaskStatistics] = useState([
   
    {
      key: "1",
      Status: "open",
      Count: 0,
      Percentage: <Progress percent={0} status="active" />,
    },
    {
      key: "2",
      Status: "InProgress",
      Count: 0,
      Percentage: <Progress percent={0} status="active" />,
    },
    {
      key: "3",
      Status: "Development Done",
      Count: 0,
      Percentage: <Progress percent={0} status="active" />,
    },
    {
      key: "4",
      Status: "Reopen",
      Count: 0,
      Percentage: <Progress percent={0} status="active" />,
    },
    {
      key: "5",
      Status: "close",
      Count: 0,
      Percentage: <Progress percent={0} status="active" />,
    },
    {
      key: "6",
      Status: "Rejected",
      Count: 0,
      Percentage: <Progress percent={0} status="active" />,
    },
    {
      key: "7",
      Status: "ReadyForQA",
      Count: 0,
      Percentage: <Progress percent={0} status="active" />,
    },
    {
      key: "8",
      Status: "On Hold",
      Count: 0,
      Percentage: <Progress percent={0} status="active" />,
    },
  ]);
  const columns = [
    {
      title: "Status",
      dataIndex: "Status",
      key: "Status",
    },
    {
      title: "Count",
      dataIndex: "Count",
      key: "Count",
    },

    {
      title: "Percentage",
      dataIndex: "Percentage",
      key: "Percentage",
    },
    {
      title: "Task",
      dataIndex: "Task",
      key: "Task",
    },
    {
      title: "Bugs",
      dataIndex: "Bugs",
      key: "Bugs",
    },
    {
      title: "Story",
      dataIndex: "Story",
      key: "Story",
    },
  ];

  const mainColumns = [
    {
      title: "Type",
      dataIndex: "Type",
      key: "Type",
    },
    {
      title: "Count",
      dataIndex: "Count",
      key: "Count",
    },

    {
      title: "Percentage",
      dataIndex: "Percentage",
      key: "Percentage",
    },
    {
      title: "Open",
      dataIndex: "Open",
      key: "Open",
    },
    {
      title: "Close",
      dataIndex: "Close",
      key: "Close",
    }
    ];
    useEffect(()=>{
      setMainStatistics([
         
        {
          key: "0",
          Type: "Task",
          Count: (projectData.totalTask),
          Percentage: (
            <Progress
              percent={
                (((projectData.totalTaskCompleted)  / (projectData.totalTask)) * 100).toFixed() == "NaN"
                  ? 0
                  : ((( projectData.totalTaskCompleted) / (projectData.totalTask)) * 100).toFixed()
              }
              status="active"
            />
          ),
          Open: (projectData.totalTask- projectData.totalTaskCompleted),
          Close: projectData.totalTaskCompleted
        },
        
        {
          key: "1",
          Type: "Story",
          Count: (projectData.totalStory),
          Percentage: (
            <Progress
              percent={
                (((projectData.totalStory- projectData.totalCompletedStory)  / (projectData.totalStory)) * 100).toFixed() == "NaN"
                  ? 0
                  : (((projectData.totalStory- projectData.totalCompletedStory) / (projectData.totalStory)) * 100).toFixed()
              }
              status="active"
            />
          ),
          Open: (projectData.totalStory- projectData.totalCompletedStory),
          Close: projectData.totalCompletedStory
        },
        {
          key: "2",
          Type: "Sprint",
          Count: (projectData.totalSprint),
          Percentage: (
            <Progress
              percent={
                (((projectData.totalSprintCompleted)  / (projectData.totalSprint)) * 100).toFixed() == "NaN"
                  ? 0
                  : ((( projectData.totalSprintCompleted) / (projectData.totalSprint)) * 100).toFixed()
              }
              status="active"
            />
          ),
          Open: (projectData.totalSprint- projectData.totalSprintCompleted),
          Close: projectData.totalSprintCompleted
        },
        {
          key: "4",
          Type: "Milestone",
          Count: (projectData.totalMilestone),
          Percentage: (
            <Progress
              percent={
                (((projectData.totalMilestoneCompleted)  / (projectData.totalMilestone)) * 100).toFixed() == "NaN"
                  ? 0
                  : (((projectData.totalMilestoneCompleted) / (projectData.totalMilestone)) * 100).toFixed()
              }
              status="active"
            />
          ),
          Open: (projectData.totalMilestone- projectData.totalMilestoneCompleted),
          Close: projectData.totalMilestoneCompleted
        },
        {
          key: "5",
          Type: "Issues",
          Count: (projectData.totalBug),
          Percentage: (
            <Progress
              percent={
                (((projectData.totalBugCompleted)  / (projectData.totalBug)) * 100).toFixed() == "NaN"
                  ? 0
                  : (((projectData.totalBugCompleted) / (projectData.totalBug)) * 100).toFixed()
              }
              status="active"
            />
          ),
          Open: (projectData.totalBug- projectData.totalBugCompleted),
          Close: projectData.totalBugCompleted
        }
      ])
    },[projectData])

  const taskListData = () => {
    setLoading(true);
    AxiosInstance.get(`auth/project/task/list-analytics/${params.id}`).then(
      async (res) => {
        if (res.data.status == 1) {
          setLoading(false);

          let result = res.data.data;
          const draftCount = result.filter(
            (task) => task.Status === "DRAFT"
          ).length;
          const openCount = result.filter(
            (task) => task.Status === "OPEN"
          ).length;
          const inProgressCount = result.filter(
            (task) => task.Status === "IN PROGRESS"
          ).length;
          const developmentDoneCount = result.filter(
            (task) => task.Status === "DEVELOPMENT DONE"
          ).length;
          const readyForQACount = result.filter(
            (task) => task.Status === "READY FOR QA"
          ).length;
          const reOpenCount = result.filter(
            (task) => task.Status === "REOPEN"
          ).length;
          const rejectedCount = result.filter(
            (task) => task.Status === "REJECTED"
          ).length;
          const onHoldCount = result.filter(
            (task) => task.Status === "ON HOLD"
          ).length;
          const closeCount = result.filter(
            (task) => task.Status === "CLOSE"
          ).length;
          const totaltask = result.length;
          const totalbugs = result.length;
          const totalstory = result.length;

          const totalTask = result.filter(
            (task) => task.Issue_type === "Task"
          ).length;
          const totalBugs = result.filter(
            (task) => task.Issue_type === "Bug"
          ).length;
          const totalStory = result.filter(
            (task) => task.Issue_type === "Story"
          ).length;

          const draftTaskCount = result.filter(
            (task) => task.Status === "DRAFT" && task.Issue_type === "Task"
          ).length;
          const openTaskCount = result.filter(
            (task) => task.Status === "OPEN" && task.Issue_type === "Task"
          ).length;
          const draftBugsCount = result.filter(
            (task) => task.Status === "DRAFT" && task.Issue_type === "Bug"
          ).length;
          const openBugsCount = result.filter(
            (task) => task.Status === "OPEN" && task.Issue_type === "Bug"
          ).length;
      
          const draftStoryCount = result.filter(
            (task) => task.Status === "DRAFT" && task.Issue_type === "Story"
          ).length;

          const openStoryCount = result.filter(
            (task) => task.Status === "OPEN" && task.Issue_type === "Story"
          ).length;

          const openStory = result.filter(
            (task) => task.Status === "OPEN" && task.Issue_type === "Story"
          );
          // const openStoryCount = openStory.length;
          console.log(openStory);
          let accumulator = 0,totalHours=0;;
          if(openStory.length>0){
          totalHours = openStory.reduce((accumulator, current) => accumulator + current.Task_duration)
        }
          console.log("Pending Hours",totalHours)

          const inProgressTaskCount = result.filter(
            (task) =>
              task.Status === "IN PROGRESS" && task.Issue_type === "Task"
          ).length;
          const inProgressBugsCount = result.filter(
            (task) => task.Status === "IN PROGRESS" && task.Issue_type === "Bug"
          ).length;
          const inProgressStoryCount = result.filter(
            (task) =>
              task.Status === "IN PROGRESS" && task.Issue_type === "Story"
          ).length;

          const developmentDoneTaskCount = result.filter(
            (task) =>
              task.Status === "DEVELOPMENT DONE" && task.Issue_type === "Task"
          ).length;
          const developmentDoneBugsCount = result.filter(
            (task) =>
              task.Status === "DEVELOPMENT DONE" && task.Issue_type === "Bug"
          ).length;
          const developmentDoneStoryCount = result.filter(
            (task) =>
              task.Status === "DEVELOPMENT DONE" && task.Issue_type === "Story"
          ).length;

          const readyForQATaskCount = result.filter(
            (task) =>
              task.Status === "READY FOR QA" && task.Issue_type === "Task"
          ).length;
          const readyForQABugsCount = result.filter(
            (task) =>
              task.Status === "READY FOR QA" && task.Issue_type === "Bug"
          ).length;
          const readyForQAStoryCount = result.filter(
            (task) =>
              task.Status === "READY FOR QA" && task.Issue_type === "Story"
          ).length;

          const reOpenTaskCount = result.filter(
            (task) => task.Status === "REOPEN" && task.Issue_type === "Task"
          ).length;
          const reOpenBugsCount = result.filter(
            (task) => task.Status === "REOPEN" && task.Issue_type === "Bug"
          ).length;
          const reOpenStoryCount = result.filter(
            (task) => task.Status === "REOPEN" && task.Issue_type === "Story"
          ).length;

          const rejectedTaskCount = result.filter(
            (task) => task.Status === "REJECTED" && task.Issue_type === "Task"
          ).length;
          const rejectedBugsCount = result.filter(
            (task) => task.Status === "REJECTED" && task.Issue_type === "Bug"
          ).length;
          const rejectedStoryCount = result.filter(
            (task) => task.Status === "REJECTED" && task.Issue_type === "Story"
          ).length;

          const onHoldTaskCount = result.filter(
            (task) => task.Status === "ON HOLD" && task.Issue_type === "Task"
          ).length;
          const onHoldBugsCount = result.filter(
            (task) => task.Status === "ON HOLD" && task.Issue_type === "Bug"
          ).length;
          const onHoldStoryCount = result.filter(
            (task) => task.Status === "ON HOLD" && task.Issue_type === "Story"
          ).length;

          const closeTaskCount = result.filter(
            (task) => task.Status === "CLOSE" && task.Issue_type === "Task"
          ).length;
          const closeBugsCount = result.filter(
            (task) => task.Status === "CLOSE" && task.Issue_type === "Bug"
          ).length;
          const closeStoryCount = result.filter(
            (task) => task.Status === "CLOSE" && task.Issue_type === "Story"
          ).length;




          setTaskAnalytics({
            series: [totalTask, totalStory, totalBugs],
            labels: ["Task", "Story", "Bugs"],
          });

          setTaskStatistics([
            {
              key: "0",
              Status: "Draft",
              Count: draftCount,
              Percentage: (
                <Progress
                  percent={
                    ((draftCount / totaltask) * 100).toFixed() == "NaN"
                      ? 0
                      : ((draftCount / totaltask) * 100).toFixed()
                  }
                  status="active"
                />
              ),
              Bugs: draftBugsCount,
              Task: draftTaskCount,
              Story: draftStoryCount,
            },
            {
              key: "1",
              Status: "Open",
              Count: openCount,
              Percentage: (
                <Progress
                  percent={
                    ((openCount / totaltask) * 100).toFixed() == "NaN"
                      ? 0
                      : ((openCount / totaltask) * 100).toFixed()
                  }
                  status="active"
                />
              ),
              Bugs: openBugsCount,
              Task: openTaskCount,
              Story: openStoryCount,
            },
            {
              key: "2",
              Status: "InProgress",
              Count: inProgressCount,
              Percentage: (
                <Progress
                  percent={
                    ((inProgressCount / totaltask) * 100).toFixed() == "NaN"
                      ? 0
                      : ((inProgressCount / totaltask) * 100).toFixed()
                  }
                  status="active"
                />
              ),
              Bugs: inProgressBugsCount,
              Task: inProgressTaskCount,
              Story: inProgressStoryCount,
            },
            {
              key: "3",
              Status: "Development Done",
              Count: developmentDoneCount,
              Percentage: (
                <Progress
                  percent={
                    ((developmentDoneCount / totaltask) * 100).toFixed() ==
                    "NaN"
                      ? 0
                      : ((developmentDoneCount / totaltask) * 100).toFixed()
                  }
                  status="active"
                />
              ),
              Bugs: developmentDoneBugsCount,
              Task: developmentDoneTaskCount,
              Story: developmentDoneStoryCount,
            },
            {
              key: "4",
              Status: "Reopen",
              Count: reOpenCount,
              Percentage: (
                <Progress
                  percent={
                    ((reOpenCount / totaltask) * 100).toFixed() == "NaN"
                      ? 0
                      : ((reOpenCount / totaltask) * 100).toFixed()
                  }
                  status="active"
                />
              ),
              Bugs: reOpenBugsCount,
              Task: reOpenTaskCount,
              Story: reOpenStoryCount,
            },
            {
              key: "5",
              Status: "close",
              Count: closeCount,
              Percentage: (
                <Progress
                  percent={
                    ((closeCount / totaltask) * 100).toFixed() == "NaN"
                      ? 0
                      : ((closeCount / totaltask) * 100).toFixed()
                  }
                  status="active"
                />
              ),
              Bugs: closeBugsCount,
              Task: closeTaskCount,
              Story: closeStoryCount,
            },
            {
              key: "6",
              Status: "Rejected",
              Count: rejectedCount,
              Percentage: (
                <Progress
                  percent={
                    ((rejectedCount / totaltask) * 100).toFixed() == "NaN"
                      ? 0
                      : ((rejectedCount / totaltask) * 100).toFixed()
                  }
                  status="active"
                />
              ),
              Bugs: rejectedBugsCount,
              Task: rejectedTaskCount,
              Story: rejectedStoryCount,
            },
            {
              key: "7",
              Status: "ReadyForQA",
              Count: readyForQACount,
              Percentage: (
                <Progress
                  percent={
                    ((readyForQACount / totaltask) * 100).toFixed() == "NaN"
                      ? 0
                      : ((readyForQACount / totaltask) * 100).toFixed()
                  }
                  status="active"
                />
              ),
              Bugs: readyForQABugsCount,
              Task: readyForQATaskCount,
              Story: readyForQAStoryCount,
            },
            {
              key: "8",
              Status: "On Hold",
              Count: onHoldCount,
              Percentage: (
                <Progress
                  percent={
                    ((onHoldCount / totaltask) * 100).toFixed() == "NaN"
                      ? 0
                      : ((onHoldCount / totaltask) * 100).toFixed()
                  }
                  status="active"
                />
              ),
              Bugs: onHoldBugsCount,
              Task: onHoldTaskCount,
              Story: onHoldStoryCount,
            },
            {
              key: "9",
              Status: "Total",
              Count: (totalBugs+totalTask+totalStory),
              Percentage: (
                <Progress
                  percent={
                    (((onHoldCount+draftCount+openCount+inProgressCount+developmentDoneCount+rejectedCount+closeCount+readyForQACount+reOpenCount )  / (totalBugs+totalTask+totalStory)) * 100).toFixed() == "NaN"
                      ? 0
                      : (((onHoldCount+draftCount+openCount+inProgressCount+developmentDoneCount+rejectedCount+closeCount+readyForQACount+reOpenCount ) / (totalBugs+totalTask+totalStory)) * 100).toFixed()
                  }
                  status="active"
                />
              ),
              Bugs: totalBugs,
              Task: totalTask,
              Story: totalStory,
            },
          ]);

         
        }
      }
    );
  };
  useEffect(() => {
    taskListData();
  }, []);
  return (
    <>
      {loading ? (
        <ReactLoader loading={loading}></ReactLoader>
      ) : (
        <div className="sub-main-chart">
          <div className="left-side-chart display-table-at-bottom">
            <div id="chart">
              <Chart
                options={taskAnalytics}
                series={taskAnalytics.series}
                type="pie"
                width={500}
                height={320}
              />
            </div>
            <div>
              <StoryTable projectId={params.id} />
            </div>
          </div>
          <div className="left-side-chart right-side-chart">
            <div className="task-progress">
              <div className="left-progress">
                <p className="progress-left-head">Statistics: </p>
                <Table
                  className="progress-table"
                  dataSource={mainStatistics}
                  columns={mainColumns}
                />
                 <p className="progress-left-head">Issue Statistics: </p>
                <Table
                  className="progress-table"
                  dataSource={taskStatistics}
                  columns={columns}
                />
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );

};

export default EmployeeChart;
