import React, { useEffect, useState } from "react";
import { MDBDataTableV5, MDBCard } from "mdbreact";
import { MDBRow, MDBCol, MDBIcon } from "mdb-react-ui-kit";
import { Modal, Button, message } from "antd";
import { ToastContainer, toast } from "react-toastify";
import AxiosInstance from "../../../service/AxiosInstance";
import AddLocation from "./AddLocation";
import ReactLoader from "../../../components/common/ReactLoader";

const Location = () => {
  const [locationList, setLocationList] = useState([]);
  const [datatable, setDatatable] = useState({});
  const [isOpen, setIsOpen] = useState(false);
  const [isOpenDeleteModal, setIsOpenDeleteModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [locationId, setLocationId] = useState();

  useEffect(() => {
    setDatatable({
      columns: [
        {
          label: "No",
          field: "icon",
          sort: "disabled",
        },
        {
          label: "Location Name",
          field: "Location_name",
          sort: "disabled",
        },

        {
          label: "Status",
          field: "Status",
          sort: "disabled",
        },
        {
          label: "Action",
          field: "Action",
          sort: "disabled",
        },
      ],
      rows: locationList,
    });
  }, [locationList]);

  const getAllLocation = () => {
    setLoading(true);
    AxiosInstance.get("/location/getAllLocations").then((res) => {
      setLoading(false);
      let list1 = res.data.data.map((item, index) => {
        return {
          icon: index + 1,
          Location_name: item?.Location_name,
          Status: item?.Status,

          Action: (
            <div style={{ display: "flex", justifyContent: "space-around" }}>
              <MDBIcon
                fas
                icon="edit"
                style={{ color: "blue", cursor: "pointer" }}
                onClick={() => {
                  setIsOpen(true);
                  setLocationId(item?._id);
                }}
              />{" "}
              <MDBIcon
                fas
                icon="trash"
                style={{ color: "red", cursor: "pointer" }}
                onClick={() => {
                  setLocationId(item?._id);
                  setIsOpenDeleteModal(true);
                }}
              />
            </div>
          ),
        };
      });
      setLocationList(list1);
    });
  };

  const deleteLocation = (id) => {
    const urlencoded = new URLSearchParams();

    urlencoded.append("id", id);
    AxiosInstance.post("/location/deleteLocation", urlencoded).then((res) => {
      // toast.success("Location deleted successfully");
      message.success("Location deleted successfully");
      setIsOpenDeleteModal(false);
      getAllLocation();
    });
  };

  const handleCancel = (errorInfo) => {
    setIsOpenDeleteModal(false);
  };

  useEffect(() => {
    getAllLocation();
  }, []);

  const reloadList = () => {
    setIsOpen(false);
    getAllLocation();
  };

  return (
    <>
      {/* <ToastContainer /> */}
      {/* Delete Modal */}

      <Modal
        title="Are you sure to delete this record?"
        centered
        // visible={isOpenDeleteModal}
        open={isOpenDeleteModal}
        onOk={() => setIsOpenDeleteModal(false)}
        onCancel={handleCancel}
        width={380}
        footer={null}
        className="delete-modal"
      >
        <Button
          className="pms-same-btn"
          type="danger"
          onClick={() => deleteLocation(locationId)}
          style={{
            marginRight: "7%",
          }}
        >
          Delete
        </Button>
        <Button onClick={handleCancel} className="pms-same-btn-cancel">
          Cancel
        </Button>
      </Modal>

      <div className="demo">
        <MDBCard className="page-content">
          <MDBCol size="md" className="col-example">
            <h5 className="tablHeader">
              Location Master
              <Button
                className="pms-same-btn"
                type="primary"
                onClick={() => {
                  setIsOpen(true);
                  setLocationId("");
                }}
                style={{ float: "right" }}
              >
                Add
              </Button>
              {isOpen ? (
                <AddLocation
                  locationid={locationId}
                  inSidebar={true}
                  btnStyl={true}
                  reloadList={reloadList}
                  setIsOpen={(a) => {
                    setIsOpen(a);
                  }}
                />
              ) : null}
            </h5>
          </MDBCol>

          {loading ? (
            <ReactLoader loading={loading} />
          ) : (
            <div>
              <MDBDataTableV5
                hover
                entriesOptions={[10, 20, 25]}
                entries={10}
                pagesAmount={4}
                data={datatable}
                searchTop
                searchBottom={false}
                className="location-master-table scrool-set"
              />
            </div>
          )}
        </MDBCard>
      </div>
    </>
  );
};

export default Location;
