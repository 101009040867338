import { MDBDataTableV5 } from "mdbreact";
import React, { useEffect, useState } from "react";
import AxiosInstance from "../../../service/AxiosInstance";
import { Link } from "react-router-dom";

const StoryTable = ({ projectId }) => {
  const [datatable, setDatatable] = useState({ columns: [], rows: [] });
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const result = await AxiosInstance.get(
          `auth/project/getAllStory/${projectId}`
        );
        console.log(result?.data?.alltaskData, "alltaskData");
        const stories = result.data.data;
        console.log(stories, "15");
        if (stories) {
          setLoading(true);
          const filteredStories = stories.filter(
            (story) => story.Issue_type === "Story"
          );

          const employeesData = filteredStories.map((story) => ({
            storyId: (
              <Link
                style={{ fontWeight: "500" }}
                to={`/project/task/${story._id}/${projectId}`}
                target="_blank"
              >
                {story.Task_key}
              </Link>
            ),
            estHr:
              story.Task_duration === "undefined" ? "-" : story.Task_duration,
            consumerHr:
              story.totalTaskDuration && story.totalBugsDuration
                ? `${
                    story.totalTaskDuration.hours +
                    story.totalBugsDuration.hours
                  }`
                : "-",
            task: story.totalTaskDuration ? story.totalTaskDuration.hours : "-",
            bug: story.totalBugsDuration ? story.totalBugsDuration.hours : "-",
          }));
          setDatatable((prev) => ({ ...prev, rows: employeesData }));
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, [projectId, loading]);

  useEffect(() => {
    setDatatable({
      columns: [
        { label: "Story Id", field: "storyId", sort: "disabled", width: 10 },
        { label: "EST Hours", field: "estHr", sort: "disabled", width: 100 },
        {
          label: "Consumed Hours",
          field: "consumerHr",
          sort: "disabled",
          width: 100,
        },
        { label: "Task Hours", field: "task", sort: "disabled", width: 100 },
        { label: "Bug Hours", field: "bug", sort: "disabled", width: 100 },
      ],
      rows: datatable.rows, // Set rows here
    });
  }, [datatable.rows]);
  return (
    <div className="story-wise-table-parent-div">
      <h6 className="progress-left-head">Story Wise Consume Hours</h6>
      <div className="tasklisttbl">
        <MDBDataTableV5
          hover
          entriesOptions={[10, 20, 25]}
          entries={10}
          pagesAmount={4}
          data={datatable}
          searchTop={false}
          searchBottom={false}
          className="story-wise-table"
        />
      </div>
    </div>
  );
};

export default StoryTable;
