import {
  Button,
  Col,
  DatePicker,
  Form,
  Modal,
  Radio,
  Row,
  TimePicker,
  Table,
  Select,
  Input,
} from "antd";

import moment from "moment";
import { MDBCard, MDBIcon } from "mdbreact";
import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import AxiosInstance from "../../../service/AxiosInstance";
import DashboardBar from "../../../components/common/DashboardBar";
import { Option } from "antd/lib/mentions";
import PmsDatePicker from "../me/leaveModule/PmsDatePicker";

const RequestedResource = () => {
  const [rows, setRows] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [memberList, setMemberList] = useState([]);
  const [myTask, setMyTask] = useState([]);
  const [projectID, setProjectID] = useState();
  const [assignFrom, setAssignFrom] = useState("");
  const [assignTo, setAssignTo] = useState("");
  const [fromTime, setFromTime] = useState("");
  const [toTime, setToTime] = useState("");
  const [Status, setStatus] = useState("");
  const [ResourceId, setResourceId] = useState("");
  const [EmployeeName, setEmployeeName] = useState("");
  const [Role, setRole] = useState("");
  const params = useParams();
  const [filteredData, setFilteredData] = useState([]);
  const [filteredDate, setFilteredDate] = useState();
  const [form] = Form.useForm();
  let PID = params.id;

  const columns = [
    {
      title: "No",
      dataIndex: "no",
      width: 10,
    },

    {
      title: "Project Name",
      dataIndex: "Project_name",
      width: 150,
    },
    {
      title: "Requested By",
      dataIndex: "Requested_by",
      width: 150,
    },
    {
      title: "Department Name",
      dataIndex: "Department_name",
      width: 150,
    },
    {
      title: "Exp. level",
      dataIndex: "Exp_level",
      width: 150,
    },
    {
      title: "From Date",
      dataIndex: "from_Date",
      width: 150,
      render: (data) => {
        return <>{moment(data).format("DD-MM-YYYY")}</>;
      },
    },
    {
      title: "To Date",
      dataIndex: "to_Date",
      width: 150,
      render: (data) => {
        return <>{moment(data).format("DD-MM-YYYY")}</>;
      },
    },

    {
      title: "Time",
      dataIndex: "Hours",
      width: 90,
    },
    {
      title: "Status",
      dataIndex: "status",
      render: (data) => {
        return <>{data ? data : ""}</>;
      },
    },
    {
      title: "Action",
      dataIndex: "Project_id",
      width: 100,
      render: (data, record) => {
        return (
          <>
            <Link
              to={"#"}
              style={{ fontWeight: "500" }}
              onClick={() => {
                setResourceId(record?.id);
                setProjectID(data);
                setRole(record?.Department_name);
                showModal();
              }}
            >
              Assign
            </Link>
            <span>/</span>
            <Link
              to={"#"}
              style={{ fontWeight: "500" }}
              onClick={() => {
                setResourceId(record?.id);
                setProjectID(data);
                setRole(record?.Department_name);
                showDeleteModal();
              }}
            >
              Reject
            </Link>
          </>
        );
      },
    },
  ];
  const getReportList = () => {
    const formData = new URLSearchParams();
    formData.append("Project_id", PID);
    AxiosInstance.get(`auth/resource/all-list`, formData).then((res) => {
      //   dispatch(actionMyDailyReport(res.data.data));
      const taskdata = [];
      var i = 1;
      res.data.data.map((data, index) => {
        var dataObj = {
          key: index,
          no: res?.data?.data?.length - index,
          Department_name: data?.Department_id?.Department_name,
          Project_name: data?.Project_id?.Project_name,
          Project_id: data?.Project_id?._id,
          Exp_level: data?.Exp_level,
          from_Date: data?.Date?.from,
          to_Date: data?.Date?.to,
          Hours: data?.Hours === 0 ? "-" : data.Hours,
          id: data?._id,
          status: data.Assign.length
            ? data.Assign[data?.Assign?.length - 1]?.Status
            : "Pending",
          Requested_by: `${data?.Requested_by?.First_name} ${data?.Requested_by?.Middle_name} ${data?.Requested_by?.Last_name}`,
        };
        taskdata.push(dataObj);
        i++;
      });

      setMyTask(taskdata?.reverse());
      setFilteredData(taskdata);
    });
  };

  useEffect(() => {
    getReportList();
  }, []);
  useEffect(() => {
    SelectMember();
  }, [Role]);
  const SelectMember = () => {
    AxiosInstance.get(`auth/employees`).then(async (res) => {
      if (res.data.status == 1) {
        let employee = await res.data.data
          .filter((data) => data?.Role === Role)
          .map((element) => {
            return {
              value: element?._id,
              label: `${element.First_name} ${element.Last_name}`,
            };
          });
        setMemberList(employee);
        let list1 = res.data.data.map((item) => {
          return {
            icon: <MDBIcon fas icon="plus" style={{ color: "green" }} />,
            name: item?.First_name + " " + item?.Last_name,
            email: item?.Email,
            department: item?.Role,
            position: item?.Position,
          };
        });
        // if (filteredDate === undefined) {
        //   setAttendanceReport(list1)
        //   setFilteredData(list1)
        // } else {
        //   return false
        // }
        setRows(list1);
      }
    });
  };
  const onFinish = (values) => {
    const formData = new URLSearchParams();
    if (isModalOpen) {
      formData.append("Employee_id", values.Employee_Name);
      formData.append("assign_from", moment(assignFrom).format("YYYY-MM-DD"));
      formData.append("assign_to", moment(assignTo).format("YYYY-MM-DD"));
      formData.append("From_time", moment(fromTime).format("HH:mm:ss"));
      formData.append("To_time", moment(toTime).format("HH:mm:ss"));
      formData.append("Full_time", value);
    }

    formData.append("Status", isModalOpen ? "Approved" : "Rejected");
    formData.append("id", ResourceId);

    AxiosInstance.post("auth/resource/assign", formData).then(
      async (result) => {
        form.resetFields();
        getReportList();
        setIsModalOpen(false);
        setIsDeleteModalOpen(false);
      }
    );
  };

  const showDeleteModal = () => {
    setIsDeleteModalOpen(true);
  };
  const isdelete = () => {
    setIsDeleteModalOpen(false);
  };
  const handleDeleteCancel = () => {
    form.resetFields();
    setIsDeleteModalOpen(false);
  };

  const showModal = () => {
    setIsModalOpen(true);
  };
  const handleOk = () => {
    setIsModalOpen(false);
  };
  const handleCancel = () => {
    form.resetFields();
    setIsModalOpen(false);
  };

  const [value, setValue] = useState(true);

  const onChange = (e) => {
    setValue(e.target.value);
  };
  const onChange2 = (date) => {
    var m = moment(date);
    m.set({ hour: 0, minute: 0, second: 0, millisecond: 0 });
    setAssignFrom(m);
  };
  const onChange3 = (date) => {
    var m = moment(date);
    m.set({ hour: 0, minute: 0, second: 0, millisecond: 0 });

    setAssignTo(m);
  };
  const onChange4 = (time) => {
    setFromTime(time);
  };
  const onChange5 = (time) => {
    setToTime(time);
  };
  const onChange6 = (status) => {
    setStatus(status);
  };

  const onDateChange = (time) => {
    const selectedDate = moment(time).format("DD-MM-YYYY");
    setFilteredDate(selectedDate);
    const filteredReport = myTask.filter((val) => {
      console.log(
        moment(val.from_Date).format("DD-MM-YYYY"),
        selectedDate,
        "458"
      );
      return moment(val.from_Date).format("DD-MM-YYYY") === selectedDate;
    });
    console.log(filteredReport, 778);
    setFilteredData(filteredReport);
  };
  const handleSearch = (event) => {
    const getSearch = event.target.value;
    if (getSearch.length > 0) {
      const FilterData = myTask?.filter((item) => {
        return item?.Project_name.toLowerCase().includes(
          getSearch?.toLowerCase()
        );
      });
      setFilteredData(FilterData);
    } else {
      setFilteredData(myTask);
    }
    // setQuery(getSearch);
  };

  const handleSearchDepartment = (event) => {
    const getSearchDepartment = event.target.value;
    if (getSearchDepartment.length > 0) {
      const FilterData = myTask?.filter((item) => {
        return item?.Department_name.toLowerCase().includes(
          getSearchDepartment?.toLowerCase()
        );
      });
      setFilteredData(FilterData);
    } else {
      setFilteredData(myTask);
    }
  };

  const handleSearchStatus = (event) => {
    const getSearchStatus = event.target.value;
    if (getSearchStatus.length > 0) {
      const FilterData = myTask?.filter((item) => {
        return item?.status
          .toLowerCase()
          .includes(getSearchStatus?.toLowerCase());
      });
      setFilteredData(FilterData);
    } else {
      setFilteredData(myTask);
    }
  };

  return (
    <>
      <div className="">
        <div className="page-content">
          <Row className="mx-0 mydashboard-main">
            <Col className="col" xs={24} sm={24} md={24} lg={24} xl={24}>
              <div>
                <div className="left-head-title">Requested Resource</div>
                <Row className="create-project req-resource-top-inputs">
                  <Col span={4} className="req-res-input">
                    <PmsDatePicker
                      format={"DD/MM/YYYY"}
                      onChange={onDateChange}
                    />
                  </Col>
                  <Col span={4} className="req-res-input">
                    <Input
                      onChange={handleSearch}
                      placeholder="Search Project name"
                    ></Input>
                  </Col>
                  <Col span={4} className="req-res-input">
                    <Input
                      onChange={handleSearchDepartment}
                      placeholder="Search from department"
                    ></Input>
                  </Col>
                  <Col span={4} className="req-res-input">
                    <Input
                      onChange={handleSearchStatus}
                      placeholder="Search by status"
                    ></Input>
                  </Col>
                </Row>

                <div className="page-content pm-project-main">
                  <div className="tasklisttbl all-project-list pm-dash-table">
                    <Table
                      entriesOptions={[20, 25, 30]}
                      entries={20}
                      pagesAmount={4}
                      dataSource={filteredData}
                      columns={columns}
                      searchTop
                      // searchBottom={false}
                      className="pm-pro directory-list-table"
                    />
                  </div>
                </div>
              </div>
            </Col>
          </Row>

          <Modal
            title="Resource Allocation"
            open={isModalOpen}
            onOk={handleOk}
            onCancel={handleCancel}
            className="tl-kra-modal"
          >
            <Form form={form} onFinish={onFinish}>
              <div className="resource-modal-main">
                <div className="tasklisttbl all-project-list pm-dash-table">
                  <Form.Item
                    className="res-manager-lbl"
                    label="Employee Name :"
                    name="Employee_Name"
                    rules={[
                      {
                        required: true,
                        message: "Please select employee name",
                      },
                    ]}
                  >
                    <Select
                      placeholder="Select"
                      className="sort-name-inner"
                      onChange={(e) => setEmployeeName(e)}
                      allowClear
                    >
                      {memberList?.map((option, index) => (
                        <Option key={index} value={option.value}>
                          {option.label}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>
                </div>
              </div>
              <div>
                <Form.Item
                  className="res-manager-lbl"
                  label="Select From Date"
                  name="Select_From_Date"
                  rules={[
                    {
                      required: true,
                      message: "Please select date",
                    },
                  ]}
                >
                  <DatePicker
                    onChange={onChange2}
                    value={assignFrom}
                    placeholder="From"
                  />
                </Form.Item>
                <Form.Item
                  className="res-manager-lbl"
                  label="Select To Date"
                  name="Select_To_Date"
                  rules={[
                    {
                      required: true,
                      message: "Please select date",
                    },
                  ]}
                >
                  <DatePicker
                    onChange={onChange3}
                    value={assignTo}
                    placeholder="To"
                  />
                </Form.Item>
              </div>
              <div
                className="res-manager-lbl"
                style={{
                  margin: "10px 0",
                  display: "flex",
                  alignItems: "center",
                  fontSize: "14px",
                }}
              >
                <h6
                  style={{ margin: "0", textAlign: "left" }}
                  className="ant-form-item-label"
                >
                  {" "}
                  Assign For :
                </h6>
                <Radio.Group onChange={onChange} value={value}>
                  <Radio value={true} className="none-form-resource">
                    Full Time
                  </Radio>
                  <Radio value={false} className="parttime-form">
                    Part Time
                  </Radio>
                </Radio.Group>
              </div>
              <div className={value ? "hide-form" : "show-form"}>
                <div className="resource-timer">
                  <Form.Item
                    className="res-manager-lbl"
                    label="Select Start Time"
                    name="Select_Start_Time"
                    rules={[
                      {
                        required: value ? false : true,
                        message: "Please select time",
                      },
                    ]}
                  >
                    <TimePicker placeholder="Start Time" onChange={onChange4} />
                  </Form.Item>
                  <Form.Item
                    className="res-manager-lbl"
                    label="Select End Time"
                    name="Select_End_Time"
                    rules={[
                      {
                        required: value ? false : true,
                        message: "Please select time",
                      },
                    ]}
                  >
                    <TimePicker placeholder="End Time" onChange={onChange5} />
                  </Form.Item>
                </div>
              </div>
              <div style={{ textAlign: "right", marginTop: "20px" }}>
                <Button
                  className="pms-same-btn mb-0"
                  htmlType="submit"
                  style={{ marginRight: "10px" }}
                >
                  Submit
                </Button>
                <Button onClick={handleCancel} className="pms-same-btn-cancel">
                  Cancel
                </Button>
              </div>
            </Form>
          </Modal>
          <Modal
            title="Resource Reject"
            open={isDeleteModalOpen}
            onOk={isdelete}
            onCancel={handleDeleteCancel}
            className="tl-kra-modal"
          >
            <Form form={form} onFinish={onFinish}>
              <div>Are you sure you want to Reject?</div>

              <div style={{ textAlign: "right", marginTop: "20px" }}>
                <Button
                  className="pms-same-btn mb-0"
                  htmlType="submit"
                  style={{ marginRight: "10px" }}
                >
                  Reject
                </Button>
                <Button
                  onClick={handleDeleteCancel}
                  className="pms-same-btn-cancel"
                >
                  Cancel
                </Button>
              </div>
            </Form>
          </Modal>
        </div>
      </div>
    </>
  );
};

export default RequestedResource;
