import React, { useState, useEffect } from "react";
import {
  Modal,
  Form,
  Input,
  Button,
  Row,
  Col,
  Select,
  Upload,
  Checkbox,
  Image,
  DatePicker,
  message,
} from "antd";
import PmsDatePicker from "../me/leaveModule/PmsDatePicker";
import AxiosInstance from "../../../service/AxiosInstance";
import { MDBIcon } from "mdb-react-ui-kit";
import "antd/dist/antd.css";
import { UploadOutlined, DeleteOutlined } from "@ant-design/icons";
import "./project.css";
import {
  Requirement,
  techStackOption,
  ProjectManagerList,
  clientList,
  EmployeeList,
  PositionEmployeeListOption,
  VerticalList,
} from "../Options";
import moment from "moment";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { Option } from "antd/lib/mentions";
import { ToastContainer, toast } from "react-toastify";
import { useSelector } from "react-redux";
import { Button as Buttonss } from "@mui/material";

const imageUrl = "https://bucket-pms.s3.ap-south-1.amazonaws.com/s3-upload/";

// const props = {
//   className: 'upload-list-inline',
//   listType: 'picture',
//   maxCount: '50',
//   multiple: 'multiple',
//   openFileDialogOnClick: 'false',
//   action: 'demo_image',
//   // action: { url },
// };
const { TextArea } = Input;
// main component
const Edit = ({ setCount, id, projectData, getProjectData }) => {
  const [PmName, setPmName] = useState("");
  const [PmNamechng, setPmNamechng] = useState("");
  const [form] = Form.useForm();
  const [visible, setVisible] = useState(false);
  const [textEditer, setTextEditer] = useState("");
  const [textEditerCount, setTextEditerCount] = useState(0);
  const [Count2, setCount2] = useState(0);
  const [olddocument, Setolddocument] = useState([""]);
  const permssionsState = useSelector((state) => state.Permissions.Permissions);
  const [rolePermissions, setRolepermissions] = useState(permssionsState);
  const [url, setUrl] = useState("");
  const [file, setFile] = useState("");
  const [description, setDescription] = useState(
    projectData?.Project_description || ""
  );
  const fileHandler = (e) => {
    const File = e[0];
    console.log(File);
    let urllink = File.name;
    setUrl(urllink);
  };
  useEffect(() => {
    setUrl(projectData?.Document);
  }, [projectData?.Document]);
  useEffect(() => {
    setRolepermissions(permssionsState);
  }, [permssionsState]);
  // useEffect(() => {
  //   AxiosInstance.get("auth/employees").then(async (res) => {
  //     if (res.data.status == 1) {
  //       let EM = await res.data.data.filter(
  //         (item) => console.log(200)
  //         // item._id == PmNamechng
  //       );
  //       // let EmList = await EM.map((item) => {  });
  //       PmNamechng
  //         ? setPmName(EM[0]?.First_name + " " + EM[0]?.Last_name)
  //         : setPmName(projectData.Project_manager);
  //     }
  //   });
  // }, [PmNamechng, projectData]);
  useEffect(() => {
    form.setFields([
      {
        Project_name: "field-to-update",
        errors: ["error-string"],
      },
    ]);
    Setolddocument(projectData.Document);
    // setPmName(projectData.Project_manager);
  }, [projectData]);
  //onchange ck editor

  const onDescriptionChange = (evt) => {
    // setTextEditer(evt.editor.getData());
    setTextEditerCount(() => textEditerCount + 1);
  };
  const content = () => {
    return textEditer
      ? textEditer
      : textEditerCount === 0
      ? projectData?.Project_description
      : "";
  };
  const onCkeditorChange = (event, editor) => {
    const data = editor.getData();
    setTextEditer(data);
    setDescription(data);
    sessionStorage.setItem("projectDescription", data);
  };
  useEffect(() => {
    const savedDescription = sessionStorage.getItem("projectDescription");
    if (savedDescription) {
      setDescription(savedDescription);
    }
  }, []);
  let config = {
    toolbarGroups: [
      // { name: "document", groups: ["mode", "document", "doctools"] },
      {
        name: "editing",
        groups: ["find", "selection", "spellchecker", "editing"],
      },
      { name: "forms", groups: ["forms"] },
      { name: "basicstyles", groups: ["basicstyles", "cleanup"] },
      {
        name: "paragraph",
        groups: ["list", "indent", "blocks", "align", "bidi", "paragraph"],
      },
      { name: "styles", groups: ["Styles", "Format", "Font", "FontSize"] },
      { name: "clipboard", groups: ["clipboard", "undo"] },
      { name: "others", groups: ["others"] },
      { name: "about", groups: ["about"] },
    ],
    removeButtons:
      "Save,NewPage,Preview,Print,Templates,Cut,Copy,Paste,PasteText,PasteFromWord,Find,SelectAll,Scayt,Replace,Form,Checkbox,Textarea,Select,Button,ImageButton,HiddenField,CreateDiv,BidiLtr,BidiRtl,Language,Flash,Smiley,SpecialChar,PageBreak,Iframe,Anchor,ShowBlocks,About,CopyFormatting,Undo,Redo",
    fontSize_sizes: "16/16px;24/24px;48/48px;",
    font_names:
      "Arial/Arial, Helvetica, sans-serif;" +
      "Times New Roman/Times New Roman, Times, serif;" +
      "Verdana",
    allowedContent: true,
  };

  const onFinish = (values) => {
    let pmName;
    let pmId;
    let tpmName;
    let tpmId;
    let salesId;
    let salesName;
    let verticalName;
    let verticalId;
    if (projectData.Project_manager == values.projectManagerId) {
      pmName = values.projectManagerId;
      pmId = projectData.Project_manager_id;
    } else {
      pmName = JSON.parse(values.projectManagerId)?.name;
      pmId = JSON.parse(values.projectManagerId)?.id;
    }
    if (projectData.Sales == values.salesId) {
      salesName = values.salesId;
      salesId = projectData.Sales_id;
    } else {
      salesName = JSON.parse(values.salesId)?.name;
      salesId = JSON.parse(values.salesId)?.id;
    }

    if (
      projectData.Technical_project_manager == values.technicalProjectManagerId
    ) {
      tpmName = values.technicalProjectManagerId;
      tpmId = projectData.Technical_project_manager_id;
    } else {
      tpmName = JSON.parse(values.technicalProjectManagerId)?.name;
      tpmId = JSON.parse(values.technicalProjectManagerId)?.id;
    }
    if (projectData.Vertical == values.verticalId) {
      verticalName = values.verticalId;
      verticalId = projectData.Vertical_id;
    } else {
      verticalName = JSON.parse(values.verticalId)?.name;
      verticalId = JSON.parse(values.verticalId)?.id;
    }
    //  let pmName=
    //  let pmId=
    if (values) {
      // const formData = new URLSearchParams();
      const formData = new FormData();
      formData.append(
        "Project_name",
        values.projectName ? values.projectName : "-"
      );
      formData.append("projectId", values.projectId ? values.projectId : "-");
      formData.append(
        "Client_name",
        values.clientName ? values.clientName : "-"
      );
      formData.append("Tech_stack", values.techStack ? values.techStack : "-");
      formData.append("Project_manager", pmName ? pmName : "-");
      if (pmId !== undefined) {
        formData.append("Project_manager_id", pmId ? pmId : "-");
      }
      formData.append("Technical_project_manager", tpmName ? tpmName : "-");
      if (tpmId !== undefined) {
        formData.append("Technical_project_manager_id", tpmId ? tpmId : "-");
      }
      formData.append("Sales", salesName ? salesName : "-");

      if (salesId !== undefined) {
        formData.append("Sales_id", salesId);
      }
      formData.append(
        "Project_short_name",
        values.projectShortName ? values.projectShortName : "-"
      );
      formData.append(
        "Project_awarded_date",
        values.projectAwardedDate ? values.projectAwardedDate : "-"
      );
      formData.append(
        "Project_start_date",
        values.projectStartDate ? values.projectStartDate : "-"
      );
      formData.append("Vertical", verticalName ? verticalName : "-");
      if (verticalId !== undefined) {
        formData.append("Vertical_id", verticalId ? verticalId : "-");
      }
      if (values.projectDueDate !== "-" || undefined) {
        formData.append(
          "Project_due_date",
          moment(values.projectDueDate).format("YYYY-MM-DD")
        );
      }

      // formData.append(
      //   'olddocument',
      //   olddocument ? JSON.stringify(olddocument) : []
      // );
      // for (let i = 0; i < olddocument?.length; i++) {
      //   formData.append("olddocument", olddocument[i]);
      // }
      // formData.append('Project_description',  textEditer ? textEditer : textEditer === 0 : projectData.Project_description : textEditer);
      formData.append(
        "Project_description",
        textEditer
          ? textEditer
          : textEditerCount === 0
          ? projectData?.Project_description
          : ""
      );
      formData.append("Remark", values.remark ? values.remark : "-");
      formData.append(
        "Client_requirement",
        values.clientRequirement ? values.clientRequirement : "-"
      );

      let fileData = file && file[0] ? [...file] : "";
      if (fileData[0] !== undefined) {
        // formData.append("image", fileData[0] ? fileData[0] : "")
        Array.from(file).forEach((image) => {
          formData.append("image", image);
        });
      }
      // formData.append('Project_member', values.projectMember);
      formData.append("id", projectData._id);
      const config = {
        headers: {
          "content-type": "multipart/form-data",
        },
      };
      AxiosInstance.post("auth/projects/edit-details", formData, config)
        .then((res) => {
          getProjectData()
          if (res.data.status == 1) {
            // toast.success("Project updated Successfully");
            message.success("Project updated Successfully");

 

            projectData = res.data.data;
            // form.resetFields();
            setTextEditer("");
            setCount((pre) => pre + 1);
            setCount2((pre) => pre + 1);
            setTextEditerCount(0);
          }
          if (res.data.status == 0) {
            projectData = res.data.data[0];
        
            message.error(res.data.message);
         
     
          }
        })
        .catch((err) => console.log(err, "err"));
    }
    // form.resetFields();
    setVisible(false);
    // form.resetFields();
  };



  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  const handleCancel = (errorInfo) => {
    form.resetFields();
    setVisible(false);
    setTextEditerCount(0);
  };
  useEffect(() => {
    // form.resetFields();
  }, [visible]);

  function ImageDemo({ element, index }) {
    // let linkarray = element.split('.');
    let linkarray =
      element !== null && element !== undefined ? element?.split(".") : [];
    let exe = "jpg,jpeg,png";
    return (
      <>
        {/* <div className="image"> */}
        <div>
          <Image
            width={80}
            height={80}
            src={
              exe.includes(linkarray[linkarray.length - 1])
                ? imageUrl + element
                : "https://cdn1.iconfinder.com/data/icons/leto-files/64/leto_files-76-512.png"
            }
          />
          {element && <a href="#">{element}</a>}
        </div>
        <div>{/* <DeleteOutlined onClick={() => deleteimage(index)} /> */}</div>
        {/* </div> */}
      </>
    );
  }

  const deleteimage = (index) => {
    let newarray = [...olddocument];
    newarray.splice(index, 1);
    // Setolddocument([...newarray]);
  };

  useEffect(() => {

    if (projectData) {
      form.setFieldsValue({
        projectId: projectData.projectId,
    
      });
    }
  }, [projectData, form]);

  return (
    <>
      {/* <ToastContainer /> */}
      {rolePermissions?.Project?.Edit_Project == "1" ? (
        <Button className="pms-same-btn" onClick={() => setVisible(true)}>
          <MDBIcon fas icon="edit" />
        </Button>
      ) : (
        ""
      )}
      <Modal
        title="Edit"
        centered
        // visible={visible}
        open={visible}
        onOk={() => setVisible(false)}
        onCancel={handleCancel}
        width={1000}
        height={640}
        bodyStyle={{ paddingTop: "5px 15px" }}
        footer={null}
      >
        <Form
      
          form={form}
          name="control-hooks"
          layout="vertical"
          // name="basic"
          labelCol={{
            span: 24,
          }}
          wrapperCol={{
            span: 24,
          }}
          // initialValues={{
          //   remember: true,
          // }}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          autoComplete="off"
          className="left-margin main-project-edit-modal create-project-modal"
          // initialValue={projectData.Project_awarded_date}
          // initialValue={projectData.Project_start_date}
          initialValues={{
            projectAwardedDate:
              projectData.Project_awarded_date !== "-"
                ? moment(
                    projectData.Project_awarded_date
                      ? JSON.stringify(
                          new Date(projectData.Project_awarded_date)
                        ).slice(1, 25)
                      : ""
                  )
                : "",
            projectStartDate:
              projectData.Project_start_date !== "-"
                ? moment(
                    projectData.Project_start_date
                      ? JSON.stringify(
                          new Date(projectData.Project_start_date)
                        ).slice(1, 25)
                      : ""
                  )
                : "",
          }}
        >
          {console.log(projectData?.projectId, 400)}
          <Row className="res-full-width">
            <Col span={11}>
              <Form.Item
                label="Project Id:"
                name="projectId"
                initialValue={projectData?.projectId}
                rules={[
                  {
                    required: true,
                    message: "Please Enter Project Id!",
                    whitespace: true,
                  },
                ]}
              >
                <Input/>
              </Form.Item>
            </Col>
            <Col span={11}>
              <Form.Item
                label="Vertical"
                name="verticalId"
                initialValue={projectData?.Vertical}
              >
                <Select
                  onChange={(e) => {
                    setPmNamechng(e);
                  }}
                  showSearch="true"
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    option.children
                      .toLocaleLowerCase()
                      .includes(input.toLocaleLowerCase())
                  }
                >
                  {VerticalList()?.map((item, index) => {
                    return (
                      <Option
                        key={index}
                        value={JSON.stringify({
                          id: item.value,
                          name: item.label,
                        })}
                      >
                        {item.label}
                      </Option>
                    );
                  })}
                </Select>
              </Form.Item>
            </Col>
          </Row>
          <Row className="res-full-width">
            <Col span={11}>
              <Form.Item
                label="Client Name:"
                name="clientName"
                initialValue={projectData?.Client_name}
                rules={[
                  {
                    required: true,
                    message: "Please Enter Client Name!",
                  },
                ]}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col span={11}>
              <Form.Item
                label="Project Name:"
                name="projectName"
                initialValue={projectData?.Project_name}
                rules={[
                  {
                    required: true,
                    message: "Please Enter Project Name!",
                    whitespace: true,
                  },
                ]}
              >
                <Input />
              </Form.Item>
            </Col>
          </Row>

          <Row className="res-full-width">
            <Col span={11}>
              <Form.Item
                label="Tech Stack:"
                name="techStack"
                initialValue={
                  projectData.Tech_stack
                    ? projectData.Tech_stack.split(",")
                    : ""
                }
                rules={[
                  {
                    required: true,
                    message: "Please Select Tech Stack!",
                  },
                ]}
              >
                <Select
                  mode="multiple"
                  placeholder="Please Select Tech Stack"
                  options={techStackOption}
                />
              </Form.Item>
            </Col>
            <Col span={11}>
              <Form.Item
                label="Project Short Name:"
                name="projectShortName"
                initialValue={projectData?.Project_short_name}
                rules={[
                  {
                    required: true,
                    message: "Please Enter Project Short Name!",
                    whitespace: true,
                  },
                ]}
              >
                <Input disabled="true" />
              </Form.Item>
            </Col>

            <div className="view-dopdown ant-col-24">
              <Row className="ant-row">
                <Col span={11}>
                  <Form.Item
                    label="Project Manager:"
                    name="projectManagerId"
                    initialValue={projectData?.Project_manager}
                    // initialValue={()=> {
                    //   let PM = projectData?.Project_manager.split(',')
                    //   let PM1 = PM.toString()
                    //   return PM1
                    // }}
                    rules={[
                      {
                        required: true,
                        message: "Please Enter Project Manager!",
                        whitespace: true,
                      },
                    ]}
                  >
                    <Select
                      onChange={(e) => setPmNamechng(e)}
                      placeholder="Please Select Project Manager"
                      showSearch="true"
                      optionFilterProp="children"
                      filterOption={(input, option) =>
                        option.children
                          .toLocaleLowerCase()
                          .includes(input.toLocaleLowerCase())
                      }
                    >
                      {PositionEmployeeListOption(
                        ["Project Manager"],
                        "withDesignation"
                      )?.map((item, index) => {
                        return (
                          <Option
                            key={index}
                            value={JSON.stringify({
                              id: item.value,
                              name: item.label,
                            })}
                          >
                            {item.label}
                          </Option>
                        );
                      })}

                      {/* {ProjectManagerList()?.map((item, index) => {
                      return (
                        <Select.Option key={index} value={item.id}>
                          {item.label}
                        </Select.Option>
                      );
                    })} */}
                    </Select>
                  </Form.Item>
                </Col>
                <Col span={11}>
                  <Form.Item
                    label="Technical Project Manager:"
                    name="technicalProjectManagerId"
                    initialValue={projectData?.Technical_project_manager}
                    // initialValue={()=> {
                    //   let PM = projectData?.Project_manager.split(',')
                    //   let PM1 = PM.toString()
                    //   return PM1
                    // }}
                    rules={[
                      {
                        required: true,
                        message: "Please Enter Technical Project Manager!",
                        whitespace: true,
                      },
                    ]}
                  >
                    <Select
                      onChange={(e) => setPmNamechng(e)}
                      placeholder="Please Select Technical Project Manager"
                      showSearch="true"
                      optionFilterProp="children"
                      filterOption={(input, option) =>
                        option.children
                          .toLocaleLowerCase()
                          .includes(input.toLocaleLowerCase())
                      }
                    >
                      {PositionEmployeeListOption([
                        "Technical Project Manager",
                      ])?.map((item, index) => {
                        return (
                          <Option
                            key={index}
                            value={JSON.stringify({
                              id: item.value,
                              name: item.label,
                            })}
                          >
                            {item.label}
                          </Option>
                        );
                      })}

                      {/* {ProjectManagerList()?.map((item, index) => {
                      return (
                        <Select.Option key={index} value={item.id}>
                          {item.label}
                        </Select.Option>
                      );
                     })} */}
                    </Select>
                  </Form.Item>
                </Col>
              </Row>
            </div>
          </Row>
          <Row>
            <Col span={11}>
              <Form.Item
                label="Sales"
                name="salesId"
                initialValue={projectData?.Sales}
                // rules={[
                //   {
                //     required: false,
                //     message: "Please Select Sales!",
                //     whitespace: true,
                //   },
                // ]}
              >
                <Select
                  onChange={(e) => setPmNamechng(e)}
                  placeholder="Please Select Sales"
                  // options={PositionEmployeeListOption(['Technical Project Manager','Project Manager'])}
                  showSearch="true"
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    option.children
                      .toLocaleLowerCase()
                      .includes(input.toLocaleLowerCase())
                  }
                >
                  {/* // 'BDE','BDM','BA','AVP Sales','VP Sales','Business Analyst SDE 1' */}
                  {PositionEmployeeListOption(
                    [
                      "BDE",
                      "BDM",
                      "BA",
                      "AVP Sales",
                      "VP Sales",
                      "Business Analyst SDE 1",
                      "Team Leader",
                    ],
                    "withDesignation"
                  )?.map((item, index) => {
                    return (
                      <Option
                        key={index}
                        value={JSON.stringify({
                          id: item.value,
                          name: item.label,
                        })}
                      >
                        {/*  <Select.Option key={index} value={item.value}> */}
                        {item.label}
                      </Option>
                    );
                  })}
                </Select>
              </Form.Item>
            </Col>
            <Col span={11}>
              <Form.Item
                label="Project Awarded Date:"
                name="projectAwardedDate"
              >
                <DatePicker
                  format={"DD/MM/YYYY"}
                  // disabledDate={(d) => !d || d.isSameOrAfter(Date.now()-86400000)}
                  // disabledDate={(d) => d < moment(Date.now()-86400000)}
                  defaultValue={moment(projectData.Project_awarded_date)}
                  disabledDate={(d) => !d || d.isSameOrAfter(Date.now())}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col span={11}>
              <Form.Item label="Project Start Date:" name="projectStartDate">
                <DatePicker
                  format={"DD/MM/YYYY"}
                  // disabledDate={(d) => !d || d.isSameOrBefore(Date.now())}
                  // disabledDate={(d) => d < moment(Date.now()-86400000)}
                  defaultValue={moment(projectData.Project_start_date)}
                />
              </Form.Item>
            </Col>
            <Col span={11}>
              <Form.Item label="Project Due Date:" name="projectDueDate">
                <DatePicker
                  format={"DD/MM/YYYY"}
                  // disabledDate={(d) => !d || d.isSameOrBefore(Date.now())}
                  // disabledDate={(d) => d < moment(Date.now()-86400000)}
                  defaultValue={moment(projectData.Project_due_date)}
                />
              </Form.Item>
            </Col>
          </Row>

          <Row>
            <Col span={24}>
              <div style={{ marginBottom: "10px", marginTop: "11px" }}>
                Description<span style={{ color: "red" }}>*</span>
              </div>
              <div>
                <CKEditor
                  // activeClass="p10"
                  className="cknew-editor"
                  editor={ClassicEditor}
                  config={config}
                  data={projectData?.Project_description}
                  content={content()}
                  events={{
                    change: onDescriptionChange,
                  }}
                  onChange={onCkeditorChange}
                  // onChange={(event, editor) => {
                  //   const data = editor.getData();
                  //   setTextEditer(data)
                  // }}
                />
              </div>
            </Col>
          </Row>
          <Row>
            <Col span={24}>
              <Form.Item
                label="Remark"
                name="remark"
                initialValue={projectData?.Remark}
              >
                <TextArea rows={3} />
              </Form.Item>
            </Col>
          </Row>

          <Row>
            <Col span={24}>
              <Form.Item
                className="client-req-type"
                label="Client Requirement:"
                name="clientRequirement"
                initialValue={
                  projectData.Client_requirement
                    ? projectData.Client_requirement.split(",")
                    : ""
                }
              >
                <Checkbox.Group options={Requirement} />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Buttonss
                variant="contained"
                component="label"
                className="bttn-clr pms-same-btn pms-same-btn-upload-new"
              >
                Upload
                <input
                  accept="files/*"
                  multiple
                  type="file"
                  hidden
                  onChange={(e) => {
                    setFile(e.target.files);
                    fileHandler(e.target.files);
                  }}
                />
              </Buttonss>{" "}
              &nbsp;
              {/* <a href={url} target="_blank">{url}</a>  */}
            </Col>
            <Col>
              <Form.Item>
                <div className="preview-image">
                  {olddocument !== undefined &&
                    olddocument.map((element, index) => (
                      <ImageDemo element={element} index={index} />
                    ))}
                </div>
              </Form.Item>
            </Col>
          </Row>
          <Form.Item>
            <div style={{ textAlign: "end" }}>
              <Button
                type="primary"
                className="margin-left-5 pms-same-btn"
                htmlType="submit"
                style={{ marginRight: "10px" }}
                // onClick={onFinish()}
              >
                Submit
              </Button>
              <Button onClick={handleCancel} className="pms-same-btn-cancel">
                cancel
              </Button>
            </div>
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};

export default Edit;
