import React, { useEffect, useState } from "react";
import "antd/dist/antd.css";
import { Modal, Form, Button, Select } from "antd";
import {
  Box,
  Grid,
  TextField,
  IconButton,
  InputAdornment,
  Autocomplete,
  Tooltip,
} from "@mui/material";
import MenuItem from "@mui/material/MenuItem";

import SearchIcon from "@mui/icons-material/Search";
import { Link, useHistory } from "react-router-dom";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";

import {
  MDBNavbarItem,
  MDBNavbarLink,
  MDBNavbarNav,
  MDBIcon,
  MDBCol,
} from "mdb-react-ui-kit";
import { useDispatch, useSelector } from "react-redux";
import AxiosInstance from "../../../service/AxiosInstance";
import AddTask from "../tasks/Add";
import { ToastContainer, toast } from "react-toastify";
import { AllPermissions } from "../../../redux/actions/Index";
import { Png } from "../../../assets/png";
import FilterModal from "../../../components/common/FilterModal";

import { useLocation } from "react-router-dom";
const ProjectBar = ({
  projectId,
  updateList,
  setIsFilterModalOpen,
  setCount,
  count,
  id,
  setList,
  taskList,
}) => {
  const [projectList, setProjectList] = useState([]);
  const [selectedProject, setSelectedProject] = useState("");
  const [projectDetail, setProjectDetail] = useState([]);
  const [taskAddIsShow, setTaskAddIsShow] = useState(false);
  const [isSearchActive, setIsSearchActive] = useState(false);

  const [form] = Form.useForm();
  const [isFilterShow, setIsFilterShow] = useState(false);
  const [visible, setVisible] = useState(false);
  //const [rolePermissions, setRolepermissions] = useState({});
  const rolePermissions = useSelector((state) => state.Permissions.Permissions);
  // const [rolePermissions, setRolepermissions] = useState(permssionsState);
  let userData = JSON.parse(localStorage.getItem("userdata"));
  const dispatch = useDispatch();
  const location = useLocation();

  useEffect(() => {
    //getRolePermissionList();
  }, []);
  // const handleCancel = (errorInfo) => {
  //   form.resetFields();
  //   setVisible(false);
  // };

  // const deleteData = async () => {
  //   const formdata = new URLSearchParams();
  //   formdata.append("id", projectId);
  //   await AxiosInstance.post("auth/projects/delete", formdata).then((res) => {
  //     if (res.data.status) {
  //       setList((pre) => pre + 1);
  //       setVisible(false);
  //     }
  //   });
  //   setVisible(false);
  // };

  useEffect(() => {
    //API
    const formData = new URLSearchParams();
    formData.append("id", projectId);
    AxiosInstance.post("auth/projects/detail?detailprojectbar", formData).then(
      async (res) => {
        if (res.data.status == 1) {
          setProjectDetail(res?.data?.data);
          taskList = { taskList };
        }
      }
    );
  }, [count]);

  // const getTodoList = async () => {
  //   console.log("888888");
  //   try {
  //     const res = await AxiosInstance.get("/auth/projectslist3");
  //     console.log("7777777", res.data.data);
  //     setProjectList(res?.data?.data);
  //   } catch (error) {
  //     console.error(error);
  //   }
  // };

  // useEffect(() => {
  //   getTodoList();
  // }, []);

  const history = useHistory();

  useEffect(() => {
    const path = location.pathname;
    setIsFilterShow(path.startsWith("/project/tasks/"));
  }, [location.pathname]);

  return (
    <div>
      {/* <ToastContainer /> */}

      <MDBNavbarNav className="page-menu-nav page-menu-nav-pro">
        <div className="page-menu-nav2">
          <MDBNavbarItem>
            <Link to={`/project/view/${projectId}`} active>
              {" "}
              <MDBNavbarLink active aria-current="page" href="#">
                Dashboard
              </MDBNavbarLink>
            </Link>
          </MDBNavbarItem>
          {rolePermissions?.Project?.All_Task == 1 ? (
            <MDBNavbarItem>
              <Link to={`/project/tasks/${projectId}`} active>
                <MDBNavbarLink>All Tasks</MDBNavbarLink>
              </Link>
            </MDBNavbarItem>
          ) : (
            ""
          )}
          {/* {rolePermissions?.Project?.Backlog == 1 ? ( */}
          <MDBNavbarItem>
            <Link to={`/project/backlog/${projectId}`} active>
              {" "}
              <MDBNavbarLink>Backlog</MDBNavbarLink>
            </Link>
          </MDBNavbarItem>
          {/* ) : ( */}
          {/* "" */}
          {/* )} */}

          {rolePermissions?.Project?.View_Member == 1 ? (
            <MDBNavbarItem>
              <Link to={`/project/resource/${projectId}`}>
                {" "}
                <MDBNavbarLink>Resource</MDBNavbarLink>
              </Link>
            </MDBNavbarItem>
          ) : (
            ""
          )}
          {rolePermissions?.Milestone_Project &&
          Object.values(rolePermissions?.Milestone_Project).includes("1") ==
            true ? (
            <MDBNavbarItem>
              <Link to={`/project/milestone/${projectId}`}>
                {" "}
                <MDBNavbarLink>Milestone</MDBNavbarLink>
              </Link>
            </MDBNavbarItem>
          ) : (
            ""
          )}

          {rolePermissions?.Sprint_Project &&
          Object.values(rolePermissions?.Sprint_Project).includes("1") ==
            true ? (
            <MDBNavbarItem>
              <Link to={`/project/sprint/${projectId}`}>
                {" "}
                <MDBNavbarLink>Sprint</MDBNavbarLink>
              </Link>
            </MDBNavbarItem>
          ) : (
            ""
          )}
        </div>

        <div className="pro-add-task">
          {/* <Box className="project-change-main">
            <Grid item xs={8} md={6} lg={3}>
              <Autocomplete
                popupIcon={<KeyboardArrowDownIcon />}
                className="cus-autocomplete"
                disablePortal
                id="combo-box-demo"
                options={projectList || []}
                getOptionLabel={(option) => option.Project_name}
                sx={{ width: 300 }}
                value={
                  projectList.find(
                    (option) => option._id === selectedProject
                  ) || null
                }
                onChange={(event, newValue) => {
                  const projectId = newValue ? newValue._id : "";
                  setSelectedProject(projectId);

                  // history.push(`/project/view/${projectId}`);
                  history.push(`/project/tasks/${projectId}`);
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    className="select-box-input"
                    placeholder="Change Project"
                    value={
                      projectList.find(
                        (option) => option._id === selectedProject
                      )?.Project_name || ""
                    }
                  />
                )}
              />
            </Grid>
          </Box> */}

          {rolePermissions?.Project?.Create_Task == "1" ? (
            <>
              {isFilterShow == true && (
                <Tooltip title="Filter">
                  <Box
                    onClick={() => {
                      setIsFilterModalOpen(true);
                    }}
                  >
                    <img src={Png.filterIcon} className="filter-icon" />
                  </Box>
                </Tooltip>
              )}
              {/* <FilterModal  open={isFilterModalOpen} onClose={() => setIsFilterModalOpen(false)}  /> */}

              <Button
                className="btn-add-link add-task-btn"
                onClick={() => setTaskAddIsShow(true)}
              >
                <MDBIcon className="plus-btn" fas icon="plus" />
                <span
                  style={{
                    paddingLeft: "15px",
                    fontWeight: "600",
                    fontSize: "14px",
                  }}
                >
                  Create Task
                </span>
              </Button>
            </>
          ) : (
            ""
          )}

          {taskAddIsShow ? (
            <AddTask
              inSidebar={true}
              btnStyl={true}
              projectDetail={projectDetail}
              setFlage={setCount}
              setTaskAddIsShow={(a) => {
                setTaskAddIsShow(a);
              }}
              updateList={updateList}
              taskList={taskList}
              data={localStorage.getItem("ProjectName")}
            />
          ) : null}
          {/* {rolePermissions?.Project?.Delete_Project == "1" ? (
            <Button className="btn-tresh-link" onClick={() => setVisible(true)}>
              <MDBIcon fas icon="trash" />
              <span style={{ paddingLeft: "15px", fontWeight: "600" }}>
                Delete Project
              </span>
            </Button>
          ) : (
            ""
          )} */}

          {/* Delete model */}
          {/* <Modal
            title="Are you sure to delete this project ?"
            centered
            visible={visible}
            onOk={() => setVisible(false)}
            onCancel={handleCancel}
            width={380}
            bodyStyle={{
              marginLeft: "16%",
              paddingBottom: "6% !important",
              marginTop: "6% !important",
            }}
            footer={null}
          >
            <Button
              type="danger"
              onClick={() => deleteData()}
              style={{
                marginRight: "10%",
                marginLeft: "5%",
                marginTop: "26px !important",
              }}
            >
              Delete
            </Button>
            <Button onClick={handleCancel}>Cancel</Button>
          </Modal> */}
        </div>
      </MDBNavbarNav>
    </div>
  );
};

export default ProjectBar;
