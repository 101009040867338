import { Spin } from "antd";
import moment from "moment";
import React, { useState, useEffect } from "react";
import AxiosInstance from "../../../service/AxiosInstance";
// main component

//getting loggedin user data

//getting project id

const TaskTimer = ({ projectID, taskID }) => {
  const [time, setTime] = useState(0);
  const [taskStarted, setTaskStarted] = useState(null);
  const [hours, setHours] = useState(0);
  const [minutes, setMinutes] = useState(0);
  const [second, setSeconds] = useState(0);
  const [loading, setLoading] = useState(false);
  const HideShowStart = () => {
    setLoading(true);
    let formData = new URLSearchParams();
    formData.append("Task_id", taskID);
    formData.append("Project_id", projectID);

    // formData.append('Status', st)

    AxiosInstance.post("auth/tasks/tasktimer", formData).then((res) => {
      setLoading(false);
      if (res.data.data.Status == "Stopped") {
        setTaskStarted(false);
        setTime(0);
      } else {
        setTaskStarted(true);
      }
    });
  };
  useEffect(() => {
    setLoading(true);

    let formData = new URLSearchParams();
    formData.append("Task_id", taskID);
    formData.append("Project_id", projectID);
    // formData.append('Status', st)

    AxiosInstance.post("auth/tasks/gettasktimer", formData).then((res) => {
      setLoading(false);
      if (res.data.data.Status == "Running") {
        setTaskStarted(true);
        let difference = moment(res.data.data.Check_In).diff(
          moment(),
          "seconds"
        );
        console.log("difference", difference * 10);

        //        setTime(moment(res.data.data.Check_In).utcOffset('+05:30'))

        setTime(Math.abs(difference));
      } else {
        setTaskStarted(false);

        // console.log(difference*10);

        //        setTime(moment(res.data.data.Check_In).utcOffset('+05:30'))

        setTime(0);
      }
    });
  }, []);
  useEffect(() => {
    let interval;

    if (taskStarted === true) {
      interval = setInterval(() => {
        setTime((prevTime) => prevTime + 1);
      }, 1000);
    } else if (taskStarted === false) {
      clearInterval(interval);
    }

    return () => clearInterval(interval);
  }, [taskStarted]);

  useEffect(() => {
    let minutes = Math.floor(time) % 3600;
    setHours(Math.floor(time / 3600));
    setMinutes(Math.floor(minutes / 60));
    setSeconds(time % 60);
  }, [time]);

  return (
    <>
      <div className="task-timer">
        <div className="task-timer-second">
          <span>{"0" + hours}:</span>
          <span>{("0" + minutes).slice(-2)}:</span>
          <span>{("0" + second).slice(-2)} </span>
        </div>
        <div className="task-timer-btn-group">
          {loading && <Spin />}
          {!loading && !taskStarted && (
            <button
              className="task-timer-btn-start"
              onClick={() => {
                HideShowStart();
              }}
            >
              <i className="fa fa-play"></i>
            </button>
          )}
          {!loading && taskStarted && (
            <button
              className="task-timer-btn-stop"
              onClick={() => {
                HideShowStart();
              }}
            >
              <i className="fa fa-stop"></i>
            </button>
          )}
        </div>
      </div>
    </>
  );
};
export default TaskTimer;
