import {
  Button,
  Col,
  DatePicker,
  Form,
  Modal,
  Radio,
  Row,
  TimePicker,
  Table,
  Select,
  Input,
} from "antd";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import AxiosInstance from "../../../service/AxiosInstance";
import PmsDatePicker from "../me/leaveModule/PmsDatePicker";

const AllocatedResource = () => {
  const [rows, setRows] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [memberList, setMemberList] = useState([]);
  const [myTask, setMyTask] = useState([]);
  const [projectID, setProjectID] = useState();
  const [assignFrom, setAssignFrom] = useState("");
  const [assignTo, setAssignTo] = useState("");
  const [fromTime, setFromTime] = useState("");
  const [toTime, setToTime] = useState("");
  const [Status, setStatus] = useState("");
  const [ResourceId, setResourceId] = useState("");
  const [EmployeeName, setEmployeeName] = useState("");
  const [allocatedData, setAllocatedData] = useState([]);
  const [Role, setRole] = useState("");
  const params = useParams();
  const [filteredData, setFilteredData] = useState([]);
  const [form] = Form.useForm();
  let PID = params.id;

  const columns = [
    {
      title: "Project Name",
      dataIndex: "Project_name",
      width: 150,
    },
    {
      title: "Department Name",
      dataIndex: "Department_Name",
      width: 150,
    },
    {
      title: "Requested By",
      dataIndex: "Requested_By",
      width: 150,
    },
    {
      title: "Assigned By",
      dataIndex: "Assigned_by",
      width: 150,
    },
    {
      title: "Assigned To",
      dataIndex: "Assigned_To",
      width: 150,
    },
    {
      title: "Requested Date",
      dataIndex: "Requested_Date",
      width: 150,
    },
    {
      title: "Assigned Date",
      dataIndex: "Assigned_Date",
      width: 150,
    },
  ];

  const handleSearch = (event) => {
    const getSearch = event.target.value;
    if (getSearch.length > 0) {
      const FilterData = allocatedData?.filter((item) => {
        return item?.Project_name.toLowerCase().includes(
          getSearch?.toLowerCase()
        );
      });
      setFilteredData(FilterData);
    } else {
      setFilteredData(allocatedData);
    }
  };

  const getAllocatedResource = async () => {
    try {
      const response = await AxiosInstance.get("auth/resource/all-list");

      const taskdata = [];
      var i = 1;
      response.data.data.map((data, index) => {
        const temp = [...data?.Assign];
        const dates = temp.reverse();
        const toName = data?.Assign?.[0]?.Employee_id?.First_name
          ? data?.Assign?.[0]?.Employee_id?.First_name
          : "-";
        const toLastName = data?.Assign?.[0]?.Employee_id?.Last_name
          ? data?.Assign?.[0]?.Employee_id?.Last_name
          : "";
        const byName = data?.Assign?.[0]?.Assign_by?.First_name
          ? data?.Assign?.[0]?.Assign_by?.First_name
          : "-";
        const byLastName = data?.Assign?.[0]?.Assign_by?.Last_name
          ? data?.Assign?.[0]?.Assign_by?.Last_name
          : "";
        const requestedDate =
          data?.Assign &&
          `${moment(data?.Date?.from).format("DD-MM-YYYY")} - ${moment(
            data?.Date?.to
          ).format("DD-MM-YYYY")} `;
        const assignedDate =
          data?.Assign &&
          `${moment(dates?.[0]?.assign_from).format("DD-MM-YYYY")} - ${moment(
            dates?.[0]?.assign_to
          ).format("DD-MM-YYYY")} `;
        var dataObj = {
          key: index,
          Project_name: data?.Project_id?.Project_name,
          Department_Name: data?.Department_id?.Department_name,
          Assigned_by: `${byName} ${byLastName}`,
          Assigned_To: `${toName} ${toLastName}`,
          Requested_Date: requestedDate,
          Assigned_Date: assignedDate,
          Requested_By: `${data?.Requested_by?.First_name} ${data?.Requested_by?.Last_name}`,
        };
        taskdata.push(dataObj);
        i++;
      });
      setAllocatedData(taskdata);
      setFilteredData(taskdata);
    } catch {
      console.log("errror");
    }
  };

  useEffect(() => {
    getAllocatedResource();
  }, []);

  const showModal = () => {
    setIsModalOpen(true);
  };
  const handleOk = () => {
    setIsModalOpen(false);
  };
  const handleCancel = () => {
    form.resetFields();
    setIsModalOpen(false);
  };

  const [value, setValue] = useState(true);

  const onChange = (e) => {
    setValue(e.target.value);
  };
  const onChange2 = (date) => {
    var m = moment(date);
    m.set({ hour: 0, minute: 0, second: 0, millisecond: 0 });
    setAssignFrom(m);
  };
  const onChange3 = (date) => {
    var m = moment(date);
    m.set({ hour: 0, minute: 0, second: 0, millisecond: 0 });

    setAssignTo(m);
  };
  const onChange4 = (time) => {
    setFromTime(time);
  };
  const onChange5 = (time) => {
    setToTime(time);
  };
  const onChange6 = (status) => {
    setStatus(status);
  };

  // const onDateChange = (time) => {
  //   const selectedDate = moment(time).format("DD-MM-YYYY")
  //   setFilteredDate(selectedDate)
  //   const filteredReport = myTask.filter((val) => {
  //     console.log(moment(val.from_Date).format("DD-MM-YYYY"), selectedDate, "458")
  //     return moment(val.from_Date).format("DD-MM-YYYY") === selectedDate;
  //   })
  //   console.log(filteredReport, 778);
  //   setFilteredData(filteredReport)
  // }
  return (
    <>
      <div className="">
        <div className="page-content">
          <Row className="mx-0 mydashboard-main">
            <Col className="col" xs={24} sm={24} md={24} lg={24} xl={24}>
              <div>
                <div className="left-head-title">Allocated Resource</div>
                <Row className="teamsearch create-project">
                  <Col span={11}>
                    <PmsDatePicker format={"DD/MM/YYYY"} />
                  </Col>
                  <Col span={11}>
                    <Input
                      onChange={handleSearch}
                      placeholder="Search Project name"
                    ></Input>
                  </Col>
                </Row>

                <div className="page-content pm-project-main">
                  <div className="tasklisttbl all-project-list pm-dash-table">
                    <Table
                      entriesOptions={[20, 25, 30]}
                      entries={20}
                      pagesAmount={4}
                      dataSource={filteredData}
                      columns={columns}
                      searchTop
                      // searchBottom={false}
                      className="pm-pro directory-list-table"
                    />
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </div>
      </div>
    </>
  );
};

export default AllocatedResource;
