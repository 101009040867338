import React, { useEffect, useState } from "react";
import AxiosInstance from "../../../../service/AxiosInstance";
import moment from "moment";
import { Spin } from "antd";

const EmpLeave = ({ loading, projectIds, setLoading }) => {
  const [empLeaveData, setEmpLeaveData] = useState([]);
  const [employeedata, setEmployeedata] = useState([]);

  const GetEmpLeave = () => {
    setLoading(true);

    const formData = new URLSearchParams();
    formData.append("id", JSON.stringify(projectIds));
    AxiosInstance.post("auth/project-member-leave", formData).then((res) => {
      setLoading(false);
      let arr1 = res.data.data[0];
      let arr2 = res.data.data[1];
      let uniqueProject = [];
      let output = arr1?.map((e) => {
        let check = arr2
          .map((el) => el.Project_member_id.includes(e.Employee_ID._id) && el)
          .filter((e) => e)
          .map((u) => {
            return {
              ...e.Employee_ID,
              ...u,
              Status: e.Status,
              Leave_Date_to_Date: e.Leave_Date_to_Date,
            };
          });
        uniqueProject.push(...check);
      });
      let unique = [
        ...new Map(
          uniqueProject.map((item) => [item["Leave_Date_to_Date"], item])
        ).values(),
      ];
      setEmployeedata(unique);

      setEmpLeaveData(output);
    });
  };
  useEffect(() => {
    GetEmpLeave();
  }, [projectIds]);
  return (
    <>
      <div className="dash-col-milestone">
        <div className="main-milestone">
          <div className="milstone-flex">
            <div className="milestone-card-head">
              <p>Employee Leave</p>
              <Spin spinning={loading} style={{ marginTop: 100 }}>
                {" "}
              </Spin>
            </div>
          </div>
          <div className="milestone-card-body">
            {employeedata &&
              employeedata.map((item) => {
                return (
                  <div className="mile-body-content pm-emp-leave">
                    {/* <div className="mile-date">
                    <p>
                      25 <span>Task</span>
                    </p>
                  </div> */}
                    <div className="mail-right-detail">
                      <p className="mile-name">
                        {item?.First_name} {item?.Last_name} ({item?.Role})
                      </p>
                      <p className="mile-pro-name">{item?.Project_name}</p>
                      <p className="mile-pro-name">{item?.Status}</p>
                      <div className="pm-leave-days">
                        <p className="">{item?.leavedays}</p>
                        <p className="">
                          <i className="far fa-calendar-alt"></i>{" "}
                          {moment(
                            item?.Leave_Date_to_Date?.split(",")[0]
                          ).format("DD MMM")}{" "}
                          -{" "}
                          {moment(
                            item?.Leave_Date_to_Date?.split(",")[1]
                          ).format("DD MMM")}
                        </p>
                      </div>
                    </div>
                  </div>
                );
              })}
          </div>
        </div>
      </div>
    </>
  );
};

export default EmpLeave;
