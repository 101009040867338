import React, { useEffect, useRef, useState } from "react";
import { Form, Input, Spin } from "antd";
import AxiosInstance from "../../../service/AxiosInstance";
import moment from "moment";
import { useParams } from "react-router";
import { SendOutlined } from "@ant-design/icons";
const imageUrl = "https://bucket-pms.s3.ap-south-1.amazonaws.com/s3-upload/";
function WriteComment({ User, ProjectId, Type }) {
  const [form] = Form.useForm();
  const PID = useParams();
  const [comments, setComments] = useState([]);
  const [count2, setCount2] = useState(false);
  const [HistoryList, setHistoryList] = useState([]);
  // const [hoursLogsList, setHoursLogsList] = useState([]);
  // const [empId, setEmpId] = useState([])
  // const [empData, setEmpData] = useState([])
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const formData = new URLSearchParams();
    formData.append("Project_id", PID?.id);
    AxiosInstance.post("auth/project/comments", formData).then(async (res) => {
      if (res.data.status === 1) {
        setComments(res.data.data);
      }
    });
    AxiosInstance.get(`auth/history/project/${ProjectId}`).then(async (res) => {
      let sortedList = res.data.data.sort(
        (a, b) => moment(a.Time) - moment(b.Time)
      );

      let index = -1;
      let list1 = sortedList.map((item) => {
        return item.History.map((item2) => {
          index++;
          return {
            icon: index,
            // name: item?.Name,
            Project_name: item?.Project_id?.Project_name,
            // Task_id: item.Task_id?._id,
            // Task_name: item?.Task_id?.Task_key,
            Current_Status: item2?.Current_Status,
            Previous_Status: item2?.Privious_Status || item2?.Previous_Status,
            Current_Assignees: item2?.Current_Assignees,
            Previous_Assignees:
              item2?.Privious_Assignees || item2?.Previous_Assignees,
            Comments: item2?.Comments,
            // Task_create: item2?.Task_create,
            Created_by: item2?.Created_by,
            Updated_by: item2?.Updated_by,
            Time: item2?.Time,
            MilestoneName: item?.Milestone_id?.Name,
            User_id: item2.User_id,
          };
        });
      });
      setHistoryList(list1.flat());
      // setEmpId(res.data.data[0].History[0].User_id);
    });
  }, [count2]);

  const onFinish = async (values) => {
    setLoading(true);
    form.resetFields();
    if (comments.trim()) {
      const User = JSON.parse(localStorage.getItem("userdata"));
      const formData = new URLSearchParams();
      formData.append("User_id", User[0]._id);
      formData.append("First_name", User[0].First_name);
      formData.append("Last_name", User[0].Last_name);
      formData.append("Project_id", PID?.id);
      formData.append("Type", Type);
      formData.append("Comment", comments);

      await AxiosInstance.post("auth/project/comment/add", formData)
        .then((res) => {
          let formData1 = new URLSearchParams();
          formData1.append("User_id", User[0]._id);
          formData1.append("Project_id", PID?.id);
          formData1.append(
            "Updated_by",
            User[0].First_name + " " + User[0].Last_name
          );
          formData1.append("Comments", comments);
          AxiosInstance.post("auth/history/add", formData1).then((res) => {
            if (res.data.status == 1) {
            }
          });
          setCount2(!count2);
          form.resetFields();

          // here is for getting latest comment basis
          if (res.data.status === 1) {
            AxiosInstance.get(`auth/history/project/${ProjectId}`).then(
              async (res) => {
                let sortedList = res.data.data.sort(
                  (a, b) => moment(a.Time) - moment(b.Time)
                );

                let index = -1;
                let list1 = sortedList.map((item) => {
                  return item.History.map((item2) => {
                    index++;
                    return {
                      icon: index,
                      // name: item?.Name,
                      Project_name: item?.Project_id?.Project_name,
                      // Task_id: item.Task_id?._id,
                      // Task_name: item?.Task_id?.Task_key,
                      Current_Status: item2?.Current_Status,
                      Previous_Status:
                        item2?.Privious_Status || item2?.Previous_Status,
                      Current_Assignees: item2?.Current_Assignees,
                      Previous_Assignees:
                        item2?.Privious_Assignees || item2?.Previous_Assignees,
                      Comments: item2?.Comments,
                      // Task_create: item2?.Task_create,
                      Created_by: item2?.Created_by,
                      Updated_by: item2?.Updated_by,
                      Time: item2?.Time,
                      MilestoneName: item?.Milestone_id?.Name,
                      User_id: item2.User_id,
                    };
                  });
                });
                setHistoryList(list1.flat());
                // setEmpId(res.data.data[0].History[0].User_id);
              }
            );
          }
        })
        .catch((err) => console.log(err, "err"));
      setLoading(false);
    }
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  const onKeyDownHandler = (e) => {
    if (e.keyCode === 13) {
      this.sendMessage();
    }
  };
  return (
    <>
      <Form
        className="max-w"
        form={form}
        name="comments"
        // labelCol={{ span: 8 }}
        wrapperCol={{ span: 8 }}
        initialValues={{ remember: true }}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        autoComplete="off"
      >
        <div className="add-cmt">
          <img
            src={
              User[0]._id && User[0].Profile_pic
                ? imageUrl + User[0].Profile_pic
                : "https://bootdey.com/img/Content/avatar/avatar1.png"
            }
            alt="profile"
            style={{
              width: "35px",
              height: "35px",
              objectFit: "cover",
              borderRadius: "50px",
              marginRight: "10px",
            }}
          />
          <div style={{ flex: "1" }}>
            <Form.Item
              name="comments"
              className="spinner-main task-cmt-textarea"
              onKeyDown={(e) => {
                if (e.key === "Enter" && e.altKey) {
                  form.submit();
                }
              }}
            >
              <Input.TextArea
                placeholder="Add a comment..."
                accessKey="A"
                onChange={(e) => {
                  setComments(e.target.value, "maulik133");
                }}
              />
              <a>
                <SendOutlined />
              </a>
              <Spin className="main-spin" spinning={loading}>
                {" "}
              </Spin>
            </Form.Item>
            <div className="tip-text">
              <p>
                Pro-tip :
                <span>
                  1. Press <b>Left Alt + Enter </b> to comment for Windows
                </span>
              </p>
              <p style={{ marginLeft: "43px" }}>
                <span>
                  2. Press <b>Option + Enter </b> to comment for Mac
                </span>
              </p>
            </div>
          </div>
        </div>
      </Form>
      <ShowHistory comments={comments} history={HistoryList} />
    </>
  );
}

function Text({ content }) {
  const words = content.split(" ");
  const URL_REGEX =
    /^(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/gm;
  return (
    <p>
      {words.map((word) => {
        return word.match(URL_REGEX) ? (
          <>
            <a href={word} target="_blank">
              {" "}
              {word}{" "}
            </a>{" "}
          </>
        ) : (
          word + " "
        );
      })}
    </p>
  );
}

// function CommentList({ comments, history }) {
//   return (
//     <>
//       {comments &&
//         comments.map((item) => {
//           let time = 0;
//           let current = Date.now();
//           let before = new Date(item.Create_at).getTime();

//           //console.log('x> y',before>current);
//           let difrence = current - before;
//           const days = Math.floor(difrence / (24 * 60 * 60 * 1000));
//           const hours = Math.floor(difrence / (60 * 60 * 1000));
//           const minutes = Math.floor(difrence / (60 * 1000));
//           const sec = Math.floor(difrence / 1000);
//           if (days > 0) {
//             time = `${days} days ago`;
//           } else if (hours > 0) {
//             time = `${hours} hours ago`;
//           } else if (minutes === 0 || minutes === 1) {
//             time = `Just now`;
//           } else {
//             time = `${minutes} minutes ago`;
//           }
//           return (
//             <div className="add-cmt" style={{ marginTop: "15px" }}>
//               <img
//                 src={
//                   item.User_id && item.User_id.Profile_pic
//                     ? item.User_id.Profile_pic
//                     : "https://bootdey.com/img/Content/avatar/avatar1.png"
//                 }
//                 alt="profile"
//                 style={{
//                   width: "35px",
//                   height: "35px",
//                   objectFit: "contain",
//                   borderRadius: "50px",
//                   marginRight: "10px",
//                 }}
//               />
//               <div style={{ flex: "1" }}>
//                 <div className="user-name-detils">
//                   <p>
//                     {" "}
//                     {item.First_name} {item.Last_name}
//                   </p>
//                   <p> {`${time}`}</p>

//                   <p className="label-comment">Comment</p>
//                 </div>
//                 <div className="user-cmt-detail">
//                   <p style={{ margin: "0" }}>
//                     {" "}
//                     {console.log(item.Comment,"1454")}
//                     <><Text content={item.Comment} /></>
//                   </p>
//                 </div>
//               </div>
//             </div>
//           );
//         })}
//     </>
//   );
// }

const ShowHistory = ({ comments, history }) => {
  const Currentcolorstatus = (color) => {
    CurrentstatusBg = "shadow-0";
    PrevstatusBg = "shadow-0";
    if (color == "IN PROGRESS") {
      PrevstatusBg = "danger-info";
    } else if (color == "In client Review") {
      PrevstatusBg = "danger-info";
    } else if (color == "Pending") {
      PrevstatusBg = "shadow-0";
    } else if (color == "Completed") {
      PrevstatusBg = "shadow-0";
    }
  };

  const Prevcolorstatus = (color) => {
    PrevstatusBg = "shadow-0";
    if (color == "IN PROGRESS") {
      PrevstatusBg = "danger-info";
    } else if (color == "In client Review") {
      PrevstatusBg = "danger-info";
    } else if (color == "Pending") {
      PrevstatusBg = "shadow-0";
    } else if (color == "Completed") {
      PrevstatusBg = "shadow-0";
    }
  };
  let PrevstatusBg;
  let CurrentstatusBg;
  return (
    <>
      {history.length
        ? history
            .sort((a, b) => moment(b.Time) - moment(a.Time))
            .map((item, number) => {
              Currentcolorstatus(item.Current_Status);
              Prevcolorstatus(item.Previous_Status);

              return (
                <div className="add-cmt">
                  <img
                    src={
                      item?.User_id && item?.User_id?.Profile_pic
                        ? imageUrl + item?.User_id?.Profile_pic
                        : "https://bootdey.com/img/Content/avatar/avatar1.png"
                    }
                    alt="profile"
                    style={{
                      width: "35px",
                      height: "35px",
                      objectFit: "cover",
                      borderRadius: "50px",
                      marginRight: "10px",
                    }}
                  />

                  <div style={{ flex: "1" }}>
                    <div>
                      <div className="user-name-detils history-name project-direction">
                        <p>
                          <b>
                            {!item.Created_by
                              ? item?.User_id?.First_name +
                                " " +
                                item?.User_id?.Last_name
                              : item?.Created_by
                              ? item.Created_by?.First_name +
                                " " +
                                item.Created_by?.Last_name
                              : item.Updated_by}
                          </b>
                        </p>
                        <p>
                          {" "}
                          {item?.Task_create
                            ? ` Created Task ${item?.Task_name} in ${
                                item?.Project_name
                              } On ${moment(item?.Task_create).format(
                                "DD MMM YYYY"
                              )}  At ${moment(item?.Task_create).format(
                                "hh:mm A"
                              )}`
                            : item?.Current_Status
                            ? `Changed the Status for ${
                                item?.Project_name
                              } in ${item?.MilestoneName} on ${moment(
                                item.Time
                              ).format("DD MMM YYYY")} at ${moment(
                                item.Time
                              ).format("hh:mm A")}`
                            : item?.Current_Assignees
                            ? `Changed the Assignee For ${
                                item?.Project_name
                              } On ${moment(item.Time).format(
                                "DD MMM YYYY"
                              )} At ${moment(item.Time).format("hh:mm A")}`
                            : item.Comments
                            ? `has commented in ${
                                item?.Project_name
                              } on ${moment(item.Time).format(
                                "DD MMM YYYY"
                              )} At ${moment(item.Time).format("hh:mm A")}`
                            : item.MilestoneName
                            ? `Created Milestone on ${moment(item.Time).format(
                                "DD MMM YYYY"
                              )} at ${moment(item.Time).format("hh:mm A")}`
                            : ""}
                        </p>
                      </div>
                      <div className="status-data pm-pro-task-status-data">
                        {item?.Comments ? (
                          item?.Comments
                        ) : item?.Previous_Assignees ? (
                          <>
                            {item?.Previous_Assignees} &nbsp;{" "}
                            <i className="fas fa-long-arrow-alt-right taskarrowmini"></i>{" "}
                            &nbsp; <div>{item?.Current_Assignees}</div>
                          </>
                        ) : item.Current_Status ? (
                          <>
                            <div className={PrevstatusBg}>
                              {item?.Previous_Status
                                ? item?.Previous_Status
                                : item?.Current_Status}
                            </div>{" "}
                            &nbsp;
                            <i className="fas fa-long-arrow-alt-right taskarrowmini"></i>{" "}
                            &nbsp;
                            <div className={CurrentstatusBg}>
                              {item?.Current_Status}
                            </div>
                          </>
                        ) : item.MilestoneName ? (
                          item.MilestoneName
                        ) : (
                          <div className={PrevstatusBg}>
                            {item?.Previous_Status
                              ? item?.Previous_Status
                              : "OPEN"}
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              );
            })
        : comments
            .sort((a, b) => moment(b.Create_at) - moment(a.Create_at))
            .map((item) => {
              return (
                <div className="add-cmt" style={{ marginTop: "15px" }}>
                  <img
                    src={
                      item.User_id && item.User_id.Profile_pic
                        ? imageUrl + item.User_id.Profile_pic
                        : "https://bootdey.com/img/Content/avatar/avatar1.png"
                    }
                    alt="profile"
                    style={{
                      width: "35px",
                      height: "35px",
                      objectFit: "cover",
                      borderRadius: "50px",
                      marginRight: "10px",
                    }}
                  />
                  <div style={{ flex: "1" }}>
                    <div>
                      <div className="user-name-detils history-name project-direction">
                        <p>
                          <b>
                            {!item.Created_by
                              ? item?.User_id?.First_name +
                                " " +
                                item?.User_id?.Last_name
                              : item?.Created_by
                              ? item.Created_by?.First_name +
                                " " +
                                item.Created_by?.Last_name
                              : item.Updated_by}
                          </b>
                        </p>
                        <p>
                          {" "}
                          {item.Comment
                            ? `has commented in ${
                                item?.Project_id?.Project_name
                              } on ${moment(item.Create_at).format(
                                "DD MMM YYYY"
                              )} At ${moment(item.Create_at).format("hh:mm A")}`
                            : ""}
                        </p>
                      </div>
                      <div className="status-data pm-pro-task-status-data">
                        {item?.Comment ? item?.Comment : ""}
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
    </>
  );
};

export default WriteComment;
