import React, { useEffect, useState } from "react";

import { MDBCard, MDBIcon} from "mdbreact";
import { MDBCol } from "mdb-react-ui-kit";
import { Link } from "react-router-dom";

import {
  Box,
  Menu,
  MenuItem,
  Typography,
  Button,
  TablePagination,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  TableCell,
  Paper,
  InputAdornment,
  TextField,
} from "@mui/material";

import BlockIcon from "@mui/icons-material/Block";
import PersonIcon from "@mui/icons-material/Person";
import RefreshIcon from "@mui/icons-material/Refresh";
import StarOutlineIcon from "@mui/icons-material/StarOutline";
import BugReportIcon from "@mui/icons-material/BugReport";
import PmsRangePicker from "../../me/leaveModule/RangePicker";
import SearchIcon from "@mui/icons-material/Search";

import { Png } from "../../../../assets/png";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";

import AxiosInstance from "../../../../service/AxiosInstance";
import LinearProgress from "@mui/material/LinearProgress";

import { CgCalendarDates } from "react-icons/cg";

import "antd/dist/antd.css";
import { toast } from "react-toastify";

import "react-toastify/dist/ReactToastify.css";
import { Select } from "antd";
const { Option } = Select;

const MilestonesList = () => {
  const [milestoneList, setMilestoneList] = useState([]);
  console.log("milestoneList", milestoneList);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [status, setStatus] = useState([]);
  const [searchQuery, setSearchQuery] = useState([]);

  const [menuAnchor, setMenuAnchor] = useState(null);

  const [isHovered, setIsHovered] = useState(false);

  const [groupBy, setGroupBy] = useState("None");

  const handleMenuOpen = (event) => {
    setMenuAnchor(event.currentTarget);
  };

  const handleMenuClose = () => {
    setMenuAnchor(null);
  };

  const handleGroupBy = (groupBy) => {
    console.log(groupBy);
    setGroupBy(groupBy);
    handleMenuClose();
  };

  const getMilestoneList = async () => {
    try {
      const res = await AxiosInstance.get("milestone/newReport");
      setMilestoneList(res?.data?.data);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    getMilestoneList();
  }, []);

  useEffect(() => {
    console.log(startDate, endDate, status);
  }, [startDate, endDate, status]);

  const onChange = (value) => {
    if (!value || !value[0] || !value[1]) {
      setStartDate(null);
      setEndDate(null);
    } else {
      setStartDate(value[0]);
      setEndDate(value[1]);
    }
  };

  const filteredMilestoneList = milestoneList.filter((item) => {
    const itemStartDate = new Date(item?.milestones?.[0]?.milestone?.StartDate);
    const itemEndDate = new Date(item?.milestones?.[0]?.milestone?.DueDate);

    const startDateMatch =
      !startDate || areDatesEqual(itemStartDate, startDate);
    const endDateMatch = !endDate || areDatesEqual(itemEndDate, endDate);
    const statusMatch =
      status.length === 0 ||
      status.includes(item?.milestones?.[0]?.milestone?.Status);

    const searchQueryMatch = !searchQuery || matchesSearchQuery(item);

    return startDateMatch && endDateMatch && statusMatch && searchQueryMatch;
  });

  function areDatesEqual(date1, date2) {
    return (
      date1.toISOString().slice(0, 10) === date2.toISOString().slice(0, 10)
    );
  }

  function matchesSearchQuery(item) {
    const searchRegex = new RegExp(searchQuery, "i");
    const projectName = item?.project?.Project_name || "";
    const milestoneName = item?.milestones?.[0]?.milestone?.Name || "";
    const pmNAME = item?.project?.Project_manager || "";
    const chName = item?.project?.Technical_project_manager || "";

    return (
      searchRegex.test(projectName) ||
      searchRegex.test(milestoneName) ||
      searchRegex.test(pmNAME) ||
      searchRegex.test(chName)
    );
  }

  const handleSearch = (e) => {
    setSearchQuery(e?.target?.value);
  };



  function formatDate(dateString, isDueDate) {
    if (dateString) {
      const date = new Date(dateString);
      const currentDate = new Date();
      const diffInDays = Math.floor(
        (date - currentDate) / (1000 * 60 * 60 * 24)
      );
  
      if (isDueDate) {
        if (diffInDays < 0) {
          return (
            <span style={{ color: "red" }}>
              {`${date.getDate().toString().padStart(2, "0")}-${(
                date.getMonth() + 1
              )
                .toString()
                .padStart(2, "0")}-${date.getFullYear()} (${Math.abs(
                diffInDays
              )} days ago)`}
            </span>
          );
        } else {
          return (
            <span style={{ color: "green" }}>
              {`${date.getDate().toString().padStart(2, "0")}-${(
                date.getMonth() + 1
              )
                .toString()
                .padStart(
                  2,
                  "0"
                )}-${date.getFullYear()} (${diffInDays} days to go)`}
            </span>
          );
        }
      } else {
        const day = date.getDate().toString().padStart(2, "0");
        const month = (date.getMonth() + 1).toString().padStart(2, "0");
        const year = date.getFullYear();
        return `${day}-${month}-${year}`;
      }
    } else {
      return "-";
    }
  }
  

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleRefresh = () => {
    window.location.reload(true);

    toast.success("Page Refreshed!", {
      position: "top-center",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      style: {
        background: "green",
        color: "white",
      },
    });
  };

  return (
    <>
      <div className="demo">
        <MDBCard className="page-content">
          <div className="create-project">
            <MDBCol size="md" className="col-example titel-set">
              <>
                <div className="leave-set-btn2">
                  <h3 className="tablHeader">Milestone Report</h3>
                </div>
              </>
            </MDBCol>
          </div>

          <hr />
          <Box className="bottom_main">
            <Box className="bottom_header">
              <Box
                className={`bottom_left ${isHovered ? "show-icons" : ""}`}
                onMouseEnter={() => setIsHovered(true)}
                onMouseLeave={() => setIsHovered(false)}
              >
                <Box className="main-group-icon">
                  <Box className="group_by">
                    <Typography onClick={handleMenuOpen}>
                      Group by {groupBy}
                      <ArrowDropDownIcon />
                    </Typography>
                    <Menu
                      anchorEl={menuAnchor}
                      open={Boolean(menuAnchor)}
                      onClose={handleMenuClose}
                    >
                      <MenuItem onClick={() => handleGroupBy("Project")}>
                        <MDBIcon icon="briefcase" className="project_icon" />
                        {/* Project */}
                        <Link to="/milestonesList2">Project</Link>
                      </MenuItem>
                      <MenuItem onClick={() => handleGroupBy("None")}>
                        <BlockIcon className="none_icon"> </BlockIcon>
                        None
                      </MenuItem>
                    </Menu>
                  </Box>

                  <Box className="main-image-icon">
                    {isHovered && (
                      <React.Fragment>
                        <Box className="star_icon_box">
                          <StarOutlineIcon />
                        </Box>
                        <Box className="ref_box_icon">
                          <Box className="ref_box_icon" onClick={handleRefresh}>
                            <RefreshIcon />
                          </Box>
                        </Box>
                      </React.Fragment>
                    )}
                  </Box>
                </Box>

                <Box className="right-side-my">

                  <Box className="milestone_search_box">
                    <TextField
                    className="cus-milestone_search_box"
                      type="text"
                      placeholder="Search"
                      onChange={(e) => handleSearch(e)}
                      value={searchQuery}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <SearchIcon />
                          </InputAdornment>
                        ),
                      }}
                    />
                  </Box>
                  <div className="row cus-row">
                    <MDBCol size="md-6 cus-main-md">
                      <label className="start-list">Due Date</label>
                      <PmsRangePicker
                        className="search-rangepicker"
                        format="DD-MM-YYYY"
                        onChange={onChange}
                      />
                    </MDBCol>
                    <MDBCol size="md-6 cus-main-md">
                      <label className="start-list">Status</label>
                      <Select
                        placeholder="Status"
                        mode="multiple"
                        
                        className="select-dropdown cus-select-dropdown leave-4"
                        onChange={(e) => {
                          console.log(e);
                          setStatus(e);
                        }}
                      >
                        <Option value="Pending">Pending</Option>
                        <Option value="In Progress">In Progress</Option>
                        <Option value="In Client Review">
                          In Client Review
                        </Option>
                        <Option value="On Hold">On Hold</Option>
                        <Option value="Completed">Completed</Option>
                      </Select>
                    </MDBCol>
                  </div>
                </Box>
              </Box>
              <Box className="main">
                <Box className="right">
                <Paper sx={{ width: '100%', overflow: 'hidden' }}>
                  <TableContainer className="cus-container" component={Paper} sx={{ maxHeight: 300 }}>
                    <Table className="cus-table" stickyHeader aria-label="sticky table">
                      <TableHead>
                        <TableRow>
                          <TableCell>Milestone</TableCell>
                          <TableCell className="dp-text-image">
                            <MDBIcon icon="briefcase" className="status_icon" />
                            Project
                          </TableCell>
                          <TableCell>
                            <img
                              className="status_icon"
                              src={Png.status}
                              alt="Status"
                            />
                            Status
                          </TableCell>
                          <TableCell>
                            <PersonIcon className="person-icon"></PersonIcon>
                            PM
                          </TableCell>
                          <TableCell>
                            <PersonIcon className="person-icon"></PersonIcon>
                            CH
                          </TableCell>
                          <TableCell>
                            <CgCalendarDates className="person-icon"></CgCalendarDates>
                            Start Date
                          </TableCell>
                          <TableCell>
                            <CgCalendarDates className="person-icon"></CgCalendarDates>
                            End Date
                          </TableCell>
                          <TableCell>
                            <img className="filter_icon" src={Png.task}></img>
                            Tasks
                          </TableCell>

                          <TableCell>
                            {" "}
                            <BugReportIcon className="filter_icon" />
                            Issues
                          </TableCell>
                          <TableCell>
                            <img className="filter_icon" src={Png.task}></img>
                            Sprints
                          </TableCell>
                        </TableRow>
                      </TableHead>

                      <TableBody className="table_body">
                        {filteredMilestoneList
                          .slice(
                            page * rowsPerPage,
                            page * rowsPerPage + rowsPerPage
                          )
                          .map((item, index) => (
                            <TableRow
                              key={item?.project?._id}
                              sx={{
                                "&:last-child td, &:last-child th": {
                                  border: 0,
                                },
                              }}
                            >
                              <TableCell>
                                {item?.milestones?.[0]?.milestone?.Name}
                              </TableCell>
                              <TableCell>
                                {item?.project?.Project_name}
                              </TableCell>

                              <TableCell>
                                {item?.milestones?.[0]?.milestone?.Status ===
                                  "In Progress" ? (
                                  <Box
                                    style={{
                                      width: "100%",
                                      textAlign: "center",
                                      backgroundColor: "#385CF1",
                                      color: "white",
                                      display: "inline-block",
                                      padding: "14.5px 14.5px",
                                    }}
                                  >
                                    {item?.milestones?.[0]?.milestone?.Status}
                                  </Box>
                                ) : item?.milestones?.[0]?.milestone?.Status ===
                                  "Completed" ? (
                                  <Box
                                    style={{
                                      width: "100%",
                                      textAlign: "center",
                                      backgroundColor: "#375E14",
                                      color: "white",
                                      display: "inline-block",
                                      padding: "15px 15px",
                                    }}
                                  >
                                    {item?.milestones?.[0]?.milestone?.Status}
                                  </Box>
                                ) : item?.milestones?.[0]?.milestone?.Status ===
                                  "Active" ? (
                                  <Box
                                    style={{
                                      width: "100%",
                                      textAlign: "center",
                                      backgroundColor: "#74CB80",
                                      color: "white",
                                      display: "inline-block",
                                      padding: "17px 17px",
                                    }}
                                  >
                                    {item?.milestones?.[0]?.milestone?.Status}
                                  </Box>
                                ) : item?.milestones?.[0]?.milestone?.Status ===
                                  "DeActive" ? (
                                  <Box
                                    style={{
                                      width: "100%",
                                      textAlign: "center",
                                      backgroundColor: "grey",
                                      color: "white",
                                      display: "inline-block",
                                      padding: "23px 23px",
                                    }}
                                  >
                                    {item?.milestones?.[0]?.milestone?.Status}
                                  </Box>
                                ) : item?.milestones?.[0]?.milestone?.Status ===
                                  "Client Not Responding" ? (
                                  <Box
                                    style={{
                                      width: "100%",
                                      textAlign: "center",
                                      backgroundColor: "#C5A070",
                                      color: "white",
                                      display: "inline-block",
                                      padding: "16px",
                                    }}
                                  >
                                    {item?.milestones?.[0]?.milestone?.Status}
                                  </Box>
                                ) : item?.milestones?.[0]?.milestone?.Status ===
                                  "On Hold" ? (
                                  <Box
                                    style={{
                                      width: "100%",
                                      textAlign: "center",
                                      backgroundColor: "#FFA33C",
                                      color: "white",
                                      display: "inline-block",
                                      padding: "23px 23px",
                                    }}
                                  >
                                    {item?.milestones?.[0]?.milestone?.Status}
                                  </Box>
                                ) : item?.milestones?.[0]?.milestone?.Status ===
                                  "In Client Review" ? (
                                  <Box
                                    style={{
                                      width: "100%",
                                      textAlign: "center",
                                      backgroundColor: "#9EBB5C",
                                      color: "white",
                                      display: "inline-block",
                                      padding: "16px",
                                    }}
                                  >
                                    {item?.milestones?.[0]?.milestone?.Status}
                                  </Box>
                                ) : item?.milestones?.[0]?.milestone?.Status ===
                                  "Pending" ? (
                                  <Box
                                    style={{
                                      width: "100%",
                                      textAlign: "center",
                                      backgroundColor: "#08AEEA",
                                      color: "white",
                                      display: "inline-block",
                                      padding: "18px 18px",
                                      margin: "0%",
                                    }}
                                  >
                                    {item?.milestones?.[0]?.milestone?.Status}
                                  </Box>
                                ) : (
                                  ""
                                )}
                              </TableCell>

                              <TableCell>
                                {item?.project?.Project_manager?.replace(
                                  /\([^)]*\)/,
                                  ""
                                )}
                              </TableCell>
                              <TableCell>
                                {item?.project?.Technical_project_manager?.replace(
                                  /\([^)]*\)/,
                                  ""
                                )}
                              </TableCell>
                              <TableCell>
                                {formatDate(
                                  item?.milestones?.[0]?.milestone?.StartDate,
                                  false
                                )}
                              </TableCell>
                              <TableCell>
                                {formatDate(
                                  item?.milestones?.[0]?.milestone?.DueDate,
                                  true
                                )}
                              </TableCell>
                              <TableCell>
                                <div className="project_table_progress_bar">
                                  <span>
                                    {
                                      item?.milestones?.[0]?.milestone
                                        ?.totalTaskCompleted
                                    }
                                  </span>
                                  <Box className="my-progress">
                                    {item?.milestones?.[0]?.milestone
                                      ?.totalTask > 0 ? (
                                      <LinearProgress
                                        className="progress_bar"
                                        value={
                                          (item?.milestones?.[0]?.milestone
                                            ?.totalTaskCompleted /
                                            item?.milestones?.[0]?.milestone
                                              ?.totalTask) *
                                          100
                                        }
                                        color="success"
                                        variant="determinate"
                                      />
                                    ) : (
                                      <LinearProgress
                                        className="progress_bar"
                                        value={0}
                                        color="success"
                                        variant="determinate"
                                      />
                                    )}
                                    <span className="progress-value">
                                      {item?.milestones?.[0]?.milestone
                                        ?.totalTask > 0
                                        ? `${Math.round(
                                          (item?.milestones?.[0]?.milestone
                                            ?.totalTaskCompleted /
                                            item?.milestones?.[0]?.milestone
                                              ?.totalTask) *
                                          100
                                        )}%`
                                        : "0%"}
                                    </span>
                                  </Box>
                                  <span>
                                    {
                                      item?.milestones?.[0]?.milestone
                                        ?.totalTask
                                    }
                                  </span>
                                </div>
                              </TableCell>

                              <TableCell>
                                <div className="project_table_progress_bar">
                                  <span>
                                    {
                                      item?.milestones?.[0]?.milestone
                                        ?.totalBugCompleted
                                    }
                                  </span>
                                  <Box className="my-progress">
                                    {item?.milestones?.[0]?.milestone
                                      ?.totalBug > 0 ? (
                                      <LinearProgress
                                        className="progress_bar"
                                        value={
                                          (item?.milestones?.[0]?.milestone
                                            ?.totalBugCompleted /
                                            item?.milestones?.[0]?.milestone
                                              ?.totalBug) *
                                          100
                                        }
                                        color="success"
                                        variant="determinate"
                                      />
                                    ) : (
                                      <LinearProgress
                                        className="progress_bar"
                                        value={0}
                                        color="success"
                                        variant="determinate"
                                      />
                                    )}
                                    <span className="progress-value">
                                      {item?.milestones?.[0]?.milestone
                                        ?.totalBug > 0
                                        ? `${Math.round(
                                          (item?.milestones?.[0]?.milestone
                                            ?.totalBugCompleted /
                                            item?.milestones?.[0]?.milestone
                                              ?.totalBug) *
                                          100
                                        )}%`
                                        : "0%"}
                                    </span>
                                  </Box>
                                  <span>
                                    {item?.milestones?.[0]?.milestone?.totalBug}
                                  </span>
                                </div>
                              </TableCell>

                              <TableCell>
                                <div className="project_table_progress_bar">
                                  <span>
                                    {
                                      item?.milestones?.[0]?.milestone
                                        ?.totalSprintCompleted
                                    }
                                  </span>
                                  <Box className="my-progress">
                                    {item?.milestones?.[0]?.milestone
                                      ?.totalSprint > 0 ? (
                                      <LinearProgress
                                        className="progress_bar"
                                        value={
                                          (item?.milestones?.[0]?.milestone
                                            ?.totalSprintCompleted /
                                            item?.milestones?.[0]?.milestone
                                              ?.totalSprint) *
                                          100
                                        }
                                        color="success"
                                        variant="determinate"
                                      />
                                    ) : (
                                      <LinearProgress
                                        className="progress_bar"
                                        value={0}
                                        color="success"
                                        variant="determinate"
                                      />
                                    )}
                                    <span className="progress-value">
                                      {item?.milestones?.[0]?.milestone
                                        ?.totalSprint > 0
                                        ? `${Math.round(
                                          (item?.milestones?.[0]?.milestone
                                            ?.totalSprintCompleted /
                                            item?.milestones?.[0]?.milestone
                                              ?.totalSprint) *
                                          100
                                        )}%`
                                        : "0%"}
                                    </span>
                                  </Box>
                                  <span>
                                    {
                                      item?.milestones?.[0]?.milestone
                                        ?.totalSprint
                                    }
                                  </span>
                                </div>
                              </TableCell>
                            </TableRow>
                          ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                  </Paper>
                </Box>
                <TablePagination
                className="pages"
                  rowsPerPageOptions={[5, 10, 15]}
                  component="div"
                  count={milestoneList?.length}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                />
              </Box>
            </Box>
          </Box>
        </MDBCard>
      </div>
    </>
  );
};

export default MilestonesList;
