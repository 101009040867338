import React from 'react';
import { DatePicker } from 'antd';
import moment from 'moment';

const { RangePicker } = DatePicker;

const DateDurationDatePicker = ({ dateRange, setAssignedDateRange, SetDueDateRange, setCreatedDate }) => {
  
  const onChange = (value) => {
    if (value && value.length === 2) {
      const date1 = value[0] ? Math.floor(value[0].valueOf() / 86400000) : '';
      const date2 = value[1] ? Math.floor(value[1].valueOf() / 86400000) : '';

      if (setAssignedDateRange) {
        setAssignedDateRange([date1, date2]);
      }
      if (SetDueDateRange) {
        SetDueDateRange([date1, date2]);
      }
      if (setCreatedDate) {
        setCreatedDate([date1, date2]);
      }
    }
  };

  return (
    <RangePicker
      className='search-rangepicker'
      format="DD-MM-YYYY"
      onChange={onChange} 
      value={[
        dateRange[0] ? moment(dateRange[0] * 86400000) : null,
        dateRange[1] ? moment(dateRange[1] * 86400000) : null
      ]}
    />
  );
};

export default DateDurationDatePicker;
