import React, { useState } from "react";
import { Col, Row, Tabs } from "antd";
import DashboardBar from "../../../components/common/DashboardBar";
import AllocatedResource from "./AllocatedResource";
import RequestedResource from "./RequestedResource";
import VacantEmployee from "./VacantEmployee";
//main component

const ResourceManager = () => {
  return (
    <>
      <div className="event-main">
        <DashboardBar />

        <div className="rightside mydash-right resource-manager-page">
          <Tabs className="main-tab" defaultActiveKey="1">
            <Tabs.TabPane
              className="sub-tab"
              tab={
                <p style={{ margin: 0 }}>
                  <i className="far fa-file-alt" style={{ marginRight: 5 }}></i>{" "}
                  Requested Resource
                </p>
              }
              key="1"
              placeholder="abcd"
            >
              <RequestedResource />
            </Tabs.TabPane>
            <Tabs.TabPane
              className="sub-tab"
              tab={
                <p style={{ margin: 0 }}>
                  <i className="far fa-file-alt" style={{ marginRight: 5 }}></i>
                  Allocated Resource
                </p>
              }
              key="2"
            >
              <AllocatedResource />
            </Tabs.TabPane>

            <Tabs.TabPane
              className="sub-tab"
              tab={
                <p style={{ margin: 0 }}>
                  <i className="far fa-file-alt" style={{ marginRight: 5 }}></i>{" "}
                  Vacant Employee
                </p>
              }
              key="3"
            >
              <VacantEmployee />
            </Tabs.TabPane>
          </Tabs>
        </div>
      </div>
    </>
  );
};

export default ResourceManager;
