import { Select, Spin, Table } from "antd";
import React, { useState, useEffect } from "react";
import AxiosInstance from "../../../../service/AxiosInstance";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import { Status } from "../../Options";
import {
  taskPriorityBadge,
  taskStatusBadge,
} from "../../../../components/common/Coomon";
import { actionMyTaskAction } from "../../../../redux/actions/TaskActions";
const TaskTable = ({ employeeDetails }) => {
  const MyTask = useSelector((state) => state.Task?.MyTask);
  const [myTeamTask, setmyTeamTask] = useState(MyTask || []);
  const [myTask, setMyTask] = useState([]);
  const [statusSort, setStatusSort] = useState("ALL");
  const [loading, setLoading] = useState(false);

  const dispatch = useDispatch();
  const columns = [
    {
      title: "#",
      dataIndex: "taskKey",
    },
    {
      title: "Summary",
      dataIndex: "shortSummary",
    },
    {
      title: "P",
      dataIndex: "priority",
    },
    {
      title: "Status",
      dataIndex: "status",
    },
    {
      title: "AssignedDate",
      dataIndex: "assignedDate",
    },
    {
      title: "Time",
      dataIndex: "assignedTime",
    },
    {
      title: "Due Date",
      dataIndex: "dueDate",
    },
  ];

  useEffect(() => {
    if (employeeDetails?.id) {
      getByUserIdTasks();
    } else {
      getDefaultUserTasks();
    }
  }, [employeeDetails]);
  useEffect(() => {
    getTaskList();
  }, [statusSort]);

  const getDefaultUserTasks = () => {
    setLoading(true);
    AxiosInstance.get("auth/employeetasks/list/Default").then(async (res) => {
      setLoading(false);
      dispatch(actionMyTaskAction(res.data.data));
      setmyTeamTask(res.data.data);
    });
  };

  const getByUserIdTasks = () => {
    const urlId = new URLSearchParams();
    urlId.append("id", employeeDetails?.id);
    setLoading(true);
    AxiosInstance.post("employeetasks/list", urlId).then(async (res) => {
      setLoading(false);
      const reverseData = [...res.data.data].reverse();
      setmyTeamTask(reverseData);
    });
  };

  const getTaskList = () => {
    let data = [];

    myTeamTask?.map((row) => {
      let detail = {
        taskKey: (
          <Link style={{ fontWeight: "500" }} to={`/task/${row?._id}`}>
            {row?.Task_key}
          </Link>
        ),
        shortSummary: `${row?.Short_summary.substring(0, 50)}`,
        priority: taskPriorityBadge(row?.Priority),
        status: taskStatusBadge(row?.Status),
        assignedDate:
          row?.Assigned_date != "-"
            ? `${moment(row?.Assigned_date).format("DD/MM/YYYY")}`
            : "-",
        assignedTime: `${
          row?.Task_duration?.trim() !== "undefined" && row?.Task_duration
            ? row?.Task_duration > 1
              ? `${row?.Task_duration} Hr`
              : `${row?.Task_duration} Hr`
            : "-"
        }`,
        dueDate: `${
          row?.Due_date &&
          row?.Due_date !== "-" &&
          row?.Due_date !== "Invalid date"
            ? moment(row?.Due_date).format("MMM DD")
            : "-"
        }`,
      };
      if (statusSort != "ALL") {
        if (statusSort === row?.Status) {
          data.push(detail);
        }
      } else {
        data.push(detail);
      }
    });
    setMyTask(data);
  };
  useEffect(() => {
    getTaskList();
  }, [myTeamTask]);
  useEffect(() => {
    getTaskList();
  }, []);
  return (
    <>
      {" "}
      <div className="my-task-dropdown">
        <Select
          // style={{ width: '100%' }}
          className="select-dropdown box-width"
          value={statusSort}
          options={Status}
          onChange={(e) => setStatusSort(e)}
        />{" "}
        <Spin spinning={loading} />
      </div>
      <Table className="team-table" columns={columns} dataSource={myTask} />
    </>
  );
};

export default TaskTable;
