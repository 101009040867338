import * as React from "react";
import {
  Box,
  Button,
  SwipeableDrawer,
  TextField,
  Typography,
} from "@mui/material";
import { Png } from "../../assets/png";

const FilterModal = ({ open, onClose, data, setSearch, role }) => {
  const [project, setProject] = React.useState("");
  const [filteredData, setFilteredData] = React.useState([]);

  const handleInputChange = (e) => {
    const searchText = e?.target?.value;
    setProject(searchText);
    if (!searchText) {
      setSearch("");
    }
  };

  React.useEffect(() => {
    const filteredData = data?.filter((item) => {
      const searchLowerCase = project?.toLowerCase();
      if (role == "projectList") {
        return item?.action?.props?.Project_name?.toLowerCase()?.includes(
          searchLowerCase
        );
      } else if (role == "taskList") {
        return item?.name?.toLowerCase()?.includes(searchLowerCase);
      }
      return false;
    });
    setFilteredData(filteredData);
  }, [project, data, role]);

  const handleFind = () => {
    setSearch(project.toLowerCase());
    onClose();
  };

  const handleClose = () => {
    onClose();
  };

  const hanldeCancel = () => {
    setProject("");
    setSearch("");
  };
  return (
    <>
      <SwipeableDrawer anchor={"right"} open={open} onClose={handleClose}>
        <Box className="modal-main-box">
          <Box className="modal-box">
            <Box className="modal-heading-box">
              <Typography className="filter-text">Filter</Typography>
              <Typography className="filter-reset" onClick={hanldeCancel}>
                Reset
              </Typography>
            </Box>

            <Box className="modal-middle-box">
              <Box className="top-image-search-mian">
                <img className="filter-search-icon" src={Png.search}></img>
                <TextField
                  className="input-search"
                  variant="standard"
                  placeholder="Filter Search"
                  onChange={handleInputChange}
                  value={project}
                />
                <img
                  className="filter-cross-icon"
                  src={Png.crossIcon}
                  onClick={hanldeCancel}
                ></img>
              </Box>

              <Box className="list-data">
                {filteredData?.map((list, index) => (
                  <Box className="filter-list-text-box" key={index}>
                    <Typography
                      className="select-text"
                      onClick={() => {
                        if (role === "projectList") {
                          setProject(
                            list?.action?.props?.Project_name
                          );
                        } else if (role == "taskList") {
                          setProject(list?.name);
                        }
                      }}
                    >
                      {role == "projectList"
                        ? list?.action?.props?.Project_name
                        : list?.name}
                    </Typography>
                  </Box>
                ))}
              </Box>
            </Box>

            <Box className="modal-footer-box">
              <Button className="find-btn" onClick={handleFind}>
                Find
              </Button>
              <Button className="cancel-btn" onClick={handleClose}>
                Cancel
              </Button>
            </Box>
          </Box>
        </Box>
      </SwipeableDrawer>
    </>
  );
};

export default FilterModal;
