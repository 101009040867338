import React from "react";
import { Select } from "antd";

const { Option } = Select;

const SortingSelect = ({
  sortingValue,
  setSortingValue,
  optionsArray,
  addStyle,
  placeholder,
}) => {
  const handleOnChange = (value) => {
    // console.log(`selected ${value}`);
    setSortingValue(value);
  };
  return (
    <>
      <Select
        placeholder="Select"
        onChange={handleOnChange}
        value={sortingValue}
        /*defaultValue="lucy"*/ style={addStyle}
        allowClear
      >
        {optionsArray?.map((option, index) => (
          <Option key={index} value={option.value}>
            {option.label}
          </Option>
        ))}
      </Select>
    </>
  );
};

export default SortingSelect;
