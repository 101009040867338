import React, { useEffect, useState } from "react";
import { Table } from "antd";
import { Select } from "antd";
import AxiosInstance from "../../../service/AxiosInstance";
import moment from "moment";
import { Link } from "react-router-dom";
import { MDBBadge, MDBIcon } from "mdb-react-ui-kit";

const column = [
  {
    title: "No",
    dataIndex: "no",
    key: "no",
  },
  {
    title: "Employee Name",
    dataIndex: "name",
    key: "name",
  },
];

const CurrentWorkingTasks = (props) => {
  const [dataTable, setDataTable] = useState([]);
  const [data, setData] = useState([]);
  const [departmentName, setDepartmentName] = useState("");
  const [columns, setColumns] = useState(column);
  const [list, setList] = useState([]);
  const [listHtml, setListHtml] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [allemployee, setAllEmployee] = useState([]);
  const [current, setCurrent] = useState(7);
  const [addEvent, setAddEvent] = useState("subtract");
  const [day, setDay] = useState(new Date().getDate());
  const [month, setMonth] = useState(new Date().getMonth() + 1);
  const [year, setYear] = useState(new Date().getFullYear());

  const [fromDate, setFromDate] = useState(
    moment(new Date()).format("YYYY-MM-DD")
  );
  const [toDate, setToDate] = useState(
    moment().add(10, "day").format("YYYY-MM-DD")
  );
  const [loading, setLoading] = useState("No Data");
  const onSearch = (value) => {
    console.log("search:", value);
  };

  const getDefaultUserTasks = () => {
    setLoading("Fetching....");
    const urlencoded = new URLSearchParams();

    // let prevDate = moment(currentDate).subtract(10, "day").date();
    urlencoded.append("from", fromDate);
    urlencoded.append("to", toDate);
    AxiosInstance.post("auth/working-report", urlencoded).then(async (res) => {
      console.log(res.data.data, "7869");
      setData(res.data.data);
      setLoading("Received");
    });
    AxiosInstance.get("auth/employees", {}).then(async (res) => {
      setAllEmployee(
        res.data.data.map((data) => {
          return {
            name: `${data.First_name} ${data.Last_name}`,
            role: data.Role,
          };
        })
      );
    });
  };

  const gettaskList = () => {
    let TaskDetails = [];
    (departmentName ? filteredData : data).map((item, i) => {
      let name = `${item?.Employee_id?.First_name} ${item?.Employee_id?.Last_name}`;
      let index = TaskDetails.findIndex((x) => x.name === name);
      console.log(TaskDetails, 98, name);
      console.log(
        moment(item.Check_In).format("YYYY-MM-DD"),
        "89090998ur",
        name
      );
      if (index !== -1) {
        let nameDate = TaskDetails.findIndex(
          (x) =>
            x.name === name &&
            Object.keys(x).includes(moment(item.Check_In).format("YYYY-MM-DD"))
        );
        if (nameDate !== -1) {
          console.log(TaskDetails, 110);
          TaskDetails[index][moment(item.Check_In).format("YYYY-MM-DD")] = (
            <>
              {TaskDetails[index][moment(item.Check_In).format("YYYY-MM-DD")]},
              <Link
                to={`/task/${item.Task_id._id}`}
                className="task-tag-${item.Status}"
                target={"_blank"}
              >
                {item.Task_id?.Task_key}
              </Link>
              {getTaskName(item)}
            </>
          );
        } else {
          TaskDetails[index][moment(item.Check_In).format("YYYY-MM-DD")] = (
            <Link to={`/${item.Task_id._id}`}>{item?.Task_key}</Link>
          );
          getTaskName(item);
        }
      } else {
        let detail = {
          name,
          no: i + 1,
          [`${moment(item.Check_In).format("YYYY-MM-DD")}`]: getTaskName(item),
          role: item.Employee_id.Role,
        };

        TaskDetails.push(detail);
      }
    });
    let arr = [...TaskDetails];
    function add(arr, data) {
      const { length } = arr;
      const no = length + 1;
      const found = arr.some((el) => el.name === data.name);
      const dept = arr.some((el) => el.role === data.role);
      if (!found && dept) arr.push({ no, name: data.name });
      return arr;
    }

    allemployee.map((data) => {
      add(arr, data);
    });

    setDataTable(arr);
    setData(data);
  };

  console.log(filteredData, "filteredData");
  useEffect(() => {
    getDefaultUserTasks();
    let arr = [];

    let tempArray = [];

    [...Array(current)].map((_, i) => {
      let date = moment(`${year}-${month}-${day}`)
        [`${addEvent}`](i, "days")
        .format("YYYY-MM-DD");

      if (i === 0) {
        setFromDate(date);
      } else if (i === [...Array(current)].length - 1) {
        setToDate(date);
      }
      arr.push({
        title: date,
        dataIndex: date,
        key: date,
      });
    });

    setColumns([...column, ...arr]);
  }, [day, month, year, fromDate, toDate]);

  useEffect(() => {
    gettaskList();
  }, [fromDate, toDate, filteredData]);

  const getTaskName = (item) => {
    console.log(item, "78890");
    let taskIcon = <MDBIcon fas icon="check" style={{ color: "#1890ff" }} />;
    if (item.Issue_type == "Bug") {
      taskIcon = <MDBIcon fas icon="spider" style={{ color: "red" }} />;
    } else if (item.Issue_type == "Story") {
      taskIcon = <MDBIcon fas icon="bookmark" style={{ color: "green" }} />;
    } else {
      taskIcon = <MDBIcon fas icon="check" style={{ color: "#1890ff" }} />;
    }
    let statusBg = (
      <MDBBadge style={{ borderRadius: "4px", color: "#404040" }} color="light">
        {taskIcon}
        {item?.Task_key}
      </MDBBadge>
    );

    if (item?.Status == "IN PROGRESS") {
      statusBg = (
        <MDBBadge className="status-blue" style={{ borderRadius: "4px" }}>
          {taskIcon} {item?.Task_key}
        </MDBBadge>
      );
    } else if (item?.Status == "DEVELOPMENT DONE") {
      statusBg = (
        <MDBBadge className="status-blue" style={{ borderRadius: "4px" }}>
          {taskIcon} {item?.Task_key}
        </MDBBadge>
      );
    } else if (item?.Status == "REJECTED") {
      statusBg = (
        <MDBBadge className="badge-light" style={{ borderRadius: "4px" }}>
          {taskIcon} {item?.Task_key}
        </MDBBadge>
      );
    } else if (item?.Status == "ON HOLD") {
      statusBg = (
        <MDBBadge className="badge-light" style={{ borderRadius: "4px" }}>
          {taskIcon} {item?.Task_key}
        </MDBBadge>
      );
    } else if (item?.Status == "READY FOR QA") {
      statusBg = (
        <MDBBadge className="status-blue" style={{ borderRadius: "4px" }}>
          {taskIcon} {item?.Task_key}
        </MDBBadge>
      );
    } else if (item?.Status == "REOPEN") {
      statusBg = (
        <MDBBadge className="badge-light" style={{ borderRadius: "4px" }}>
          {taskIcon} {item?.Task_key}
        </MDBBadge>
      );
    } else if (item?.Status == "READY FOR UAT") {
      statusBg = (
        <MDBBadge
          className="status-green"
          style={{ borderRadius: "4px" }}
          color="success"
        >
          {taskIcon} {item?.Task_key}
        </MDBBadge>
      );
    } else if (item?.Status == "CLOSE") {
      statusBg = (
        <MDBBadge
          className="status-green"
          style={{ borderRadius: "4px" }}
          color="success"
        >
          {taskIcon} {item?.Task_key}
        </MDBBadge>
      );
    }

    return <>{statusBg}</>;
  };

  const getFilterValue = () => {
    const filtered = data.filter((item) => {
      return item?.Employee_id?.Role?.includes(departmentName);
    });
    setFilteredData(filtered);
  };

  useEffect(() => {
    if (departmentName) {
      getFilterValue();
    }
  }, [data]);

  const onChange = (value) => {
    setDepartmentName(value);
    const filtered = data.filter((item) => {
      return item?.Employee_id?.Role?.includes(value);
    });
    setFilteredData(filtered);
  };

  const getDepartmentList = () => {
    AxiosInstance.get("auth/department/list", {}).then(async (res) => {
      setList(res.data.data);
    });
  };

  useEffect(() => {
    getDepartmentList();
  }, []);

  useEffect(() => {
    setListHtml(
      list.map((data) => {
        return {
          key: data.Department_name,
          value: data.Department_name,
        };
      })
    );
  }, [list]);

  return (
    <>
      <Select
        showSearch
        className="dropdown-size"
        placeholder="Select Department"
        optionFilterProp="children"
        onChange={onChange}
        // value={departmentName}
        onSearch={onSearch}
        filterOption={(input, option) =>
          (option?.value ?? "").toLowerCase().includes(input.toLowerCase())
        }
        options={listHtml}
      />{" "}
      {loading}
      <Table
        dataSource={dataTable}
        columns={columns}
        className="sevendays_table"
      ></Table>
    </>
  );
};

export default CurrentWorkingTasks;
