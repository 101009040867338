import React, { useState, useEffect, useRef } from "react";
import { ChangeTaskStatus } from "../../../redux/actions/TaskStatusActions";
import {
  MDBCardBody,
  MDBTypography,
  MDBCol,
  MDBRow,
  MDBIcon,
} from "mdb-react-ui-kit";
import {
  MDBDropdown,
  MDBDropdownMenu,
  MDBDropdownToggle,
  MDBDropdownItem,
  MDBDropdownLink,
} from "mdb-react-ui-kit";
import { Link, useLocation } from "react-router-dom";
import AxiosInstance from "../../../service/AxiosInstance";
import Edit from "./Edit";
import { useDispatch, useSelector } from "react-redux";
import { useParams, useHistory } from "react-router-dom";
import parse from "html-react-parser";
import "../comment/Comments.css";
import {
  Button,
  Spin,
  Image,
  Select,
  Form,
  Table,
  Input,
  DatePicker,
} from "antd";
import TaskTimer from "./TaskTimer";
import { HomeOutlined, FolderOutlined } from "@ant-design/icons";
import { Breadcrumb } from "antd";
import TaskComments from "../comment/TaskComments";
import * as XLSX from "xlsx";
import * as FileSaver from "file-saver";
import moment from "moment";
import { Png } from "../../../assets/png";
import { MDBDataTableV5 } from "mdbreact";
import TablePagination from "@mui/material/TablePagination";
const { Option } = Select;
// import { useSelector } from "react-redux";
const imageUrl = "https://bucket-pms.s3.ap-south-1.amazonaws.com/s3-upload/";
const View = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  // pagination
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  let history = useHistory();
  const [count, setCount] = useState(0);
  const [changeTask, setChangeTask] = useState(false);
  const [taskData, setTaskData] = useState({});
  const [statusUpdate, setStatusUpdate] = useState("");
  const [taskDataByKey, setTaskDataByKey] = useState([]);
  console.log({ taskDataByKey });
  let userData = JSON.parse(localStorage.getItem("userdata"));
  const params = useParams();

  const User = JSON.parse(localStorage.getItem("userdata"));
  const [employee, setEmployee] = useState([]);
  const [assignedList, setAssignedList] = useState([]);
  const [sprintdata, setSprintdata] = useState([]);

  const [projectID, setProjectID] = useState("");
  const [visible, setVisible] = useState(false);
  const [lastStatusDb, setLastStatusDb] = useState("");
  const [myValue, setMyValue] = useState("");
  const [assigneeName, setAssigneeName] = useState({ current: "", prev: "" });
  const [sprintName, setSprintName] = useState({ current: "", prev: "" });
  console.log(sprintdata, 75);
  console.log(taskData, 85);
  // const [status, setStatus] = useState([]);
  // const [newStatus, setNewStatus] = useState([]);
  const previousValue = useRef();

  const [isueType, setIsueType] = useState();

  // console.log({ isueType });
  const [statusValue, setStatusValue] = useState("");
  const [statusValue2, setStatusValue2] = useState("");
  console.log(statusValue, statusValue2);

  const [assignDate, setAssignDate] = useState();

  useEffect(() => {
    if (taskDataByKey && taskDataByKey.length > 0) {
      let filteredStatus = taskDataByKey?.filter(
        (v) =>
          (!statusValue || v.Issue_type === statusValue) &&
          (!statusValue2 || v.Status === statusValue2)
      );
      // console.log({ filteredStatus });
      setIsueType(filteredStatus);
    }
  }, [statusValue, statusValue2, taskDataByKey]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const fileExtension = ".xlsx";
  const fileType =
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
  const checkAction = (data) => {
    const csvData = data.map((item) => ({
      Type: item?.Issue_type,
      Task: item?.Task_key,
      Priority: item?.Priority,
      Short_summary: item?.Short_summary,
      // Due_date2: item?.Due_date2,
      Reporter: item?.Reporter,
      Assignees: item?.Assignees,
      Created_date: item?.createdAt
        ? moment(item.createdAt).format("DD-MM-YYYY")
        : null,
      Assigned_date: item?.Assigned_date2
        ? moment(item.Assigned_date2).format("DD-MM-YYYY")
        : null,
      Due_date: item?.Due_date2
        ? moment(item.Due_date2).format("DD-MM-YYYY")
        : null,
      // Due_date: item?.Due_date
      // ? moment(item.Due_date2).format("DD-MM-YYYY")
      // : null,
      Status: item?.Status,
    }));

    exportAll(csvData, `${taskData?.Task_key}  sheet`);
  };

  const exportAll = (csvData, fileName) => {
    const ws = XLSX.utils.json_to_sheet(csvData);

    ws["!cols"] = [
      { wpx: 50 },
      { wpx: 80 },
      { wpx: 100 },
      { wpx: 150 },
      { wpx: 150 },
      { wpx: 150 },
    ];

    const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const fileType =
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
    const fileExtension = ".xlsx";
    const data = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(data, fileName + fileExtension);
  };

  useEffect(() => {
    previousValue.current = lastStatusDb;
  }, [lastStatusDb]);

  const changeStatus = (value) => {
    dispatch(ChangeTaskStatus());
    previousValue.current = myValue;
    setMyValue(value);
    // create history
    const formData1 = new URLSearchParams();
    formData1.append("Task_id", taskData?._id);
    formData1.append(
      "Updated_by",
      userData[0]?.First_name + " " + userData[0]?.Last_name
    );
    formData1.append("Current_Status", `${value}`);
    formData1.append("Previous_Status", `${previousValue.current}`);
    AxiosInstance.post("auth/history/add", formData1).then((res) => {
      setChangeTask(!changeTask);
      getHistoryDetails();
      if (res.data.status == 1) {
      }
    });
  };

  const getHistoryDetails = async () => {
    const response = await AxiosInstance.get(`auth/history/${params.id}`).then(
      () => {}
    );
    // setEmpId(res
  };

  useEffect(() => {
    if (projectID) {
      //Employee
      AxiosInstance.get(`auth/projectemployeelist/${projectID}`).then(
        async (res) => {
          if (res.data.status == 1) {
            setEmployee(res.data.data);

            let EmID = res.data.data.map((member) => {
              return {
                label:
                  member?.First_name +
                  " " +
                  member?.Last_name +
                  " (" +
                  member?.Role +
                  ")",
                value: member?._id,
              };
            });
            setAssignedList(EmID);
          }
        }
      );
    }
  }, [projectID]);
  const getSprintData = () => {
    // if (taskDatae?.Project_id) {
    const urlencoded = new URLSearchParams();
    urlencoded.append("Project_id", params.projectid);
    AxiosInstance.post("sprint/getAllsprint", urlencoded).then((res) => {
      setSprintdata(res.data.data);
    });
    // }
  };

  useEffect(() => {
    getSprintData();
  }, [params?.projectid]);
  const gettaskDetails = () => {
    const formData = new URLSearchParams();
    formData.append("id", params.id);

    //API  details
    AxiosInstance.post("auth/tasks/details", formData).then(async (res) => {
      if (res.data.status == 1) {
        // console.log("taskData",taskData);
        setTaskData(res.data.data);
        setProjectID(res.data.data.Project_id);
        setMyValue(res.data.data.Status);
        setAssigneeName((prev) => ({
          ...prev,
          current: res.data.data.Assignees,
        }));
        setSprintName((prev) => ({
          ...prev,
          current: res.data.data.Sprint,
        }));
      }
    });
  };

  useEffect(() => {
    gettaskDetails();
  }, [params?.id]);

  //fetching task details by task key
  useEffect(() => {
    if (taskData?._id != undefined && taskData?._id != "") {
      const formData = new URLSearchParams();
      formData.append("task_id", taskData?._id);
      AxiosInstance.post("auth/tasks/TaskDetailsByTaskKey", formData).then(
        (res) => {
          console.log(res.data.data, "7777");
          if (res.data.status == 1) {
            if (res?.data?.data?.length) {
              setTaskDataByKey(res.data.data);
              setIsueType(res.data.data);
            }
          }
        }
      );
    } else {
      setTaskDataByKey([]);
    }
  }, [taskData]);

  const columns = [
    {
      title: "Task type",
      dataIndex: "Issue_type",
      key: "Issue_type",

      render: (Issue_type) => {
        if (Issue_type === "Bug") {
          return <MDBIcon fas icon="bug" style={{ color: "red" }} />;
        } else if (Issue_type === "Story") {
          return <MDBIcon fas icon="bookmark" style={{ color: "green" }} />;
        } else if (Issue_type === "Suggestion") {
          return <MDBIcon fas icon="spider" style={{ color: "green" }} />;
        } else {
          return <MDBIcon fas icon="check" style={{ color: "#1890ff" }} />;
        }
      },
    },

    {
      title: "Task key",
      dataIndex: "Task_key",
      key: "task",
      render: (text, record) => (
        <Link
          style={{ fontWeight: "500" }}
          to={`/project/task/${record._id}/${record.Project_id}`}
        >
          {text}
        </Link>
      ),
    },

    {
      title: "Short summary",
      dataIndex: "Short_summary",
      key: "Short_summary",
    },

    {
      title: "Due date",
      dataIndex: "Due_date2",
      key: "Due_date2",
      render: (text) => (text ? moment(text).format("DD-MM-YYYY") : null),
    },

    {
      title: "Priority",
      dataIndex: "Priority",
      key: "Priority",
      render: (Priority) => {
        if (Priority == "HIGH") {
          return <MDBIcon style={{ color: "red" }} fas icon="arrow-up" />;
        } else if (Priority == "LOW") {
          return <MDBIcon style={{ color: "green" }} fas icon="arrow-down" />;
        } else {
          return <MDBIcon style={{ color: "#ffa900" }} fas icon="arrow-up" />;
        }
      },
    },

    {
      title: "Assignees",
      dataIndex: "Assignees",
      key: "Assignees",
    },

    {
      title: "Status",
      dataIndex: "Status",
      key: "Status",
    },
  ];

  useEffect(() => {
    gettaskDetails();
  }, [count, params?.id, statusUpdate]);

  useEffect(() => {
    //API History
    const taskId = params?.id;
    // AxiosInstance.get(`auth/history/${taskId}`).then(async (res) => {
    //   if (res.data.status == 1) {
    //     setHistoryList(res.data.data)
    //   }
    // })
  }, [taskData, count, params.id, statusUpdate]);
  const statusChange = (st) => {
    dispatch(ChangeTaskStatus());
    // dispatch(statusChange(st))
    let formData = new URLSearchParams();
    formData.append("id", taskData?._id);
    formData.append("Status", st);

    AxiosInstance.post("auth/tasks/edit", formData)
      .then((res) => {
        if (res.data.status == 1) {
          setStatusUpdate(st);
        }
      })
      .catch((err) => console.log(err, "err"));
    // setStatusUpdate(st);
  };

  useEffect(() => {}, [assigneeName?.current]);

  const updateTask = (a) => {
    const formData1 = new URLSearchParams();
    formData1.append("Task_id", taskData?._id);
    formData1.append(
      "Updated_by",
      userData[0]?.First_name + " " + userData[0]?.Last_name
    );
    formData1.append("Current_Assignees", a.current);
    formData1.append("Previous_Assignees", `${a.prev}`);
    AxiosInstance.post("auth/history/add", formData1).then((res) => {
      if (res.data.status == 1) {
      }
    });
  };

  const handleChange = async (e) => {
    // setAssignMember(e);
    const object = employee.filter((emp) => {
      return emp._id == e;
    });
    if (object.length == 0) {
      alert("Issue while assigning user. Please refresh and try again.");
      return false;
    }

    let formData = new URLSearchParams();
    formData.append("id", taskData?._id);

    formData.append(
      "Assignees",
      object[0].First_name + " " + object[0].Last_name
    );
    formData.append("Employee_id", object[0]._id);

    let updatedTask = await AxiosInstance.post("auth/tasks/edit", formData);

    setAssigneeName({
      current: updatedTask?.data?.data[0]?.Assignees,
      prev: assigneeName.current,
    });
    setSprintName({
      current: updatedTask?.data?.data[0]?.sprint,
      prev: sprintName.current,
    });

    if (updatedTask?.data?.data[0]?.Assignees) {
      let Assignees = {
        current: updatedTask.data.data[0].Assignees,
        prev: assigneeName.current,
      };
      updateTask(Assignees);
    }
    gettaskDetails();

    let str = "Java1scri3pt4";
    let y = str.split("");
    console.log(
      y.map((a) => Number(a)),
      "132123131"
    );

    // setStatusUpdate(st);
  };
  console.log(sprintdata, 753);

  // const handleSprint = async (e) => {
  //   // setAssignMember(e);
  //   console.log(e, 456);

  //   const object = sprintdata.filter((emp) => {
  //     console.log(emp, "");
  //     return emp._id == e?._id;
  //   });
  //   console.log(object, 450);
  //   // if (object.length == 0) {
  //   //   alert("Issue while assigning user. Please refresh and try again.");
  //   //   return false;
  //   // }

  //   let formData = new URLSearchParams();
  //   formData.append("id", taskData?._id);

  //   formData.append("Sprint", object[0].Name);
  //   formData.append("Employee_id", object[0]._id);

  //   let updatedTask = await AxiosInstance.post(
  //     "auth/tasks/edit-details",
  //     formData
  //   );

  //   setSprintName({
  //     current: updatedTask?.data?.data[0]?.Name,
  //     prev: sprintName.current,
  //   });

  //   if (updatedTask?.data?.data[0]?.Name) {
  //     let sprint = {
  //       current: updatedTask.data.data[0].Name,
  //       prev: assigneeName.current,
  //     };
  //     updateTask(sprint);
  //   }
  //   gettaskDetails();

  //   let str = "Java1scri3pt4";
  //   let y = str.split("");
  //   console.log(
  //     y.map((a) => Number(a)),
  //     "132123131"
  //   );

  //   // setStatusUpdate(st);
  // };




  const handleSprintChange = async (e) => {
    const sprint = JSON.parse(e);
    let formData = new URLSearchParams();
    formData.append("id", taskData?._id);
    formData.append("Sprint_id", sprint._id);
  
    let updatedTask = await AxiosInstance.post("auth/tasks/edit", formData);
  
    setSprintName({
      current: updatedTask?.data?.data[0]?.sprint,
      prev: sprintName.current,
    });
  
    gettaskDetails();
  };


  const handleTaskDurationChange = async (duration) => {
    let formData = new URLSearchParams();
    formData.append("id", taskData?._id);
    formData.append("Task_duration", duration);
  
    try {
      let updatedTask = await AxiosInstance.post("auth/tasks/edit", formData);
      if (updatedTask.data.status === 1) {
        gettaskDetails();
      }
    } catch (error) {
      console.error("Error updating task duration:", error);
    }
  };






  const handleAssignedDateChange = async (date, dateString) => {
    if (!date) return;
  
    let formData = new URLSearchParams();
    formData.append("id", taskData?._id);
    formData.append("Assigned_date", date.format("YYYY-MM-DD"));
  
    try {
      let updatedTask = await AxiosInstance.post("auth/tasks/edit", formData);
      if (updatedTask.data.status === 1) {
        gettaskDetails();
      }
    } catch (error) {
      console.error("Error updating assigned date:", error);
    }
  };
  
  const handleDueDateChange = async (date, dateString) => {
    if (!date) return;
  
    let formData = new URLSearchParams();
    formData.append("id", taskData?._id);
    formData.append("Due_date", date.format("YYYY-MM-DD"));
  
    try {
      let updatedTask = await AxiosInstance.post("auth/tasks/edit", formData);
      if (updatedTask.data.status === 1) {
        gettaskDetails();
      }
    } catch (error) {
      console.error("Error updating due date:", error);
    }
  };
  





  




  function ImageDemo({ element, index }) {
    // let linkarray = element.split('.');
    let linkarray =
      element !== null && element !== undefined ? element?.split(".") : [];
    let exe = "jpg,jpeg,png";
    console.log(
      "cxcasdcasdasdasd",
      exe.includes(linkarray[linkarray.length - 1])
    );
    return (
      <>
        <div className="image">
          <div>
            {/* <a href={element}> */}
            {exe.includes(linkarray[linkarray.length - 1]) ? (
              <a href={element}>
                <Image
                  width={80}
                  height={80}
                  src={
                    exe.includes(linkarray[linkarray.length - 1])
                      ? imageUrl + element
                      : "https://cdn1.iconfinder.com/data/icons/leto-files/64/leto_files-76-512.png"
                  }
                  // preview={{
                  //   onVisibleChange:(element)=>{<a href={element}>}
                  // }}
                />
              </a>
            ) : (
              <a
                href={element}
                className="ant-image"
                target="_blank"
                rel="noopener noreferrer"
              >
                <Image
                  width={80}
                  height={80}
                  src={
                    "https://cdn1.iconfinder.com/data/icons/leto-files/64/leto_files-76-512.png"
                  }
                />
              </a>
            )}
          </div>
        </div>
      </>
    );
  }

  // priorityIcon
  let priorityIcon = (
    <MDBIcon
      style={{ color: "#ffa900", marginLeft: "15px" }}
      fas
      icon="arrow-up"
    />
  );
  if (taskData.Priority == "HIGH") {
    priorityIcon = (
      <MDBIcon
        style={{ color: "red", marginLeft: "15px" }}
        fas
        icon="arrow-up"
      />
    );
  } else if (taskData.Priority == "LOW") {
    priorityIcon = (
      <MDBIcon
        style={{ color: "green", marginLeft: "15px" }}
        fas
        icon="arrow-down"
      />
    );
  }

  // taskIcon
  let taskIcon = <MDBIcon fas icon="check" style={{ color: "#1890ff" }} />;
  if (taskData.Issue_type == "Bug") {
    taskIcon = <MDBIcon fas icon="bug" style={{ color: "red" }} />;
  } else if (taskData.Issue_type == "Story") {
    taskIcon = <MDBIcon fas icon="bookmark" style={{ color: "green" }} />;
  } else if (taskData.Issue_type == "Suggestion") {
    taskIcon = (
      <img
        src={Png.SuggestionIcon}
        style={{ marginBottom: "16px", width: "30px" }}
      />
    );
  } else {
    taskIcon = <MDBIcon fas icon="check" style={{ color: "#1890ff" }} />;
  }

  // status bg
  let statusBg = "shadow-0";
  if (taskData?.Status == "IN PROGRESS") {
    statusBg = "status-blue";
  } else if (taskData?.Status == "DEVELOPMENT DONE") {
    statusBg = "status-blue";
  } else if (taskData?.Status == "OPEN") {
    statusBg = "badge-light";
  } else if (taskData?.Status == "REJECTED") {
    statusBg = "badge-light";
  } else if (taskData?.Status == "ON HOLD") {
    statusBg = "badge-light";
  } else if (taskData?.Status == "READY FOR QA") {
    statusBg = "status-blue";
  } else if (taskData?.Status == "REOPEN") {
    statusBg = "badge-light";
  } else if (taskData?.Status == "READY FOR UAT") {
    statusBg = "status-green";
  } else if (taskData?.Status == "CLOSE") {
    statusBg = "status-green";
  }

  const permssionsState = useSelector((state) => state.Permissions.Permissions);
  const [rolePermissions, setRolepermissions] = useState({});
  useEffect(() => {
    setRolepermissions(permssionsState);
  }, [permssionsState]);
  let textWithLinks = "";

  function addAttributeToTagInString(
    string,
    tagName,
    attributeName,
    attributeValue
  ) {
    const parser = new DOMParser();
    const document = parser.parseFromString(string, "text/html");
    const tag = document.querySelector(tagName);
    if (tag) {
      tag.setAttribute(attributeName, attributeValue);
    }
    return document.documentElement.innerHTML;
  }

  const string = taskData?.Description;
  const stepsString = taskData?.Steps_to_reproduce;
  let modifiedDescription = addAttributeToTagInString(
    string,
    "a",
    "target",
    "_blank"
  );
  let modifiedStepsToReproduce = addAttributeToTagInString(
    stepsString,
    "a",
    "target",
    "_blank"
  );
  modifiedDescription = modifiedDescription
    .replace("<head>", "")
    .replace("</head>", "")
    .replace("<body>", "")
    .replace("</body>", "");
  modifiedStepsToReproduce = modifiedStepsToReproduce
    .replace("<head>", "")
    .replace("</head>", "")
    .replace("<body>", "")
    .replace("</body>", "");
  // if (taskData && taskData.Description) {
  //   const urlRegex = /(https?:\/\/[^\s]+)/g; // regular expression to find URLs
  //   textWithLinks = taskData.Description.replace(urlRegex, (url) => (
  //     <a href={url} target="_blank">{url}</a> // wrap URLs with an anchor tag
  //   ));
  //   function addAttributeToTagInString(string, tagName, attributeName, attributeValue) {
  //     const parser = new DOMParser();
  //     const document = parser.parseFromString(string, 'text/html');
  //     const tag = document.querySelector(tagName);
  //     if (tag) {
  //       tag.setAttribute(attributeName, attributeValue);
  //     }
  //     return document.documentElement.innerHTML;
  //   }

  //   const string = '<p>This is a <a href="https://www.example.com">link</a> to example.com</p>';
  // const modifiedString = addAttributeToTagInString(string, 'a', 'target', '_blank');

  // const filterDATA = modifiedDescription
  //   .replace("</a>&nbsp;</p>", " ")
  //   // .replace("<a href=\"https://prnt.sc/GXUoLa9OSBrH\">", " ")
  //   .replace("</p><p><a href=\"", " ")
  //   .replace("\">", " ")
  //   .replace("</a>", " ")
  //   .replace("<p>", " ")
  //   .replace("? ", " ")
  //   .replace("<head></head><body>", " ");
  //   // .replace(" " target="_blank https://prnt.sc/GXUoLa9OSBrH </body>", " ");

  // console.log(taskDataByKey, "column",columns);

  const [form] = Form.useForm();

  useEffect(() => {
    // Set the initial value when taskData is loaded or updated
    form.setFieldsValue({
      taskDuration: taskData?.Task_duration,
    });
  }, [taskData, form]);
  // console.log("{taskData}",{taskData});
  return (
    <>
      <div
        className={
          location.pathname == `/task/${params.id}` ? "event-main" : ""
        }
      >
        <div className="cardbackground">
          <div className="cardmargine">
            <div>
              <MDBCardBody style={{ padding: "10px 15px" }}>
                <MDBTypography blockquote className="mb-0">
                  <div>
                    <div className="view-task-bread">
                      <Breadcrumb>
                        <Breadcrumb.Item href="#">
                          <HomeOutlined />
                        </Breadcrumb.Item>
                        <Breadcrumb.Item href="#projects">
                          <FolderOutlined />
                          <span>Project</span>
                        </Breadcrumb.Item>
                        <Breadcrumb.Item href="">
                          <FolderOutlined />
                          <Link
                            style={{ fontWeight: "500" }}
                            to={`/project/tasks/${taskData.Project_id}`}
                          >
                            {taskData.Task_key}
                          </Link>
                        </Breadcrumb.Item>
                        {/* <Breadcrumb.Item>{taskData.Task_key}</Breadcrumb.Item> */}
                      </Breadcrumb>
                    </div>
                    <MDBRow className="mx-0 pro-task-details-res">
                      <MDBCol
                        size="9"
                        className="mt-20 left-task-view"
                        style={{ paddingLeft: "0px" }}
                      >
                        <div
                          className="TaskKeyTitle"
                          style={{ margin: "0 0 10px 0" }}
                        >
                          <MDBRow className="mx-0">
                            <MDBCol>
                              <h4 style={{ margin: "0px" }}>
                                <strong>
                                  {taskIcon}{" "}
                                  {taskData.Task_key ? taskData.Task_key : ""}
                                </strong>
                              </h4>
                            </MDBCol>
                            <MDBCol
                              style={{ textAlign: "right", padding: "0px" }}
                            >
                              <span>
                                {rolePermissions?.Project?.Edit_Task == "1" ? (
                                  <>
                                    <Button
                                      type="primary"
                                      onClick={() => setVisible(true)}
                                      className="pms-same-btn"
                                    >
                                      <MDBIcon fas icon="edit" />
                                    </Button>
                                    {visible && (
                                      <Edit
                                        taskData={taskData}
                                        setCount={setCount}
                                        setVisible={setVisible}
                                        visible={visible}
                                      />
                                    )}
                                  </>
                                ) : (
                                  ""
                                )}

                                <Button
                                  onClick={() => history.goBack()}
                                  style={{ marginLeft: "10px" }}
                                  className="pms-same-btn-cancel"
                                >
                                  <MDBIcon
                                    style={{ paddingRight: "5px" }}
                                    fas
                                    icon="long-arrow-alt-left"
                                  />
                                  Back
                                </Button>
                              </span>
                            </MDBCol>
                          </MDBRow>
                        </div>

                        <div className="mx-0">
                          <div style={{ fontWeight: "BoldOutlined" }}>
                            <h4 style={{ margin: "0 0 10px 0" }}>
                              {taskData.Short_summary
                                ? taskData.Short_summary
                                : ""}
                            </h4>
                          </div>
                        </div>
                        <div className="TitleStyle">Description</div>
                        <div
                          className="d-flex align-items-start bg-light scrollbar"
                          id="style-7"
                          style={{
                            fontWeight: 2,
                          }}
                        >
                          <div
                            className="force-overflow"
                            style={{ padding: "7px 0" }}
                          >
                            <p style={{ margin: "0" }}>
                              {/* {modifiedDescription} */}
                              {taskData?.Description
                                ? parse(modifiedDescription)
                                : ""}
                            </p>
                          </div>
                        </div>
                        {taskData?.Issue_type === "Bug" && (
                          <>
                            <div className="TitleStyle">Steps To Reproduce</div>
                            <div
                              className="d-flex align-items-start bg-light scrollbar"
                              id="style-7"
                              style={{
                                fontWeight: 2,
                              }}
                            >
                              <div
                                className="force-overflow"
                                style={{ padding: "7px 0" }}
                              >
                                <p style={{ margin: "0" }}>
                                  {/* {modifiedDescription} */}
                                  {taskData?.Steps_to_reproduce
                                    ? parse(modifiedStepsToReproduce)
                                    : ""}
                                </p>
                              </div>
                            </div>
                          </>
                        )}
                        {taskDataByKey.length > 0 && (
                          <MDBCol>
                            <div className="story-main-box">
                              <div
                                className="TitleStyle"
                                style={{ marginTop: "10px" }}
                              >
                                Linked Task Details
                              </div>

                              <div className="main-div-setup">
                                <div className="share-image-backlog">
                                  <div className="backlog-sprint-task-status">
                                    {/* <label className="start-list">Issue Type</label> */}
                                    <Select
                                      placeholder="Task Type"
                                      // onChange={(e) => {
                                      //   setIsueType(e);
                                      // }}
                                      onChange={(e) => setStatusValue(e)}
                                      // defaultValue={"Task"}
                                    >
                                      <Select.Option value="">
                                        All Task
                                      </Select.Option>
                                      <Select.Option value="Bug">
                                        Bug
                                      </Select.Option>
                                      <Select.Option value="Task">
                                        Task
                                      </Select.Option>
                                      <Select.Option value="Story">
                                        Story
                                      </Select.Option>
                                      <Select.Option value="Suggestion">
                                        Suggestion
                                      </Select.Option>
                                    </Select>
                                  </div>
                                </div>

                                <div className="share-image-backlog">
                                  <div className="backlog-sprint-task-status">
                                    {/* <label className="start-list">Status</label> */}
                                    <Select
                                      placeholder="Status"
                                      // defaultValue={""}
                                      // mode="multiple"
                                      className="select-dropdown cus-select-dropdown leave-4"
                                      onChange={(e) => {
                                        setStatusValue2(e);
                                      }}
                                    >
                                      <Option value="">All Status</Option>
                                      <Option value="DRAFT">DRAFT</Option>
                                      <Option value="OPEN">OPEN</Option>
                                      <Option value="IN PROGRESS">
                                        IN PROGRESS
                                      </Option>
                                      <Option value="DEVELOPMENT DONE">
                                        DEVELOPMENT DONE
                                      </Option>
                                      <Option value="REJECTED">REJECTED</Option>
                                      <Option value="ON HOLD">ON HOLD</Option>
                                      <Option value="READY FOR QA">
                                        READY FOR QA
                                      </Option>
                                      <Option value="REOPEN">REOPEN</Option>
                                      <Option value="READY FOR UAT">
                                        READY FOR UAT
                                      </Option>
                                      <Option value="CLOSE">CLOSE</Option>
                                    </Select>
                                  </div>
                                </div>

                                <div
                                  className="story-export-box"
                                  onClick={() => checkAction(taskDataByKey)}
                                >
                                  <img
                                    src={Png.exportIcon}
                                    className="story-export-icon"
                                  />
                                </div>
                              </div>
                            </div>
                            <Table
                              className="task_table custom-table-my-first"
                              columns={columns}
                              dataSource={isueType?.slice(
                                page * rowsPerPage,
                                page * rowsPerPage + rowsPerPage
                              )}
                            />
                            <TablePagination
                              component="div"
                              count={isueType?.length}
                              page={page}
                              onPageChange={handleChangePage}
                              rowsPerPage={rowsPerPage}
                              rowsPerPageOptions={[10, 20, 30, 40]}
                              onRowsPerPageChange={handleChangeRowsPerPage}
                            />
                          </MDBCol>
                        )}
                        <div className="activity-text">Activity</div>

                        <div className="emp-task-tab">
                          {/* <Comments User={User} TaskId={params.id} /> */}
                          <TaskComments
                            User={User}
                            changeTask={changeTask}
                            current={myValue}
                            previous={previousValue.current}
                            TaskId={params.id}
                          />
                        </div>

                        {/* <Comments User={User} TaskId={params.id} /> */}
                      </MDBCol>

                      {/* <MDBCard></MDBCard> */}
                      <MDBCol
                        className="right-task-view"
                        size="3"
                        style={{ paddingLeft: "10px" }}
                      >
                        <div className="mx-0">
                          <MDBCol className="mt-20">
                            <MDBDropdown>
                              <MDBDropdownToggle
                                style={{
                                  // textTransform: 'none',
                                  fontSize: "1rem",
                                  padding: "0.3rem 1rem .3rem 1rem",
                                }}
                                className={statusBg}
                              >
                                {taskData?.Status ? (
                                  taskData?.Status
                                ) : (
                                  <Spin size="large" />
                                )}
                              </MDBDropdownToggle>
                              <MDBDropdownMenu>
                                <MDBDropdownItem>
                                  <MDBDropdownLink
                                    onClick={() => {
                                      statusChange("DRAFT");
                                      changeStatus("DRAFT");
                                    }}
                                  >
                                    DRAFT
                                  </MDBDropdownLink>
                                </MDBDropdownItem>
                                <MDBDropdownItem>
                                  <MDBDropdownLink
                                    onClick={() => {
                                      statusChange("OPEN");
                                      changeStatus("OPEN");
                                    }}
                                  >
                                    OPEN
                                  </MDBDropdownLink>
                                </MDBDropdownItem>
                                <MDBDropdownItem>
                                  <MDBDropdownLink
                                    onClick={() => {
                                      statusChange("IN PROGRESS");
                                      changeStatus("IN PROGRESS");
                                    }}
                                  >
                                    IN PROGRESS
                                  </MDBDropdownLink>
                                </MDBDropdownItem>
                                <MDBDropdownItem>
                                  <MDBDropdownLink
                                    onClick={() => {
                                      statusChange("DEVELOPMENT DONE");
                                      changeStatus("DEVELOPMENT DONE");
                                    }}
                                  >
                                    DEVELOPMENT DONE
                                  </MDBDropdownLink>
                                </MDBDropdownItem>
                                <MDBDropdownItem>
                                  <MDBDropdownLink
                                    onClick={() => {
                                      statusChange("REJECTED");
                                      changeStatus("REJECTED");
                                    }}
                                  >
                                    REJECTED
                                  </MDBDropdownLink>
                                </MDBDropdownItem>
                                <MDBDropdownItem>
                                  <MDBDropdownLink
                                    onClick={() => {
                                      statusChange("ON HOLD");
                                      changeStatus("ON HOLD");
                                    }}
                                  >
                                    ON HOLD
                                  </MDBDropdownLink>
                                </MDBDropdownItem>
                                <MDBDropdownItem>
                                  <MDBDropdownLink
                                    onClick={() => {
                                      statusChange("READY FOR QA");
                                      changeStatus("READY FOR QA");
                                    }}
                                  >
                                    READY FOR QA
                                  </MDBDropdownLink>
                                </MDBDropdownItem>
                                <MDBDropdownItem>
                                  <MDBDropdownLink
                                    onClick={() => {
                                      statusChange("REOPEN");
                                      changeStatus("REOPEN");
                                    }}
                                  >
                                    REOPEN
                                  </MDBDropdownLink>
                                </MDBDropdownItem>
                                <MDBDropdownItem>
                                  <MDBDropdownLink
                                    onClick={() => {
                                      statusChange("READY FOR UAT");
                                      changeStatus("READY FOR UAT");
                                    }}
                                  >
                                    READY FOR UAT
                                  </MDBDropdownLink>
                                </MDBDropdownItem>
                                <MDBDropdownItem>
                                  <MDBDropdownLink
                                    onClick={() => {
                                      statusChange("CLOSE");
                                      changeStatus("CLOSE");
                                    }}
                                  >
                                    CLOSE
                                  </MDBDropdownLink>
                                </MDBDropdownItem>
                              </MDBDropdownMenu>
                            </MDBDropdown>
                          </MDBCol>
                          {taskData._id && (
                            <TaskTimer
                              taskID={taskData._id}
                              projectID={taskData.Project_id}
                            />
                          )}

                          <MDBCol className="mt-20 task-common">
                            <span className="TitleStyle left-col-description">
                              Project Name
                            </span>

                            <Link
                              style={{ margin: "0 0 0 15px", fontSize: "13px" }}
                              to={`/project/view/${taskData.Project_id}`}
                            >
                              {taskData.Project_name
                                ? taskData.Project_name
                                : ""}
                            </Link>
                          </MDBCol>
                          <MDBCol className="mt-20 task-common">
                            <span className="TitleStyle left-col-description">
                              Priority
                            </span>
                            <p style={{ margin: "0px" }}>
                              {priorityIcon}
                              <span
                                style={{
                                  margin: "0 0 0 10px",
                                  fontSize: "13px",
                                }}
                              >
                                ({" "}
                                {taskData.Priority
                                  ? parse(taskData.Priority)
                                  : ""}{" "}
                                )
                              </span>
                            </p>
                          </MDBCol>
                        </div>

                        <div className="mx-0">
                          <MDBCol className="mt-20 task-common custom-task-common">
                            <span className="TitleStyle left-col-description">
                              Assignees
                            </span>
                            <Form.Item
                              style={{ margin: "0 0 0 10px" }}
                              rules={[
                                {
                                  required: true,
                                  message: "Please Select Project Name",
                                  whitespace: true,
                                },
                              ]}
                            >
                              <Select
                                className="select-pro-task-ass"
                                onChange={(e) => handleChange(e)}
                                showSearch
                                value={taskData?.Assignees}
                                placeholder="Please Select Reporting Person"
                                optionFilterProp="children"
                                filterOption={(input, option) =>
                                  (
                                    option?.label.toLocaleLowerCase() ?? ""
                                  ).includes(input.toLocaleLowerCase())
                                }
                                options={assignedList}
                              ></Select>
                            </Form.Item>
                          </MDBCol>

                          <MDBCol className="mt-20 task-common">
                            <span className="TitleStyle left-col-description">
                              Reporter
                            </span>
                            <p style={{ margin: "0 0 0 15px" }}>
                              {taskData.Reporter ? taskData.Reporter : ""}
                            </p>
                          </MDBCol>
                          <MDBCol className="mt-20 task-common custom-task-common">
                            <span className="TitleStyle left-col-description">
                              Sprint
                            </span>
                            {/* <p style={{ margin: "0 0 0 15px" }}>
                              {taskData?.Sprint_id?.Name
                                ? taskData?.Sprint_id?.Name
                                : "-"}
                            </p> */}
                            <Form.Item
                              style={{ margin: "0 0 0 10px" }}
                              rules={[
                                {
                                  required: true,
                                  message: "Please Select Project Name",
                                  whitespace: true,
                                },
                              ]}
                            >
                              {/* <Select showSearch="true"> */}
                              <Select
                                className="select-pro-task-ass"
                                onChange={(e) => handleSprintChange(e)}
                                showSearch
                                placeholder="Please Select Sprint"
                                value={taskData?.Sprint_id?.Name}
                              >
                                {/* {sprintdata?.map((item, index) => {
                                  return (
                                    <Select.Option
                                      value={JSON.stringify({
                                        _id: item?._id,
                                        Name: item?.Name,
                                      })}
                                    >
                                      {item?.Name}
                                    </Select.Option>
                                  );
                                })} */}
                                {sprintdata?.map((item, index) => {
                                  return (
                                    <Select.Option
                                      key={item?._id}
                                      value={JSON.stringify({
                                        _id: item?._id,
                                        Name: item?.Name,
                                      })}
                                    >
                                      {item?.Name}
                                    </Select.Option>
                                  );
                                })}
                              </Select>
                              {/* {console.log(sprintdata?.Description, 1149)} */}
                            </Form.Item>
                          </MDBCol>
                        </div>

                        <div className="mx-0">
                          <MDBCol className="mt-20 task-common custom-task-common">
                            {/* <span className="TitleStyle left-col-description">
                              Assigned Date
                            </span>
                            <p style={{ margin: "0 0 0 15px" }}> */}
                            {/* {taskData.Assigned_date !== '-'
                                ? taskData.Assigned_date
                                  ? JSON.stringify(
                                    new Date(taskData.Assigned_date),
                                  )
                                    .slice(1, 11)
                                    .split('-')
                                    .reverse()
                                    .join('-')
                                  : '-'
                                : '-'} */}
                            {/* {taskData.Assigned_date !== "-"
                                ? moment(taskData.Assigned_date).format(
                                    "DD-MM-YYYY"
                                  )
                                : "-"}
                            </p> */}

                            {/* <Form.Item
                style={{ marginTop: "-5px" }}
                label="Assigned Date"
                name="assignedDate"

                // rules={[
                //   {
                //     required: true,
                //     message: "Please Select Assigned Date!",
                //   },
                // ]}
                // initialValue={taskData.Assigned_date}
              > */}
                            <span className="TitleStyle left-col-description ">
                              Assigned Date
                            </span>
                            <DatePicker
                            className="my-date-picker-view"

                              format={"DD/MM/YYYY"}
                              onChange={(e) => {
                                handleAssignedDateChange(e);
                              }}
                              value={taskData.Assigned_date && moment(taskData.Assigned_date)}
                              // defaultValue={moment(taskData.Assigned_date)}
                              // disabled ="false"
                              disabledDate={(current) => current && current < moment().startOf('day')}
                            />
                            {/* </Form.Item> */}
                          </MDBCol>

                          <MDBCol className="mt-20 task-common custom-task-common">
                            <span className="TitleStyle left-col-description">
                              Due Date
                            </span>
                            <DatePicker
                            className="my-date-picker-view"
                              format={"DD/MM/YYYY"}
                              onChange={(e) => {
                                handleDueDateChange(e);
                              }}
                              value={taskData.Due_date &&  moment(taskData.Due_date)}
                              disabledDate={(current) => current && current < moment().startOf('day')}
                              
                              // defaultValue={moment(taskData.Due_date)}
                              // disabled ="false"
                            />
                            {/* <p style={{ margin: "0 0 0 15px" }}> */}
                            {/* {taskData.Assigned_date !== '-'
                                ? taskData.Assigned_date
                                  ? JSON.stringify(
                                    new Date(taskData.Assigned_date),
                                  )
                                    .slice(1, 11)
                                    .split('-')
                                    .reverse()
                                    .join('-')
                                  : '-'
                                : '-'} */}

                            {/* {taskData.Due_date !== "-"
                                ? moment(taskData.Due_date).format("DD-MM-YYYY")
                                : "-"} */}
                            {/* </p> */}
                          </MDBCol>
                        </div>

                        {/* <MDBRow className="mx-0">
                      <MDBCol size="12" className="mt-20">
                        <span className="TitleStyle"></span>
                        <p>
                          {taskData.Creater_id
                            ? `${taskData.Creater_id.First_name} ${taskData.Creater_id.Last_name}`
                            : '-'}
                        </p>
                      </MDBCol>
                    </MDBRow> */}

                        <div className="mx-0">
                          <MDBCol size="12" className="mt-20 task-common">
                            <p
                              className="TitleStyle left-col-description"
                              style={{ margin: "0px" }}
                            >
                              Task Duration{" "}
                              <span
                                style={{ fontSize: "14px", display: "block" }}
                              >
                                (In Hour)
                              </span>
                            </p>
                            <h5
                              style={{ margin: "0 0 0 15px", fontSize: "17px" }}
                            >
                              {/* {taskData.Task_duration &&
                              taskData.Task_duration != "undefined" &&
                              taskData.Task_duration !== "" &&
                              taskData.Task_duration !== "-"
                                ? taskData.Task_duration + " hour"
                                : "-"}{" "} */}
                              {/* <input type="number" value={taskData?.Task_duration} /> */}
                              <Form form={form} layout="vertical">
                                <Form.Item
                                  className="select-pro-task-ass"
                                  name="taskDuration"
                                  initialValue={taskData?.Task_duration}
                                >
                                  {/* <Input type="number" /> */}
                                  <Input type="number" onChange={(e) => handleTaskDurationChange(e.target.value)} />
                                </Form.Item>
                              </Form>
                            </h5>
                          </MDBCol>

                          {/* <MDBCol
                          
                            label="Task Duration ( in hour, 30min = 0.5 )"
                            name="taskDuration"
                            initialValue={taskData?.Task_duration}
                            defaultValue={moment(taskData?.Assigned_date)}
                          >
                             <p
                              className="TitleStyle left-col-description"
                              style={{ margin: "0px" }}
                            >
                              <span
                                style={{ fontSize: "14px", display: "block" }}
                                >
                                Task Duration{" "}
                                (In Hour)
                              </span>
                          <Input type="number" />
                            </p>
                          </MDBCol> */}
                        </div>

                        {/* <div
                          style={{ margin: '15px 0 0 0' }}
                          className="TitleStyle"
                        >
                          Historysfwdg
                        </div> */}
                        {/* <div
                          className="d-flex align-items-start bg-light scrollbar"
                          id="style-7"
                          style={{
                            fontWeight: 2,
                            overflowY: 'scroll',
                            maxHeight: '170px',
                            minHeight: '143px',
                          }}
                        >
                          <div className="force-overflow">
                            <ul style={{ listStylePosition: 'outside' }}>
                              {historyList &&
                                historyList?.map((item, index) => (
                                  <>
                                    <li
                                      style={{ fontSize: '13px' }}
                                      className="historyDetail"
                                    >
                                      {item.Updated_by &&
                                        `${item.Updated_by} Updated ${
                                          item.Action
                                        } ${
                                          item.Create_at
                                            ? JSON.stringify(
                                                new Date(
                                                  item.Create_at
                                                ).toLocaleString("en-US", {
                                                  hour12: false,
                                                })
                                              )
                                                .slice(1, 18)
                                                .split("-")
                                                .reverse()
                                                .join("-")
                                            : ""
                                        }`}
                                      {item.Created_by &&
                                        `${item.Created_by} ${item.Action} ${
                                          item.Create_at
                                            ? JSON.stringify(
                                                new Date(
                                                  item.Create_at
                                                ).toLocaleString("en-US", {
                                                  hour12: false,
                                                })
                                              )
                                                .slice(1, 18)
                                                .split("-")
                                                .reverse()
                                                .join("-")
                                            : ""
                                        }`}
                                    </li>
                                  </>
                                ))}
                            </ul>
                          </div>
                        </div> */}
                      </MDBCol>
                    </MDBRow>

                    {taskData.Document?.length > 0 ? (
                      <>
                        <span className="TitleStyle">Attechment</span>
                        <MDBRow style={{ marginBottom: "10px" }} classNamemx-0>
                          <MDBCol size="12" className="mt-20">
                            <div className="image">
                              {/* <Image.PreviewGroup> */}
                              {taskData.Document && (
                                <ImageDemo
                                  element={imageUrl + taskData.Document}
                                />
                              )}
                              {/* </Image.PreviewGroup> */}
                            </div>
                          </MDBCol>
                        </MDBRow>
                      </>
                    ) : (
                      ""
                    )}
                  </div>

                  {/* <div style={{ width: "65%", float: "left" }}> */}
                  {/* Comment Section */}

                  {/* Uves */}

                  {/* </div> */}

                  {/* <div style={{ width: "35%", float: "right" }}> */}
                  {/* <MDBRow style={{ marginLeft: '25px' }}>
                  {taskData.Document != '' ? (
                    <MDBCol size="12" className="mt-20">
                      <div className="TitleStyle uploadFile"> Attachments </div>
                    </MDBCol>
                  ) : (
                    ''
                  )}
                  {taskData.Document != ''
                    ? taskData.Document?.map((item) => (
                      <MDBRow>
                        <MDBCol size="12" className=" mt-20">
                          <a
                            style={{
                              lineHeight: '1',
                              marginTop: '13px',
                              width: '100%',
                              borderRadius: '7px',
                            }}
                            target="_blank"
                            href={item ? item : '#'}
                            className="btn btn-light attenchment "
                            tabindex="-1"
                          // role="button"
                          // aria-disabled="true"
                          >
                            {item}
                          </a>
                        </MDBCol>
                      </MDBRow>
                    ))
                    : ''}
                </MDBRow> */}
                  {/* <MDBRow style={{ marginLeft: '25px' }}> */}
                  <MDBRow style={{ marginBottom: "10px", marginLeft: "25px" }}>
                    <MDBCol size="12" className="mt-20">
                      {/* <AddBug setFlage1={setFlage1} flage1={flage1}/> */}
                      {/* {taskData && taskData._id && !taskData.Parent_task_id && (
                        <div className="d-grid gap-2 col-12 mx-auto">
                          <AddBug taskData={taskData} />
                          <MDBBtn
                            className="HoverBugListBtn"
                            rounded
                            outline
                            color="info"
                            style={{
                              padding: "6px 85px",
                              marginBottom: "30px",
                            }}
                            onClick={() =>
                              history.push(`/task/${taskData._id}/BugList`)
                            }
                          >
                            <MDBIcon fas icon="spider" /> Show Bug List
                          </MDBBtn>
                        </div>
                      )} */}
                      {/* <hr /> */}
                    </MDBCol>
                  </MDBRow>
                  {/* </MDBRow> */}
                  {/* </div> */}
                </MDBTypography>
              </MDBCardBody>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default View;
