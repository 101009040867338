import React, { useEffect, useState } from "react";
import { MDBDataTableV5, MDBCard } from "mdbreact";
// added 09-01-2024
import { Select, DatePicker, Form, message } from "antd";
import { Menu, Dropdown } from "antd";
import AxiosInstance from "../../../../service/AxiosInstance";
import LeaveForm from "./LeaveForm";
import moment from "moment";
import Permission from "../../Permission";
import { EllipseText } from "../../../../components/common/Coomon";
import confirm from "antd/lib/modal/confirm";
import { MDBCol, MDBRow } from "mdb-react-ui-kit";
import { toast } from "react-toastify";
import { Collapse } from "antd";

import * as XLSX from "xlsx";
import * as FileSaver from "file-saver";
import { DownloadOutlined } from "@ant-design/icons";

// main component
const List = (props) => {
  let userData = JSON.parse(localStorage.getItem("userdata"));
  const Position = userData[0].Position;
  const [totalLeave, setTotalLeave] = useState(0);
  const permission = Permission();
  const [apiData, setapiData] = useState([]);
  const [leaveLogs, setLeaveLogs] = useState([]);
  const [leave, setLeave] = useState({});
  const [currentMonthTotal, setCurrentMonthTotalu] = useState(0);
  const [last30DaysTotal, setLast30DaysTotal] = useState(0);
  const [sickLeaveTotalDays, setSickLeaveTotalDays] = useState(0);
  const [casualLeaveTotalDays, setCasualLeaveTotalDays] = useState(0);
  const [quarter, setQuarter] = React.useState("0");

  // 12-01-2024 => changes =>16-01-2024
  const [firstQuaterData, setFirstQuarterData] = React.useState([]);

  // set up for the search basis for all quarter basis  12-01-2024 => changes => 16-01-2024
  const [searchFirstQTRText, setSearchFirstQTRText] = React.useState("");

  // added => 24-01-2024
  const [leaveStatus, setLeaveStatus] = React.useState([]);
  const [leaveMonth, setLeaveMonth] = React.useState("");
  const { Option } = Select;

  const fileType =
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
  const fileExtension = ".xlsx";

  // 12-01-2024
  const initialDataTable = {
    columns: [
      {
        label: "Leave Dates",
        field: "LeaveStartDate",
        width: 200,
        sort: "disabled",
      },

      {
        label: "Leave Type ",
        field: "leave_type",
        sort: "disabled",
        width: 100,
      },
      {
        label: "Status",
        field: "status",
        sort: "disabled",
        width: 100,
      },
      {
        label: "Requested By",
        field: "Created_by",
        sort: "disabled",
        width: 100,
      },

      {
        label: "Action Taken On",
        field: "action_on",
        sort: "disabled",
        width: 100,
      },
      {
        label: "Leave Note",
        field: "ReasonElement",
        sort: "disabled",
        width: 100,
      },
      {
        label: "Action",
        field: "action",
        sort: "disabled",
        width: 100,
      },
    ],
    rows: [],
  };

  // added 09-04-2024
  const { RangePicker } = DatePicker;

  // 09-01-2024 added
  const [searchDateRangeWiseLeaves, setSearchDateRangeWiseLeaves] = useState();

  const reRenderFun = () => {
    getLeaves();
  };

  useEffect(() => {
    getLeaves();
  }, []);

  // give range between two dates
  const getDateRange = (firstDate, lastDate) => {
    if (
      moment(firstDate, "YYYY-MM-DD").isSame(
        moment(lastDate, "YYYY-MM-DD"),
        "day"
      )
    ) {
      return [firstDate];
    }

    let date = moment(firstDate).format("YYYY-MM-DD");
    const dates = [date];

    while (moment(date).isBefore(lastDate)) {
      date = moment(date).add(1, "day").format("YYYY-MM-DD");

      if (moment(lastDate).format("YYYY-MM-DD") >= date) {
        dates.push(date);
      }
    }

    return dates;
  };

  // getCount of days between two dates and exclude SAT & SUN
  const getCountDays = (firstDate, lastDate) => {
    let totalDiffDays;
    const newStartDate = moment(firstDate);
    const newEndDate = moment(lastDate);

    // check differenece of days from start & end Date
    let dateRange = getDateRange(newStartDate, newEndDate);
    let weekendDaysCount = 0;
    for (let i = 0; i < dateRange?.length; i++) {
      let weekDays = moment(dateRange[i]).day();
      if (weekDays == 6 || weekDays == 0) {
        weekendDaysCount = weekendDaysCount + 1;
      }
    }
    let diffBetweenDates = newEndDate.diff(newStartDate, "days");
    totalDiffDays = diffBetweenDates - weekendDaysCount + 1;

    return totalDiffDays;
  };

  const leaveDates = (items) => {
    const dateArray = items.Leave_Date_to_Date.split(",");
    const newStartDate = dateArray ? new Date(dateArray[0]) : null;
    const newEndDate = dateArray ? new Date(dateArray[1]) : null;
    const halfStart = items.Leave_half_from;
    const halfTo = items.Leave_half_to;

    let appliedDate = "";
    let days = "";
    if (
      moment(newStartDate, "DD/MM/YYYY").format("MMM DD, YYYY") ==
        moment(newEndDate, "DD/MM/YYYY").format("MMM DD, YYYY") &&
      halfStart == "First Half" &&
      halfTo == "Second Half"
    ) {
      appliedDate = moment(newStartDate, "DD/MM/YYYY").format("MMM DD, YYYY");
      days = "1 Day";
    } else {
      appliedDate =
        moment(newStartDate, "DD/MM/YYYY").format("MMM DD, YYYY") +
        " - " +
        moment(newEndDate, "DD/MM/YYYY").format("MMM DD, YYYY");
      if (
        moment(newStartDate, "DD/MM/YYYY").format("MMM DD, YYYY") ==
        moment(newEndDate, "DD/MM/YYYY").format("MMM DD, YYYY")
      ) {
        appliedDate = moment(newStartDate, "DD/MM/YYYY").format("MMM DD, YYYY");
        if (halfStart == "Second Half") {
          days = "0.5 Day (Second Half)";
        } else {
          days = "0.5 Day (First Half)";
        }
      } else {
        let daysLeft = getCountDays(newStartDate, newEndDate);

        // let daysLeft =
        //   moment(newEndDate, "DD/MM/YYYY").diff(
        //     moment(newStartDate, "DD/MM/YYYY"),
        //     "days"
        //   ) + 1;
        if (halfStart == "First Half" && halfTo == "Second Half") {
          days = daysLeft + " Days";
        } else {
          if (halfStart == "Second Half") {
            daysLeft -= 0.5;
          }
          if (halfTo == "First Half") {
            daysLeft -= 0.5;
          }
          days = daysLeft + " Days (" + halfStart + " - " + halfTo + ")";
        }
      }
    }

    return (
      <>
        {appliedDate} <span className="info">{days}</span>{" "}
      </>
    );
  };

  function getLeaves() {
    const urlencoded = new URLSearchParams();
    urlencoded.append("id", props.id);
    let leaveUrlCoded = new URLSearchParams();
    leaveUrlCoded.append("id", userData[0]?._id);
    leaveUrlCoded.append("quarter", "");
    leaveUrlCoded.append("year", moment().format("YYYY"));

    (props.id
      ? AxiosInstance.post("auth/leaves", urlencoded)
      : // : AxiosInstance.post("auth/leavesbyquater", leaveUrlCoded)
        AxiosInstance.post("auth/leaves")
    ).then(async (res) => {
      if (res.data.status == 1) {
        let firstQuarterResult = "";
        let secondQuarterResult = "";
        let thirdQuarterResult = "";
        let fourthQuarterResult = "";
        console.log(res.data.data, "res.data.data");
        const result = res.data.data.map((items) => {
          const dateArray = items.Leave_Date_to_Date.split(",");
          const newStartDate = new Date(dateArray[0]);
          const newEndDate = new Date(dateArray[1]);
          const creatdAt = (
            <p>{moment(items?.Create_at).format("DD/MM/YYYY")}</p>
          );
          const halfStart =
            items.Leave_half_from == "Second Half" ? "(First Half)" : "";
          const halfTo =
            items.Leave_half_to == "Second Half" ? "(First Half)" : "";
          //   items.Create_at = creatdAt;
          items.LeaveStartDate = leaveDates(items);
          items.status = (
            <p>
              {items.Status}
              <span className="info">
                {items.Action_by &&
                  " By " +
                    items?.Action_by?.First_name +
                    " " +
                    items?.Action_by?.Middle_name +
                    " " +
                    items?.Action_by?.Last_name}
              </span>
            </p>
          );
          items.action_on = items.Action_on
            ? moment(items?.Action_on).format("MMM DD, YYYY hh:mm a")
            : "-";
          items.leave_type = (
            <p>
              {items.Leave_type}{" "}
              <span className="info">
                Requested on{" "}
                {moment(items?.Create_at).format("MMM DD, YYYY HH:mm a")}
              </span>
            </p>
          );
          items.action = actions(items);
          items.ReasonElement = <EllipseText text={items.Reason} />;

          return items;
        });
        setFirstQuarterData(result);

        // here distribute the quarter wise data...
        // res.data.data?.map((el) => {

        //   if (el.quarter === 1) {
        //     firstQuarterResult = el.data.map((items) => {
        //       const dateArray = items.Leave_Date_to_Date.split(",");
        //       const newStartDate = new Date(dateArray[0]);
        //       const newEndDate = new Date(dateArray[1]);
        //       const creatdAt = (
        //         <p>{moment(items?.Create_at).format("DD/MM/YYYY")}</p>
        //       );
        //       const halfStart =
        //         items.Leave_half_from == "Second Half" ? "(First Half)" : "";
        //       const halfTo =
        //         items.Leave_half_to == "Second Half" ? "(First Half)" : "";
        //       //   items.Create_at = creatdAt;
        //       items.LeaveStartDate = leaveDates(items);
        //       items.status = (
        //         <p>
        //           {items.Status}
        //           <span className="info">
        //             {items.Action_by &&
        //               " By " +
        //                 items?.Action_by?.First_name +
        //                 " " +
        //                 items?.Action_by?.Middle_name +
        //                 " " +
        //                 items?.Action_by?.Last_name}
        //           </span>
        //         </p>
        //       );
        //       items.action_on = items.Action_on
        //         ? moment(items?.Action_on).format("MMM DD, YYYY hh:mm a")
        //         : "-";
        //       items.leave_type = (
        //         <p>
        //           {items.Leave_type}{" "}
        //           <span className="info">
        //             Requested on{" "}
        //             {moment(items?.Create_at).format("MMM DD, YYYY HH:mm a")}
        //           </span>
        //         </p>
        //       );
        //       items.action = actions(items);
        //       items.ReasonElement = <EllipseText text={items.Reason} />;

        //       return items;
        //     });
        //   } else if (el.quarter === 2) {
        //     secondQuarterResult = el.data.map((items) => {
        //       const dateArray = items.Leave_Date_to_Date.split(",");
        //       const newStartDate = new Date(dateArray[0]);
        //       const newEndDate = new Date(dateArray[1]);
        //       const creatdAt = (
        //         <p>{moment(items?.Create_at).format("DD/MM/YYYY")}</p>
        //       );
        //       const halfStart =
        //         items.Leave_half_from == "Second Half" ? "(First Half)" : "";
        //       const halfTo =
        //         items.Leave_half_to == "Second Half" ? "(First Half)" : "";
        //       //   items.Create_at = creatdAt;
        //       items.LeaveStartDate = leaveDates(items);
        //       items.status = (
        //         <p>
        //           {items.Status}
        //           <span className="info">
        //             {items.Action_by &&
        //               " By " +
        //                 items?.Action_by?.First_name +
        //                 " " +
        //                 items?.Action_by?.Middle_name +
        //                 " " +
        //                 items?.Action_by?.Last_name}
        //           </span>
        //         </p>
        //       );
        //       items.action_on = items.Action_on
        //         ? moment(items?.Action_on).format("MMM DD, YYYY hh:mm a")
        //         : "-";
        //       items.leave_type = (
        //         <p>
        //           {items.Leave_type}{" "}
        //           <span className="info">
        //             Requested on{" "}
        //             {moment(items?.Create_at).format("MMM DD, YYYY HH:mm a")}
        //           </span>
        //         </p>
        //       );
        //       items.action = actions(items);
        //       items.ReasonElement = <EllipseText text={items.Reason} />;

        //       return items;
        //     });
        //     // setFirstQuarterData(quater2);
        //   } else if (el.quarter === 3) {
        //     thirdQuarterResult = el.data.map((items) => {
        //       const dateArray = items.Leave_Date_to_Date.split(",");
        //       const newStartDate = new Date(dateArray[0]);
        //       const newEndDate = new Date(dateArray[1]);
        //       const creatdAt = (
        //         <p>{moment(items?.Create_at).format("DD/MM/YYYY")}</p>
        //       );
        //       const halfStart =
        //         items.Leave_half_from == "Second Half" ? "(First Half)" : "";
        //       const halfTo =
        //         items.Leave_half_to == "Second Half" ? "(First Half)" : "";
        //       //   items.Create_at = creatdAt;
        //       items.LeaveStartDate = leaveDates(items);
        //       items.status = (
        //         <p>
        //           {items.Status}
        //           <span className="info">
        //             {items.Action_by &&
        //               " By " +
        //                 items?.Action_by?.First_name +
        //                 " " +
        //                 items?.Action_by?.Middle_name +
        //                 " " +
        //                 items?.Action_by?.Last_name}
        //           </span>
        //         </p>
        //       );
        //       items.action_on = items.Action_on
        //         ? moment(items?.Action_on).format("MMM DD, YYYY hh:mm a")
        //         : "-";
        //       items.leave_type = (
        //         <p>
        //           {items.Leave_type}{" "}
        //           <span className="info">
        //             Requested on{" "}
        //             {moment(items?.Create_at).format("MMM DD, YYYY HH:mm a")}
        //           </span>
        //         </p>
        //       );
        //       items.action = actions(items);
        //       items.ReasonElement = <EllipseText text={items.Reason} />;

        //       return items;
        //     });
        //     // setFirstQuarterData(quater3);
        //   } else if (el.quarter === 4) {
        //     fourthQuarterResult = el.data.map((items) => {
        //       const dateArray = items.Leave_Date_to_Date.split(",");
        //       const newStartDate = new Date(dateArray[0]);
        //       const newEndDate = new Date(dateArray[1]);
        //       const creatdAt = (
        //         <p>{moment(items?.Create_at).format("DD/MM/YYYY")}</p>
        //       );
        //       const halfStart =
        //         items.Leave_half_from == "Second Half" ? "(First Half)" : "";
        //       const halfTo =
        //         items.Leave_half_to == "Second Half" ? "(First Half)" : "";
        //       //   items.Create_at = creatdAt;
        //       items.LeaveStartDate = leaveDates(items);
        //       items.status = (
        //         <p>
        //           {items.Status}
        //           <span className="info">
        //             {items.Action_by &&
        //               " By " +
        //                 items?.Action_by?.First_name +
        //                 " " +
        //                 items?.Action_by?.Middle_name +
        //                 " " +
        //                 items?.Action_by?.Last_name}
        //           </span>
        //         </p>
        //       );
        //       items.action_on = items.Action_on
        //         ? moment(items?.Action_on).format("MMM DD, YYYY hh:mm a")
        //         : "-";
        //       items.leave_type = (
        //         <p>
        //           {items.Leave_type}{" "}
        //           <span className="info">
        //             Requested on{" "}
        //             {moment(items?.Create_at).format("MMM DD, YYYY HH:mm a")}
        //           </span>
        //         </p>
        //       );
        //       items.action = actions(items);
        //       items.ReasonElement = <EllipseText text={items.Reason} />;

        //       return items;
        //     });
        //     // setFirstQuarterData(quater4);
        //   }
        // });
        // setFirstQuarterData(firstQuarterResult);
        // setSecondQuarterData(secondQuarterResult);
        // setThirdQuarterData(thirdQuarterResult);
        // setFourthQuarterData(fourthQuarterResult);
      }
    });
  }

  const LeaveCount = async () => {
    AxiosInstance.get("auth/leave/leave-count").then((res) => {
      setLast30DaysTotal(res.data.data.LeavesinLast30Days);
      setCurrentMonthTotalu(res.data.data.LeavesInCurrentMonth);
      setTotalLeave(res.data.data.LeaveTotalDays);
      setSickLeaveTotalDays(res.data.data.SickLeaveTotalDays);
      setCasualLeaveTotalDays(res.data.data.CasualLeaveTotalDays);
    });
  };

  // added 12-01-2024
  useEffect(() => {
    LeaveCount();
  }, []);

  const approve = (items, status) => {
    const formData = new URLSearchParams();

    formData.append("Status", status);
    formData.append("id", items._id);

    AxiosInstance.post("auth/leave/action", formData)
      .then((res) => {
        if (res.data.status == 1) {
          // toast.success(`Leaved ${status} Successfull`);
          message.success(`Leaved ${status} Successfull`);
          getLeaves();
        }
      })
      .catch((err) => console.log(err, "err"));
  };
  const handleClick = (items, status) => {
    setLeave(items);

    if (status == "cancel") {
      confirm({
        title: "Are you sure Cancel this leave?",

        content: items?.Reason,
        okText: "Yes",
        okType: "danger",

        cancelText: "No",

        onOk() {
          approve(items, "Cancelled");
        },

        onCancel() {
          console.log("Cancel");
        },
      });
    } else {
      getLeavesLog(items._id);
    }
  };
  function getLeavesLog(id) {
    AxiosInstance.get(`auth/leaves/leaveLogs?id=${id}`).then(async (res) => {
      setLeaveLogs(res.data.data);
      showDrawer();
    });
  }
  const [visible, setVisible] = useState(false);
  const showDrawer = () => {
    setVisible(true);
  };

  const onClose = () => {
    setVisible(false);
  };

  const actionMenu = (items, listAction) => (
    <Menu onClick={(e) => handleClick(items, e.key)} items={listAction} />
  );
  const actions = (items) => {
    let listAction = [
      {
        key: "logs",
        label: "Logs",
        items: items,
      },
    ];
    if (items.Status !== "Cancelled") {
      listAction.push({
        key: "cancel",
        label: "Cancel",
        items: items,
      });
    }

    const leaveDatePassed = moment(
      items.Leave_Date_to_Date.split(",")[1]
    ).isBefore(moment());
    if (items.Status !== "Cancelled" && !leaveDatePassed) {
      listAction.push({
        key: "cancel",
        label: "Cancel",
        items: items,
      });
    }

    return (
      <>
        <Dropdown.Button
          overlay={actionMenu(items, listAction)}
          // menu={actionMenu(items, listAction)}
        ></Dropdown.Button>
      </>
    );
  };

  // added => start => 12-01-2024
  // when the user search first quarter leaves then used..
  const handleSearchFirstQTRLeaves = React.useCallback(
    (e) => {
      const { value } = e.target;
      setSearchFirstQTRText(value);
    },
    [setSearchFirstQTRText]
  );

  // added 09-01-2024
  // when the user change the date range wise searching then used....
  const handleChangeDateRangeWiseLeaves = React.useCallback(
    (data) => {
      if (data !== null) {
        return setSearchDateRangeWiseLeaves(data);
      }
      return setSearchDateRangeWiseLeaves();
    },
    [setSearchDateRangeWiseLeaves]
  );

  // when the export csv then used...
  const checkAction = () => {
    exportAll(firstQuaterData, "Leave-Report");
  };
  const exportAll = (csvData, fileName) => {
    const ws = XLSX.utils.json_to_sheet(csvData);
    delete ws["H1"];
    const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const data = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(data, fileName + fileExtension);
  };

  return (
    <>
      <div>
        <MDBCard className="page-content">
          <div className="total-leave-details">
            <MDBRow className="total-leaves-row">
              <MDBCol size="md-8">
                <MDBRow>
                  <MDBCol className="leave-counter-no">
                    <div className="leave-counter-box">
                      <div className="leave-lable">This Month</div>
                      <div className="leave-digit">{currentMonthTotal}</div>
                    </div>
                  </MDBCol>
                  <MDBCol className="leave-counter-no">
                    <div className="leave-counter-box">
                      <div className="leave-lable">Last 30days</div>
                      <div className="leave-digit">{last30DaysTotal}</div>
                    </div>
                  </MDBCol>
                  <MDBCol className="leave-counter-no">
                    <div className="leave-counter-box">
                      <div className="leave-lable">Total Leaves</div>
                      <div className="leave-digit">{totalLeave}</div>
                    </div>
                  </MDBCol>

                  <MDBCol className="leave-counter-no">
                    <div className="leave-counter-box">
                      <div className="leave-lable">Total Casual Leave</div>
                      <div className="leave-digit">
                        {`${casualLeaveTotalDays}`}/7
                      </div>
                    </div>
                  </MDBCol>

                  <MDBCol className="leave-counter-no">
                    <div className="leave-counter-box">
                      <div className="leave-lable">Total Sick Leave</div>
                      <div className="leave-digit">
                        {`${sickLeaveTotalDays}`}/5
                      </div>
                    </div>
                  </MDBCol>

                  <MDBCol className="leave-counter-no">
                    <div className="leave-counter-box">
                      <div className="leave-lable">Unpaid Leave</div>
                      <div className="leave-digit">0</div>
                    </div>
                  </MDBCol>
                </MDBRow>
              </MDBCol>
              <MDBCol size="md-4">
                <div className="apply-leave-btn-flex">
                  <button
                    type="button"
                    className="ant-btn ant-btn-primary pms-same-btn leave-btn"
                    onClick={() => checkAction()}
                  >
                    <DownloadOutlined className="download-icon" />
                    Export
                  </button>
                  <label className="start-list"></label>
                  <div className="start-list">
                    {!props.id && <LeaveForm propData={reRenderFun} />}
                  </div>
                </div>
              </MDBCol>
            </MDBRow>
          </div>
          <hr />
          <div className="scrool-set mobile-scroll">
            <div className="leave-serach-row-main">
              <MDBRow>
                <MDBCol size="md-3" className="leave-report-inputs">
                  <Select
                    placeholder="Status"
                    mode="multiple"
                    // style={{ paddingLeft: '10px', width: '70%' }}
                    className="select-dropdown leave-4"
                    onChange={(e) => {
                      setLeaveStatus(e);
                    }}
                  >
                    <Option value="Pending">Pending</Option>
                    <Option value="Approved">Approved</Option>
                    <Option value="Conditional Approved">
                      Conditional Approved
                    </Option>
                    <Option value="Unapproved">Unapproved</Option>
                    <Option value="Rejected">Rejected</Option>
                  </Select>
                </MDBCol>
                <MDBCol size="md-3" className="leave-report-inputs">
                  <Form.Item>
                    <Select
                      onChange={(value) => {
                        // onFinish(value);
                        setLeaveMonth(value);
                      }}
                      name="month"
                      placeholder="Please select a month"
                    >
                      <Option value="01">January</Option>
                      <Option value="02">February</Option>
                      <Option value="03">March</Option>
                      <Option value="04">April</Option>
                      <Option value="05">May</Option>
                      <Option value="06">June</Option>
                      <Option value="07">July</Option>
                      <Option value="08">August</Option>
                      <Option value="09">September</Option>
                      <Option value="10">October</Option>
                      <Option value="11">November</Option>
                      <Option value="12">December</Option>
                    </Select>
                  </Form.Item>
                </MDBCol>
                <MDBCol size="md-3 leave-report-inputs">
                  {/* <label
                  className="start-list"
                  // style={{ fontWeight: '600' }}
                >
                  Start Date
                </label> */}
                  {/* added 09-01-2024 */}
                  <RangePicker
                    format={"DD/MM/YYYY"}
                    onChange={(data) => handleChangeDateRangeWiseLeaves(data)}
                  />
                </MDBCol>
                <MDBCol size="md-3 leave-report-inputs">
                  {/* <label className="start-list">search</label> */}
                  <input
                    type="text"
                    className="cust-form-control"
                    placeholder="Search"
                    onChange={handleSearchFirstQTRLeaves}
                  />
                </MDBCol>
              </MDBRow>
            </div>
          </div>
          <div className="collapse-outer-main">
            <MDBDataTableV5
              hover
              entriesOptions={[10, 20, 30]}
              entries={10}
              pagesAmount={4}
              data={{
                ...initialDataTable,
                rows:
                  // only status wise
                  leaveStatus?.length > 0 &&
                  leaveMonth === "" &&
                  searchDateRangeWiseLeaves === undefined &&
                  searchFirstQTRText.length <= 0
                    ? firstQuaterData.filter((res) =>
                        leaveStatus?.includes(res.Status)
                      )
                    : // status and month wise
                    leaveStatus?.length > 0 &&
                      leaveMonth !== "" &&
                      searchDateRangeWiseLeaves === undefined &&
                      searchFirstQTRText.length <= 0
                    ? firstQuaterData.filter(
                        (res) =>
                          leaveStatus?.includes(res.Status) &&
                          (moment(res.Leave_Date_to_Date.split(",")[0]).format(
                            "MM"
                          ) === leaveMonth ||
                            moment(res.Leave_Date_to_Date.split(",")[1]).format(
                              "MM"
                            ) === leaveMonth)
                      )
                    : // status and date wise
                    leaveStatus?.length > 0 &&
                      searchDateRangeWiseLeaves !== undefined &&
                      searchDateRangeWiseLeaves?.length > 0 &&
                      leaveMonth === "" &&
                      searchFirstQTRText.length <= 0
                    ? firstQuaterData.filter(
                        (res) =>
                          leaveStatus?.includes(res.Status) &&
                          moment(res.Leave_Date_to_Date.split(",")[0]).format(
                            "MM/DD/YYYY"
                          ) >=
                            moment(searchDateRangeWiseLeaves[0]._d).format(
                              "MM/DD/YYYY"
                            ) &&
                          moment(res.Leave_Date_to_Date.split(",")[1]).format(
                            "MM/DD/YYYY"
                          ) <=
                            moment(searchDateRangeWiseLeaves[1]._d).format(
                              "MM/DD/YYYY"
                            )
                      )
                    : // status and searching wise
                    leaveStatus?.length > 0 &&
                      searchFirstQTRText.length > 0 &&
                      searchDateRangeWiseLeaves === undefined
                    ? firstQuaterData.filter(
                        (res) =>
                          leaveStatus?.includes(res.Status) &&
                          res.Created_by.toLowerCase().includes(
                            searchFirstQTRText.toLowerCase()
                          )
                      )
                    : // when the user status , month and date wise
                    leaveStatus?.length > 0 &&
                      leaveMonth !== "" &&
                      searchDateRangeWiseLeaves !== undefined &&
                      searchDateRangeWiseLeaves?.length > 0 &&
                      searchFirstQTRText.length <= 0
                    ? firstQuaterData.filter(
                        (res) =>
                          leaveStatus?.includes(res.Status) &&
                          (moment(res.Leave_Date_to_Date.split(",")[0]).format(
                            "MM"
                          ) === leaveMonth ||
                            moment(res.Leave_Date_to_Date.split(",")[1]).format(
                              "MM"
                            ) === leaveMonth) &&
                          moment(res.Leave_Date_to_Date.split(",")[0]).format(
                            "MM/DD/YYYY"
                          ) >=
                            moment(searchDateRangeWiseLeaves[0]._d).format(
                              "MM/DD/YYYY"
                            ) &&
                          moment(res.Leave_Date_to_Date.split(",")[1]).format(
                            "MM/DD/YYYY"
                          ) <=
                            moment(searchDateRangeWiseLeaves[1]._d).format(
                              "MM/DD/YYYY"
                            )
                      )
                    : // status ,month and text wise search
                    leaveStatus?.length > 0 &&
                      leaveMonth !== "" &&
                      searchDateRangeWiseLeaves === undefined &&
                      searchFirstQTRText.length > 0
                    ? firstQuaterData.filter(
                        (res) =>
                          leaveStatus?.includes(res.Status) &&
                          (moment(res.Leave_Date_to_Date.split(",")[0]).format(
                            "MM"
                          ) === leaveMonth ||
                            moment(res.Leave_Date_to_Date.split(",")[1]).format(
                              "MM"
                            ) === leaveMonth) &&
                          res.Created_by.toLowerCase().includes(
                            searchFirstQTRText.toLowerCase()
                          )
                      )
                    : // status ,date and text wise search
                    leaveStatus?.length > 0 &&
                      leaveMonth === "" &&
                      searchDateRangeWiseLeaves !== undefined &&
                      searchDateRangeWiseLeaves?.length > 0 &&
                      searchFirstQTRText.length > 0
                    ? firstQuaterData.filter(
                        (res) =>
                          leaveStatus?.includes(res.Status) &&
                          moment(res.Leave_Date_to_Date.split(",")[0]).format(
                            "MM/DD/YYYY"
                          ) >=
                            moment(searchDateRangeWiseLeaves[0]._d).format(
                              "MM/DD/YYYY"
                            ) &&
                          moment(res.Leave_Date_to_Date.split(",")[1]).format(
                            "MM/DD/YYYY"
                          ) <=
                            moment(searchDateRangeWiseLeaves[1]._d).format(
                              "MM/DD/YYYY"
                            ) &&
                          res.Created_by.toLowerCase().includes(
                            searchFirstQTRText.toLowerCase()
                          )
                      )
                    : // when the search status , month , date and text wise
                    leaveStatus?.length > 0 &&
                      leaveMonth !== "" &&
                      searchDateRangeWiseLeaves !== undefined &&
                      searchDateRangeWiseLeaves?.length > 0 &&
                      searchFirstQTRText.length > 0
                    ? firstQuaterData.filter(
                        (res) =>
                          leaveStatus?.includes(res.Status) &&
                          (moment(res.Leave_Date_to_Date.split(",")[0]).format(
                            "MM"
                          ) === leaveMonth ||
                            moment(res.Leave_Date_to_Date.split(",")[1]).format(
                              "MM"
                            ) === leaveMonth) &&
                          moment(res.Leave_Date_to_Date.split(",")[0]).format(
                            "MM/DD/YYYY"
                          ) >=
                            moment(searchDateRangeWiseLeaves[0]._d).format(
                              "MM/DD/YYYY"
                            ) &&
                          moment(res.Leave_Date_to_Date.split(",")[1]).format(
                            "MM/DD/YYYY"
                          ) <=
                            moment(searchDateRangeWiseLeaves[1]._d).format(
                              "MM/DD/YYYY"
                            ) &&
                          res.Created_by.toLowerCase().includes(
                            searchFirstQTRText.toLowerCase()
                          )
                      )
                    : // only month wise
                    leaveStatus?.length === 0 &&
                      leaveMonth !== "" &&
                      searchDateRangeWiseLeaves === undefined &&
                      searchFirstQTRText.length <= 0
                    ? firstQuaterData.filter(
                        (res) =>
                          // leaveStatus?.includes(res.Status) &&
                          moment(res.Leave_Date_to_Date.split(",")[0]).format(
                            "MM"
                          ) === leaveMonth ||
                          moment(res.Leave_Date_to_Date.split(",")[1]).format(
                            "MM"
                          ) === leaveMonth
                      )
                    : // month and date wise search
                    leaveStatus?.length <= 0 &&
                      leaveMonth !== "" &&
                      searchDateRangeWiseLeaves !== undefined &&
                      searchDateRangeWiseLeaves?.length > 0 &&
                      searchFirstQTRText.length <= 0
                    ? firstQuaterData.filter(
                        (res) =>
                          (moment(res.Leave_Date_to_Date.split(",")[0]).format(
                            "MM"
                          ) === leaveMonth ||
                            moment(res.Leave_Date_to_Date.split(",")[1]).format(
                              "MM"
                            ) === leaveMonth) &&
                          moment(res.Leave_Date_to_Date.split(",")[0]).format(
                            "MM/DD/YYYY"
                          ) >=
                            moment(searchDateRangeWiseLeaves[0]._d).format(
                              "MM/DD/YYYY"
                            ) &&
                          moment(res.Leave_Date_to_Date.split(",")[1]).format(
                            "MM/DD/YYYY"
                          ) <=
                            moment(searchDateRangeWiseLeaves[1]._d).format(
                              "MM/DD/YYYY"
                            )
                      )
                    : // month and serach wise
                    leaveStatus?.length <= 0 &&
                      leaveMonth !== "" &&
                      searchDateRangeWiseLeaves === undefined &&
                      searchFirstQTRText.length > 0
                    ? firstQuaterData.filter(
                        (res) =>
                          (moment(res.Leave_Date_to_Date.split(",")[0]).format(
                            "MM"
                          ) === leaveMonth ||
                            moment(res.Leave_Date_to_Date.split(",")[1]).format(
                              "MM"
                            ) === leaveMonth) &&
                          res.Created_by.toLowerCase().includes(
                            searchFirstQTRText.toLowerCase()
                          )
                      ) // only date wise searching....
                    : leaveStatus?.length <= 0 &&
                      leaveMonth === "" &&
                      searchDateRangeWiseLeaves !== undefined &&
                      searchDateRangeWiseLeaves?.length > 0 &&
                      searchFirstQTRText?.length <= 0
                    ? firstQuaterData.filter(
                        (res) =>
                          moment(res.Leave_Date_to_Date.split(",")[0]).format(
                            "MM/DD/YYYY"
                          ) >=
                            moment(searchDateRangeWiseLeaves[0]._d).format(
                              "MM/DD/YYYY"
                            ) &&
                          moment(res.Leave_Date_to_Date.split(",")[1]).format(
                            "MM/DD/YYYY"
                          ) <=
                            moment(searchDateRangeWiseLeaves[1]._d).format(
                              "MM/DD/YYYY"
                            )
                      )
                    : // date and text search basis
                    leaveStatus?.length <= 0 &&
                      leaveMonth === "" &&
                      searchDateRangeWiseLeaves !== undefined &&
                      searchDateRangeWiseLeaves?.length > 0 &&
                      searchFirstQTRText?.length > 0
                    ? firstQuaterData.filter(
                        (res) =>
                          moment(res.Leave_Date_to_Date.split(",")[0]).format(
                            "MM/DD/YYYY"
                          ) >=
                            moment(searchDateRangeWiseLeaves[0]._d).format(
                              "MM/DD/YYYY"
                            ) &&
                          moment(res.Leave_Date_to_Date.split(",")[1]).format(
                            "MM/DD/YYYY"
                          ) <=
                            moment(searchDateRangeWiseLeaves[1]._d).format(
                              "MM/DD/YYYY"
                            ) &&
                          res.Created_by.toLowerCase().includes(
                            searchFirstQTRText.toLowerCase()
                          )
                      )
                    : // only search basis
                    leaveStatus?.length <= 0 &&
                      leaveMonth === "" &&
                      searchDateRangeWiseLeaves === undefined &&
                      searchFirstQTRText?.length > 0
                    ? firstQuaterData.filter((res) =>
                        res.Created_by.toLowerCase().includes(
                          searchFirstQTRText.toLowerCase()
                        )
                      )
                    : firstQuaterData,
              }}
              searchBottom={false}
              className="leave-tbl p-3"
            />
          </div>
        </MDBCard>
      </div>
    </>
  );
};

export default List;
