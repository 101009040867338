import React, { useState } from "react";
import Header from "./Header";
import { Switch, Route, Redirect, useHistory } from "react-router-dom";
import Footer from "./Footer";
import Sidebar from "./Sidebar";
import "./DefaultLayout.css";
import { Routes } from "../../routes/routes";
import ReactLoader from "../../components/common/ReactLoader";
import { useEffect } from "react";
import jwtDecode from "jwt-decode";

const loading = () => <ReactLoader />;

const DefaultLayout = () => {
  const history = useHistory();
  let isLogedIn = localStorage.getItem("token");
  const [checkInCheckOut, setcheckInCheckOut] = useState(false);

  //Check token expiry and logout
  const isValidToken = (accessToken) => {
    if (!accessToken) return false;

    const decodedToken = jwtDecode(accessToken);
    const currentTime = Date.now() / 1000;
    return decodedToken.exp > currentTime;
  };

  useEffect(() => {
    // Check token expiry every 1 minute
    setInterval(() => {
      const token = localStorage.getItem("token");

      if (!isValidToken(token)) {
        localStorage.removeItem("token");
        localStorage.removeItem("userdata");
        history.push("/login");
      }
    }, 600000); // 600000 = 10 minute
  }, []);

  const [darkMode, setDarkMode] = useState(false);
  const handleModeChange = () => {
    setDarkMode(!darkMode);
    if (darkMode) {
      document.body.classList.add("darkmode-modal");
    } else {
      document.body.classList.remove("darkmode-modal");
    }
  };

  return (
    <div>
      {isLogedIn ? (
        <div className={`flexible-content ${darkMode ? "darkmode" : ""}`}>
          <Sidebar></Sidebar>
          <main id="content">
            <Header
              checkInCheckOut={checkInCheckOut}
              updateMode={() => handleModeChange()}
            />
            {/* <MDBContainer className=""> */}
            <React.Suspense fallback={loading()}>
              <Switch>
                {Routes.map((route, idx) => {
                  return (
                    route.component && (
                      <Route
                        key={idx}
                        path={route.path}
                        exact={route.exact}
                        name={route.name}
                        render={(props) => (
                          <route.component
                            {...props}
                            setcheckInCheckOut={(v) => {
                              setcheckInCheckOut(v);
                            }}
                          />
                        )}
                      />
                    )
                  );
                })}
                <Redirect from="/" to="/dashboard" />
              </Switch>
            </React.Suspense>
          </main>
          <Footer />
        </div>
      ) : (
        <Redirect from="/" to="/login" />
      )}
    </div>
  );
};

export default DefaultLayout;
