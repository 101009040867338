import moment from "moment";
import { useSelector } from "react-redux";
import { element } from "prop-types";
import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import { Link } from "react-router-dom";
import AxiosInstance from "../../../../service/AxiosInstance";
import { Col, Row, Button } from "antd";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { Svg } from "../../../../assets/svg";
import { Select } from "antd";
import { Spin } from "antd";
import { FaArrowRight } from "react-icons/fa";
const imageUrl = "https://bucket-pms.s3.ap-south-1.amazonaws.com/s3-upload/";

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          {/* <Typography>{children}</Typography> */}
          {children}
        </Box>
      )}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const ProTaskUpdate = ({ loading, projectIds, setLoading, sortForDues }) => {
  const { Option } = Select;
  const statusss = useSelector((state) => state.StatusReducer);
  let PrevstatusBg;
  let CurrentstatusBg;
  const Currentcolorstatus = (color) => {
    CurrentstatusBg = "shadow-0";
    if (color == "IN PROGRESS") {
      CurrentstatusBg = "danger-info";
    } else if (color == "DEVELOPMENT DONE") {
      CurrentstatusBg = "danger-info";
    } else if (color == "REJECTED") {
      CurrentstatusBg = "shadow-0";
    } else if (color == "ON HOLD") {
      CurrentstatusBg = "shadow-0";
    } else if (color == "READY FOR QA") {
      CurrentstatusBg = "danger-info";
    } else if (color == "REOPEN") {
      CurrentstatusBg = "shadow-0";
    } else if (color == "READY FOR UAT") {
      CurrentstatusBg = "success-info";
    } else if (color == "CLOSE") {
      CurrentstatusBg = "success-info";
    }
  };

  const Prevcolorstatus = (color) => {
    PrevstatusBg = "shadow-0";
    if (color == "IN PROGRESS") {
      PrevstatusBg = "danger-info";
    } else if (color == "DEVELOPMENT DONE") {
      PrevstatusBg = "danger-info";
    } else if (color == "REJECTED") {
      PrevstatusBg = "shadow-0";
    } else if (color == "ON HOLD") {
      PrevstatusBg = "shadow-0";
    } else if (color == "READY FOR QA") {
      PrevstatusBg = "danger-info";
    } else if (color == "REOPEN") {
      PrevstatusBg = "shadow-0";
    } else if (color == "READY FOR UAT") {
      PrevstatusBg = "success-info";
    } else if (color == "CLOSE") {
      PrevstatusBg = "success-info";
    }
  };

  const [history, setHistory] = useState([]);

  // const [loading, setLoading] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [projectName, setProjectName] = useState([]);
  const [statusSort, setStatusSort] = useState("");
  const [comments, setComments] = useState([]);
  const [sortComment, setSortComment] = useState([]);
  const [updateNumber, setupdateNumber] = useState(0);

  const GetAllTaskUpdate = () => {
    setLoading(true);

    const formData = new URLSearchParams();
    formData.append("id", JSON.stringify(projectIds));
    formData.append("type", sortForDues);
    AxiosInstance.post("/auth/history/project-activites", formData).then(
      (res) => {
        setLoading(false);
        let sortedList = res.data.data.sort(
          (a, b) => moment(a.Time) - moment(b.Time)
        );

        let index = -1;
        let list1 = sortedList.map((item) => {
          return item.History.map((item2) => {
            index++;
            return {
              project_id: item?.Project_id?._id,
              icon: index,
              name: item?.Name,
              Project_name: item?.Project_id?.Project_name,
              Task_id: item.Task_id?._id,
              Task_name: item?.Task_id?.Task_key,
              Current_Status: item2?.Current_Status,
              Previous_Status: item2?.Privious_Status || item2?.Previous_Status,
              Current_Assignees: item2?.Current_Assignees,
              Previous_Assignees:
                item2?.Privious_Assignees || item2?.Previous_Assignees,
              Comments: item2?.Comments,
              Task_create: item2?.Task_create,
              Created_by: item2?.Created_by,
              Updated_by: item2?.Updated_by,
              Time: item2?.Time,
              MilestoneName: item?.Milestone_id?.Name,
              User_id: item2?.User_id,
            };
          });
        });
        let unique = sortedList.map((item) => item?.Project_id);
        // unique.map((item) => console.log([item["_id"], item],"item"))
        // let uniqueArray = [
        //   ...new Map(unique.map((item) => [item["_id"], item])).values(),
        // ];
        let uniqueArray = [
          ...new Map(
            unique
              .filter(
                (item) =>
                  item && item["_id"] !== null && item["_id"] !== undefined
              )
              .map((item) => [item["_id"] || "fallbackKey", item])
          ).values(),
        ];
        setProjectName(uniqueArray);
        setHistory(list1.flat());
      }
    );
  };

  const GetAllComments = () => {
    setupdateNumber(updateNumber + 1);
    AxiosInstance.get(`/auth/get-all-comments?page=${updateNumber}`).then(
      (res) => {
        // setComments(res?.data?.data);
        setComments((prevComments) => [...prevComments, ...res?.data?.data]);
        setSortComment((prevComments) => [...prevComments, ...res?.data?.data]);
        setLoading(false);
      }
    );
  };

  // const GetAllTask = () => {
  //   setLoading(true);
  //   AxiosInstance.get("/auth/tasks/list").then((res) => {
  //     setTasks(res?.data?.data);
  //   });
  // };

  useEffect(() => {
    GetAllTaskUpdate();
    GetAllComments();
    // GetAllTask();
    // getId()
  }, [statusss, projectIds, sortForDues]);

  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
    setStatusSort("");
  };

  // const handleSelect = (e) => {
  //   setStatusSort(e);
  //   if (e == "" || e == null || e == undefined) {
  //     setSortComment(comments);
  //   } else {
  //     setSortComment(
  //       comments.filter(
  //         (comment) => comment.Project_id.toString() == e.toString()
  //       )
  //     );
  //   }
  // };
  const handleSelect = (e) => {
    setStatusSort(e);

    if (!e) {
      setSortComment(comments);
    } else {
      const filteredComments = comments.filter(
        (comment) => comment.Project_id == e
      );
      setSortComment(filteredComments);
    }
  };

  useEffect(() => {
    const container = document.getElementById("scrollContainer");
    console.log("container", container);
    // const handleScroll = () => {
    //   const scrollTop = container.scrollTop;
    //   const scrollHeight = container.scrollHeight;
    //   const clientHeight = container.clientHeight;
    //   if (scrollTop + clientHeight >= scrollHeight - 10 && !isLoading) {
    //     setIsLoading(true);
    //     setTimeout(() => {
    //       setIsLoading(false);
    //     }, 1000);
    //   }
    // };
    // container.addEventListener("scroll", handleScroll);
    // return () => {
    //   container.removeEventListener("scroll", handleScroll);
    // };
  }, [isLoading]);
  return (
    <>
      <Box sx={{ width: "100%" }}>
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <div className="milstone-flex pro-task-head">
            <div className="milestone-card-head ">
              <Tabs
                value={value}
                onChange={handleChange}
                aria-label="basic tabs example"
              >
                <Tab label="Project Activity" {...a11yProps(0)}>
                  {/* <p onClick={GetAllTaskUpdate}></p> */}
                  <Spin spinning={loading} style={{ marginTop: 100 }}>
                    {" "}
                  </Spin>
                </Tab>
                <Tab label="Project Comments" {...a11yProps(1)}>
                  <p onClick={GetAllComments}></p>
                  <Spin spinning={loading} style={{ marginTop: 100 }}>
                    {" "}
                  </Spin>
                </Tab>
              </Tabs>
            </div>
            {/* <MDBNavbarNav className="project-activity">
              <MDBNavbarItem>
                <Link to="/dashboard">
                  <MDBNavbarLink href="#">Dashboard</MDBNavbarLink>
                </Link>
              </MDBNavbarItem>
              <MDBNavbarItem>
                <Link to="/my-dashboard">
                  {" "}
                  <MDBNavbarLink>My Dashboard</MDBNavbarLink>
                </Link>
              </MDBNavbarItem>
              <Spin spinning={loading} style={{ marginTop: 100 }}/>
                {" "}
              </Spin>
            </MDBNavbarNav> */}
            <div className="flex-project-activity">
              <div className="project-title-name ">
                <h6>Select Project :</h6>
              </div>
              <div className="milestone-dropdown ">
                <Select
                  defaultValue={""}
                  value={statusSort}
                  className="select-dropdown leave-4"
                  onChange={(e) => {
                    handleSelect(e);
                  }}
                >
                  <Option value="">All</Option>
                  {projectName.length > 0 &&
                    projectName
                      .sort((a, b) =>
                        a.Project_name.toLowerCase() <
                        b.Project_name.toLowerCase()
                          ? -1
                          : 1
                      )
                      .map((item, index) => (
                        <Option value={`${item?._id}`} key={index}>
                          {item?.Project_name}
                        </Option>
                      ))}
                  {/* <Option value="OPEN">OPEN</Option>
                  <Option value="IN PROGRESS">IN PROGRESS</Option>
                  <Option value="DEVELOPMENT DONE">DEVELOPMENT DONE</Option>
                  <Option value="READY FOR QA">READY FOR QA</Option>
                  <Option value="ON HOLD">ON HOLD</Option> */}
                </Select>
              </div>
            </div>
          </div>
        </Box>
        <CustomTabPanel value={value} index={0}>
          <div className="pm-pro-task-card-body">
            {history &&
              (statusSort.length > 0
                ? history.filter((item) => item.project_id === statusSort)
                : history
              )
                .sort((a, b) => moment(b.Time) - moment(a.Time))
                .map((item, index) => {
                  Currentcolorstatus(item.Current_Status);
                  Prevcolorstatus(item.Previous_Status);
                  return (
                    <Link
                      className="pm-pro-task-body-content"
                      to={`/project/view/${item?.project_id}`}
                      target="_blank"
                      key={index}
                    >
                      <div className="add-cmt">
                        <img
                          src={
                            item?.User_id && item?.User_id?.Profile_pic
                              ? imageUrl + item?.User_id?.Profile_pic
                              : "https://bootdey.com/img/Content/avatar/avatar1.png"
                          }
                          alt="profile"
                          style={{
                            width: "35px",
                            height: "35px",
                            objectFit: "contain",
                            borderRadius: "50px",
                            marginRight: "10px",
                          }}
                        />

                        <div style={{ flex: "1" }}>
                          <div>
                            <div className="user-name-detils history-name project-direction">
                              <p>
                                <b>
                                  {!item.Created_by
                                    ? item?.User_id?.First_name +
                                      " " +
                                      item?.User_id?.Last_name
                                    : item?.Created_by
                                    ? item.Created_by?.First_name +
                                      " " +
                                      item.Created_by?.Last_name
                                    : item.Updated_by}
                                </b>
                              </p>
                              <p>
                                {" "}
                                {item?.Task_create
                                  ? ` Created Task ${item?.Task_name} in ${
                                      item?.Project_name
                                    } On ${moment(item?.Task_create).format(
                                      "DD MMM YYYY"
                                    )}  At ${moment(item?.Task_create).format(
                                      "hh:mm A"
                                    )}`
                                  : item?.Current_Status
                                  ? `Changed the Status for ${
                                      item?.Project_name
                                    } in ${item?.MilestoneName} on ${moment(
                                      item.Time
                                    ).format("DD MMM YYYY")} at ${moment(
                                      item.Time
                                    ).format("hh:mm A")}`
                                  : item?.Current_Assignees
                                  ? `Changed the Assignee For ${
                                      item?.Project_name
                                    } On ${moment(item.Time).format(
                                      "DD MMM YYYY"
                                    )} At ${moment(item.Time).format(
                                      "hh:mm A"
                                    )}`
                                  : item.Comments
                                  ? `has commented in ${
                                      item?.Project_name
                                    } on ${moment(item.Time).format(
                                      "DD MMM YYYY"
                                    )} At ${moment(item.Time).format(
                                      "hh:mm A"
                                    )}`
                                  : item.MilestoneName
                                  ? `Created Milestone on ${moment(
                                      item.Time
                                    ).format("DD MMM YYYY")} at ${moment(
                                      item.Time
                                    ).format("hh:mm A")}`
                                  : ""}
                              </p>
                            </div>
                            <div className="status-data pm-pro-task-status-data">
                              {item?.Comments ? (
                                item?.Comments
                              ) : item?.Previous_Assignees ? (
                                <>
                                  {item?.Previous_Assignees} &nbsp;{" "}
                                  <i className="fas fa-long-arrow-alt-right taskarrowmini"></i>{" "}
                                  &nbsp; <div>{item?.Current_Assignees}</div>
                                </>
                              ) : item.Current_Status ? (
                                <>
                                  <div className={PrevstatusBg}>
                                    {item?.Previous_Status}
                                  </div>{" "}
                                  &nbsp;
                                  <i className="fas fa-long-arrow-alt-right taskarrowmini"></i>{" "}
                                  &nbsp;
                                  <div className={CurrentstatusBg}>
                                    {item?.Current_Status}
                                  </div>
                                </>
                              ) : item.MilestoneName ? (
                                item.MilestoneName
                              ) : (
                                <div className={PrevstatusBg}>
                                  {item?.Previous_Status
                                    ? item?.Previous_Status
                                    : "OPEN"}
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </Link>
                  );
                })}
          </div>
        </CustomTabPanel>
        <CustomTabPanel className="dsfdaf" value={value} index={1}>
          <div className="pm-pro-task-card-body custom-pm-pro-task-card-body">
            {/* {comments
              .filter((item) => {
                const taskProjectId = item?.Task_id;
                tasks.filter((e) => {
                  const newData = e.Project_id == statusSort;
                });
                return taskProjectId && taskProjectId === statusSort;
              })
              .map((item, index) => (
                <div key={index}>
                  <div key={index}>
                    <div className="add-cmt">
                      <div className="user-name-detils history-name project-direction">
                        <img
                          src={
                            item?.User_id && item?.User_id?.Profile_pic
                              ? imageUrl + item?.User_id?.Profile_pic
                              : "https://bootdey.com/img/Content/avatar/avatar1.png"
                          }
                          alt="profile"
                          style={{
                            width: "35px",
                            height: "35px",
                            objectFit: "contain",
                            borderRadius: "50px",
                            marginRight: "10px",
                          }}
                        />
                      </div>
                      <div className="name-main-text">
                        <p>{item?.First_name}</p>
                        {item?.Comment ? (
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              marginRight: "20px",
                              fontSize: "12px",
                            }}
                          >
                            {item.Comment.split(/\b(https?:\/\/\S+)/).map(
                              (part, idx) => {
                                if (idx % 2 === 0) {
                                  return <span key={idx}>{part}</span>;
                                } else {
                                  return (
                                    <a
                                      key={idx}
                                      href={part}
                                      target="_blank"
                                      rel="noopener noreferrer"
                                    >
                                      {part}
                                    </a>
                                  );
                                }
                              }
                            )}
                          </div>
                        ) : null}
                      </div>
                    </div>
                  </div>
                </div>
              ))} */}

            {sortComment?.length > 0 ? (
              sortComment?.map((item, index) => (
                <div
                  style={{
                    maxWidth: "1050",
                    overflow: "hidden",
                  }}
                  key={index}
                >
                  <div className="add-cmt">
                    <div className="user-name-detils history-name project-direction">
                      <img
                        src={
                          item?.User_id && item?.User_data?.Profile_pic
                            ? imageUrl + item?.User_data?.Profile_pic
                            : "https://bootdey.com/img/Content/avatar/avatar1.png"
                        }
                        alt="profile"
                        style={{
                          width: "35px",
                          height: "35px",
                          objectFit: "contain",
                          borderRadius: "50px",
                          marginRight: "10px",
                        }}
                      />
                    </div>
                    <div className="name-main-text ">
                      {/* <p>{item?.First_name}</p> */}
                      <p>
                        {item?.First_name} {item?.Last_name}{" "}
                        <span>
                          on{" "}
                          {moment(item?.Create_at).format(
                            "MMM D YYYY [at] hh:mm A"
                          )}
                        </span>
                      </p>

                      {item?.Comment ? (
                        <div
                          style={{
                            // display: "flex",
                            alignItems: "center",
                            overflow: "hidden",
                            marginRight: "20px",
                            fontSize: "12px",
                            maxWidth: "1050px",
                          }}
                        >
                          {item.Comment.split(/\b(https?:\/\/\S+)/).map(
                            (part, idx) => {
                              if (idx % 2 === 0) {
                                return <span key={idx}>{part}</span>;
                              } else {
                                return (
                                  <a
                                    key={idx}
                                    href={part}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    style={{
                                      maxWidth: "1050px",
                                    }}
                                  >
                                    {part}
                                  </a>
                                );
                              }
                            }
                          )}
                        </div>
                      ) : null}
                    </div>
                  </div>
                </div>
              ))
            ) : (
              <div className="protask-project-comment-spiner-div">
                <Spin></Spin>
              </div>
            )}

            <div className="main-card-task-button">
              {sortComment?.length ? (
                <button
                  className="task-card-btn"
                  onClick={() => {
                    setLoading(true);
                    GetAllComments();
                  }}
                  disabled={loading}
                >
                  {/* SEE MORE <FaArrowRight className="see-more-arrow-icon" />
                </button> */}
                  {loading ? <Spin /> : "SEE MORE"}
                  <FaArrowRight className="see-more-arrow-icon" />
                </button>
              ) : (
                ""
              )}
            </div>
          </div>
        </CustomTabPanel>
      </Box>

      {/* <div className="dash-col-milestone">
        <div className="main-milestone pm-pro-task-update"></div>
      </div> */}
    </>
  );
};
export default ProTaskUpdate;
