import React from "react";
import { Png } from "../../../../assets/png";
import {
  IconButton,
  Box,
  TableContainer,
  Paper,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
} from "@mui/material";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import PersonIcon from "@mui/icons-material/Person";
import BugReportIcon from "@mui/icons-material/BugReport";

import "react-toastify/dist/ReactToastify.css";

import { CgCalendarDates } from "react-icons/cg";

import { Skeleton } from "antd";

const StoryReportTwoSkeletonFile = () => {
  return (
    <Box className="tableContainer">
      <TableContainer
        component={Paper}
        className="cus-table-container story-report-two"
      >
        <Table aria-label="collapsible table" className="table">
          <TableHead>
            <TableRow>
              <TableCell />
              <TableCell>Story</TableCell>
              <TableCell>
                <PersonIcon className="person-icon"></PersonIcon>
                PM
              </TableCell>
              <TableCell>
                <PersonIcon className="person-icon"></PersonIcon>
                CH
              </TableCell>
              <TableCell>
                {" "}
                <img className="status_icon" src={Png.status} alt="Status" />
                Status
              </TableCell>
              <TableCell>
                {" "}
                <CgCalendarDates className="person-icon"></CgCalendarDates>
                Start Date
              </TableCell>
              <TableCell>
                {" "}
                <CgCalendarDates className="person-icon"></CgCalendarDates>
                End Date
              </TableCell>
              <TableCell>
                <img className="filter_icon" src={Png.task}></img>
                Tasks
              </TableCell>

              <TableCell>
                {" "}
                <BugReportIcon className="filter_icon" />
                Issues
              </TableCell>

              <TableCell>
                <img className="filter_icon" src={Png.task}></img>
                Tasks
              </TableCell>

              <TableCell>
                {" "}
                <BugReportIcon className="filter_icon" />
                Issues
              </TableCell>
              <TableCell>
                <img className="status_icon" src={Png.status} alt="Status" />
                DRAFT
              </TableCell>
              <TableCell>
                <img className="status_icon" src={Png.status} alt="Status" />
                OPEN
              </TableCell>
              <TableCell>
                <img className="status_icon" src={Png.status} alt="Status" />
                IN PROGRESS
              </TableCell>
              <TableCell>
                <img className="status_icon" src={Png.status} alt="Status" />
                DEV DONE
              </TableCell>
              <TableCell>
                <img className="status_icon" src={Png.status} alt="Status" />
                REJECTED
              </TableCell>
              <TableCell>
                <img className="status_icon" src={Png.status} alt="Status" />
                ON HOLD
              </TableCell>
              <TableCell>
                <img className="status_icon" src={Png.status} alt="Status" />
                RFQA
              </TableCell>
              <TableCell>
                <img className="status_icon" src={Png.status} alt="Status" />
                REOPEN
              </TableCell>
              <TableCell>
                <img className="status_icon" src={Png.status} alt="Status" />
                RFUAT
              </TableCell>
              <TableCell>
                <img className="status_icon" src={Png.status} alt="Status" />
                CLOSE
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {Array.from({ length: 10 }).map((_, index) => (
              <React.Fragment key={index}>
                <TableRow>
                  <TableCell className="table-data-button-main">
                    <IconButton
                      aria-label="expand row"
                      size="small"
                      className="my-button"
                    >
                      <KeyboardArrowDownIcon />
                    </IconButton>
                    {/* <Skeleton.Input
                      active="active"
                      size="small"
                      shape="default"
                    /> */}
                  </TableCell>
                  <TableCell
                    className="project_name"
                    sx={{ fontWeight: "bold", fontSize: "10%" }}
                  >
                    {" "}
                    {/* <MDBIcon icon="briefcase" className="status_icon" /> */}
                    <Skeleton.Input
                      active="active"
                      size="small"
                      shape="default"
                      className="story_reportStorySkeleton"
                    />
                  </TableCell>
                  <TableCell>
                    {" "}
                    <Skeleton.Input
                      active="active"
                      size="small"
                      shape="default"
                    />
                  </TableCell>
                  <TableCell>
                    <Skeleton.Input
                      active="active"
                      size="small"
                      shape="default"
                    />
                  </TableCell>
                  <TableCell>
                    <Skeleton.Input
                      active="active"
                      size="small"
                      shape="default"
                    />
                  </TableCell>
                  <TableCell>
                    <Skeleton.Input
                      active="active"
                      size="small"
                      shape="default"
                    />
                  </TableCell>
                  <TableCell>
                    <Skeleton.Input
                      active="active"
                      size="small"
                      shape="default"
                    />
                  </TableCell>
                  <TableCell>
                    <Skeleton.Input
                      active="active"
                      size="small"
                      shape="default"
                    />
                  </TableCell>

                  <TableCell>
                    <Skeleton.Input
                      active="active"
                      size="small"
                      shape="default"
                    />
                  </TableCell>
                  <TableCell>
                    <Skeleton.Input
                      active="active"
                      size="small"
                      shape="default"
                    />
                  </TableCell>

                  <TableCell>
                    <Skeleton.Input
                      active="active"
                      size="small"
                      shape="default"
                    />
                  </TableCell>
                  <TableCell>
                    <Skeleton.Input
                      active="active"
                      size="small"
                      shape="default"
                    />
                  </TableCell>
                  <TableCell>
                    <Skeleton.Input
                      active="active"
                      size="small"
                      shape="default"
                    />
                  </TableCell>
                  <TableCell>
                    {" "}
                    <Skeleton.Input
                      active="active"
                      size="small"
                      shape="default"
                    />
                  </TableCell>
                  <TableCell>
                    <Skeleton.Input
                      active="active"
                      size="small"
                      shape="default"
                    />
                  </TableCell>
                  <TableCell>
                    {" "}
                    <Skeleton.Input
                      active="active"
                      size="small"
                      shape="default"
                    />
                  </TableCell>
                  <TableCell>
                    {" "}
                    <Skeleton.Input
                      active="active"
                      size="small"
                      shape="default"
                    />
                  </TableCell>
                  <TableCell>
                    {" "}
                    <Skeleton.Input
                      active="active"
                      size="small"
                      shape="default"
                    />
                  </TableCell>
                  <TableCell>
                    <Skeleton.Input
                      active="active"
                      size="small"
                      shape="default"
                    />
                  </TableCell>
                  <TableCell>
                    <Skeleton.Input
                      active="active"
                      size="small"
                      shape="default"
                    />
                  </TableCell>
                </TableRow>
                {/* {openProject === row?.project?.Project_name &&
                  row?.stories?.map((story, index) => (
                    <TableRow key={index}>
                      <TableCell></TableCell>
                      <TableCell>
                        {story?.Short_summary}
                        {console.log(story?.statusCounts, "hii")}
                      </TableCell>
                      <TableCell>
                        {row?.project?.Project_manager?.replace(
                          /\([^)]*\)/,
                          ""
                        )}
                      </TableCell>
                      <TableCell>
                        {row?.project?.Technical_project_manager?.replace(
                          /\([^)]*\)/,
                          ""
                        )}
                      </TableCell>

                      <TableCell>
                        {story?.Status === "IN PROGRESS" ? (
                          <Box
                            style={{
                              width: "100%",
                              textAlign: "center",
                              backgroundColor: "#62AEEB",
                              color: "white",
                              display: "inline-block",
                              padding: "16px 16px",
                            }}
                          >
                            {story?.Status}
                          </Box>
                        ) : story?.Status === "DEVELOPMENT DONE" ? (
                          <Box
                            style={{
                              width: "100%",
                              textAlign: "center",
                              backgroundColor: "#62AEEB",
                              color: "white",
                              display: "inline-block",
                              padding: "16px",
                            }}
                          >
                            {story?.Status}
                          </Box>
                        ) : story?.Status === "OPEN" ? (
                          <Box
                            style={{
                              width: "100%",
                              textAlign: "center",
                              backgroundColor: "#CCDEE9",
                              color: "white",
                              display: "inline-block",
                              padding: "16px 16px",
                              margin: "0%",
                            }}
                          >
                            {story?.Status}
                          </Box>
                        ) : story?.Status === "READY FOR QA" ? (
                          <Box
                            style={{
                              width: "100%",
                              textAlign: "center",
                              backgroundColor: "#62AEEB",
                              color: "white",
                              display: "inline-block",
                              padding: "16px 16px",
                            }}
                          >
                            {story?.Status}
                          </Box>
                        ) : story?.Status === "Ready For QA" ? (
                          <Box
                            style={{
                              width: "100%",
                              textAlign: "center",
                              backgroundColor: "#62AEEB",
                              color: "white",
                              display: "inline-block",
                              padding: "16px 16px",
                            }}
                          >
                            {story?.Status}
                          </Box>
                        ) : story?.Status === "CLOSE" ? (
                          <Box
                            style={{
                              width: "100%",
                              textAlign: "center",
                              backgroundColor: "#5F9030",
                              color: "white",
                              display: "inline-block",
                              padding: "16px 16px ",
                            }}
                          >
                            {story?.Status}
                          </Box>
                        ) : story?.Status === "READT FOR UAT" ? (
                          <Box
                            style={{
                              width: "100%",
                              textAlign: "center",
                              backgroundColor: "#5F9030",
                              color: "white",
                              display: "inline-block",
                              padding: "16px",
                            }}
                          >
                            {story?.Status}
                          </Box>
                        ) : story?.Status === "ON HOLD" ? (
                          <Box
                            style={{
                              width: "100%",
                              textAlign: "center",
                              backgroundColor: "#1266F1",
                              color: "white",
                              display: "inline-block",
                              padding: "16px 16px",
                            }}
                          >
                            {story?.Status}
                          </Box>
                        ) : story?.Status === "REOPEN" ? (
                          <Box
                            style={{
                              width: "100%",
                              textAlign: "center",
                              backgroundColor: "#CCDEE9",
                              color: "white",
                              display: "inline-block",
                              padding: "16px 16px",
                            }}
                          >
                            {story?.Status}
                          </Box>
                        ) : story?.Status === "REJECTED" ? (
                          <Box
                            style={{
                              width: "100%",
                              textAlign: "center",
                              backgroundColor: "#1266F1",
                              color: "white",
                              display: "inline-block",
                              padding: "16px 16px",
                            }}
                          >
                            {story?.Status}
                          </Box>
                        ) : story?.Status === "READY FOR UAT" ? (
                          <Box
                            style={{
                              width: "100%",
                              textAlign: "center",
                              backgroundColor: "#5F9030",
                              color: "white",
                              display: "inline-block",
                              padding: "16px 16px",
                            }}
                          >
                            {story?.Status}
                          </Box>
                        ) : story?.Status === "DRAFT" ? (
                          <Box
                            style={{
                              width: "100%",
                              textAlign: "center",
                              backgroundColor: "#CCDEE9",
                              color: "white",
                              display: "inline-block",
                              padding: "16px 16px",
                            }}
                          >
                            {story?.Status}
                          </Box>
                        ) : (
                          ""
                        )}
                      </TableCell>
                      <TableCell>
                        {formatDate(story?.Assigned_date2, false)}
                      </TableCell>
                      <TableCell>
                        {formatDate(story?.Due_date2, true)}
                      </TableCell>
                      <TableCell
                        className="td-task"
                        style={{ fontSize: "12px" }}
                      >
                        <Typography className="task-main">
                          Task {story?.statusCounts?.DRAFT?.task}
                        </Typography>
                        <Typography className="task-main">
                          Bug {story?.statusCounts?.DRAFT?.bug}
                        </Typography>
                      </TableCell>
                      <TableCell
                        className="td-task"
                        style={{ fontSize: "12px" }}
                      >
                        <Typography className="task-main">
                          Task {story?.statusCounts?.OPEN?.task}
                        </Typography>
                        <Typography className="task-main">
                          Bug {story?.statusCounts?.OPEN?.bug}
                        </Typography>
                      </TableCell>

                      <TableCell
                        className="td-task"
                        style={{ fontSize: "12px" }}
                      >
                        <Typography className="task-main">
                          Task{" "}
                          {story?.statusCounts?.["IN PROGRESS"]?.task}
                        </Typography>
                        <Typography className="task-main">
                          Bug{" "}
                          {story?.statusCounts?.["IN PROGRESS"]?.bug}
                        </Typography>
                      </TableCell>

                      <TableCell
                        className="td-task"
                        style={{ fontSize: "12px" }}
                      >
                        <Typography className="task-main">
                          Task{" "}
                          {
                            story?.statusCounts?.["DEVELOPMENT DONE"]
                              ?.task
                          }
                        </Typography>
                        <Typography className="task-main">
                          Bug{" "}
                          {
                            story?.statusCounts?.["DEVELOPMENT DONE"]
                              ?.bug
                          }
                        </Typography>
                      </TableCell>

                      <TableCell
                        className="td-task"
                        style={{ fontSize: "12px" }}
                      >
                        <Typography className="task-main">
                          Task {story?.statusCounts?.REJECTED?.task}
                        </Typography>
                        <Typography className="task-main">
                          Bug {story?.statusCounts?.REJECTED?.bug}
                        </Typography>
                      </TableCell>

                      <TableCell
                        className="td-task"
                        style={{ fontSize: "12px" }}
                      >
                        <Typography className="task-main">
                          Task{" "}
                          {story?.statusCounts?.["ON HOLD"]?.task}
                        </Typography>
                        <Typography className="task-main">
                          Bug {story?.statusCounts?.["ON HOLD"]?.bug}
                        </Typography>
                      </TableCell>

                      <TableCell
                        className="td-task"
                        style={{ fontSize: "12px" }}
                      >
                        <Typography className="task-main">
                          Task{" "}
                          {
                            story?.statusCounts?.["Ready For QA"]
                              ?.task
                          }
                        </Typography>
                        <Typography className="task-main">
                          Bug{" "}
                          {story?.statusCounts?.["Ready For QA"]?.bug}
                        </Typography>
                      </TableCell>

                      <TableCell
                        className="td-task"
                        style={{ fontSize: "12px" }}
                      >
                        <Typography className="task-main">
                          Task {story?.statusCounts?.REOPEN?.task}
                        </Typography>
                        <Typography className="task-main">
                          Bug {story?.statusCounts?.REOPEN?.bug}
                        </Typography>
                      </TableCell>

                      <TableCell
                        className="td-task"
                        style={{ fontSize: "12px" }}
                      >
                        <Typography className="task-main">
                          Task{" "}
                          {
                            story?.statusCounts?.["READT FOR UAT"]
                              ?.task
                          }
                        </Typography>
                        <Typography className="task-main">
                          Bug{" "}
                          {
                            story?.statusCounts?.["READT FOR UAT"]
                              ?.bug
                          }
                        </Typography>
                      </TableCell>

                      <TableCell
                        className="td-task"
                        style={{ fontSize: "12px" }}
                      >
                        <Typography className="task-main">
                          Task {story?.statusCounts?.CLOSE?.task}
                        </Typography>
                        <Typography className="task-main">
                          Bug {story?.statusCounts?.CLOSE?.bug}
                        </Typography>
                      </TableCell>
                    </TableRow>
                  ))} */}
              </React.Fragment>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};

export default StoryReportTwoSkeletonFile;
