import React, { useEffect, useState } from "react";
import AxiosInstance from "../../../../service/AxiosInstance";
import { Png } from "../../../../assets/png";
import {
  IconButton,
  Box,
  Typography,
  TableContainer,
  Paper,
  Table,
  Menu,
  MenuItem,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  TablePagination,
  LinearProgress,
  TextField,
  InputAdornment,
} from "@mui/material";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import BugReportIcon from "@mui/icons-material/BugReport";
import PersonIcon from "@mui/icons-material/Person";
import BlockIcon from "@mui/icons-material/Block";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { Link } from "react-router-dom";
import PmsRangePicker from "../../me/leaveModule/RangePicker";
import SearchIcon from "@mui/icons-material/Search";

import "react-toastify/dist/ReactToastify.css";
import { Tooltip } from "antd";

import { CgCalendarDates } from "react-icons/cg";
import { MDBCard, MDBCol, MDBIcon } from "mdb-react-ui-kit";
import { Select } from "antd";
import moment from "moment";
import StoryReportTwoSkeletonFile from "./StoryReportTwoSkeletonFile";
const { Option } = Select;

const StoryReport2 = () => {
  const [openProject, setOpenProject] = useState(null);
  const [storyList, setStoryList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(50);
  const [menuAnchor, setMenuAnchor] = useState(null);
  const [isHovered, setIsHovered] = useState(false);
  const [groupBy, setGroupBy] = useState("Project");
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [status, setStatus] = useState([]);
  const [searchQuery, setSearchQuery] = useState([]);
  const [dateTesting, setDateTesting] = useState("");
  const [endDateFilter, setEndDateFilter] = useState("");

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 50));
    setPage(0);
  };

  const handleMenuOpen = (event) => {
    setMenuAnchor(event.currentTarget);
  };

  const handleMenuClose = () => {
    setMenuAnchor(null);
  };
  const handleGroupBy = (groupBy) => {
    console.log(groupBy);
    setGroupBy(groupBy);
    handleMenuClose();
  };

  function formatDate(dateString, isDueDate, status) {
    if (dateString) {
      const date = new Date(dateString);
      const currentDate = new Date();
      const diffInDays = Math.floor(
        (date - currentDate) / (1000 * 60 * 60 * 24)
      );

      if (isDueDate) {
        const day = date.getDate().toString().padStart(2, "0");
        const month = (date.getMonth() + 1).toString().padStart(2, "0");
        const year = date.getFullYear();
        const formattedDate = `${day}-${month}-${year}`;

        if (status === "CLOSE") {
          return <span style={{ color: "black" }}>{formattedDate}</span>;
        } else {
          if (diffInDays < 0) {
            return (
              <div>
                <span style={{ color: "red" }}>{formattedDate}</span>
                <br />
                <span style={{ color: "red" }}>
                  ({Math.abs(diffInDays)} days ago)
                </span>
              </div>
            );
          } else {
            return (
              <div>
                <span style={{ color: "green" }}>{formattedDate}</span>
                <br />
                <span style={{ color: "green" }}>({diffInDays} days to go)</span>
              </div>
            );
          }
        }
      } else {
        const day = date.getDate().toString().padStart(2, "0");
        const month = (date.getMonth() + 1).toString().padStart(2, "0");
        const year = date.getFullYear();
        return `${day}-${month}-${year}`;
      }
    } else {
      return "-";
    }
  }

  const getStoryList = async () => {
    setLoading(true);
    try {
      const res = await AxiosInstance.get("auth/story-report1");
      setStoryList(res?.data?.data);

      setLoading(false);
    } catch (error) {
      console.error(error);
      setLoading(false);
    }
  };

  useEffect(() => {
    getStoryList();
  }, []);

  useEffect(() => {
    console.log(startDate, endDate, status);
  }, [startDate, endDate, status]);

  const onChange = (value) => {
    if (!value || !value[0] || !value[1]) {
      setStartDate(null);
      setEndDate(null);
    } else {
      setStartDate(value[0]);
      setEndDate(value[1]);
      const startDateString = moment(value[0]).format("DD/MM/YYYY").toString();
      const endDateString = moment(value[1]).format("DD/MM/YYYY").toString();
      setDateTesting(startDateString);
      setEndDateFilter(endDateString);
    }
  };

  const filteredMilestoneList = storyList.filter((item) => {
    const itemStartDate = item?.stories?.[0]?.Assigned_date2
      ? moment(item?.stories?.[0]?.Assigned_date2).toDate()
      : null;
    const itemEndDate = item?.stories?.[0]?.Due_date2
      ? moment(item?.stories?.[0]?.Due_date2).toDate()
      : null;

    const startDateMatch =
      !startDate || !itemStartDate || startDate <= itemStartDate;
    const endDateMatch = !endDate || !itemEndDate || itemEndDate <= endDate;
    const statusMatch =
      status.length === 0 || status.includes(item?.stories?.[0]?.Status);
    const searchQueryMatch = !searchQuery || matchesSearchQuery(item);

    return startDateMatch && endDateMatch && statusMatch && searchQueryMatch;
  });

  function matchesSearchQuery(item) {
    // const searchRegex = new RegExp(searchQuery, "i");

    const escapedSearchQuery = String(searchQuery).replace(/[.*+?^${}()|[\]\\]/g, "\\$&");
    const searchRegex = new RegExp(escapedSearchQuery, "i");
    ;
    const projectName = item?.project?.Project_name || "";
    const storyName = item?.stories?.[0]?.Short_summary || "";
    const Task_keyName = item?.stories?.[0]?.Task_key || "";
    const pmNAME = item?.project?.Project_manager || "";
    const chName = item?.project?.Technical_project_manager || "";

    return (
      searchRegex.test(projectName) ||
      searchRegex.test(storyName) ||
      searchRegex.test(pmNAME) ||
      searchRegex.test(chName) ||
      searchRegex.test(Task_keyName)
    );
  }

  const handleSearch = (e) => {
    setSearchQuery(e?.target?.value);
  };

  return (
    <div className="demo">
      <MDBCard className="page-content">
        <div className="create-project">
          <MDBCol size="md" className="col-example titel-set">
            <>
              <div className="leave-set-btn2">
                <h3 className="tablHeader">Story Report</h3>
              </div>
            </>
          </MDBCol>
        </div>
        <Box
          className={`bottom_left1 ${isHovered ? "show-icons" : ""}`}
          onMouseEnter={() => setIsHovered(true)}
          onMouseLeave={() => setIsHovered(false)}
        >
          <Box className="group_by">
            <Typography onClick={handleMenuOpen}>
              Group by {groupBy}
              <ArrowDropDownIcon />
            </Typography>
            <Menu
              anchorEl={menuAnchor}
              open={Boolean(menuAnchor)}
              onClose={handleMenuClose}
            >
              <MenuItem onClick={() => handleGroupBy("Project")}>
                <MDBIcon icon="briefcase" className="project_icon" />
                Project
              </MenuItem>
              <MenuItem onClick={() => handleGroupBy("None")}>
                <BlockIcon className="none_icon"> </BlockIcon>
                {/* None */}
                <Link to="/storyList">None</Link>
              </MenuItem>
            </Menu>
          </Box>

          <Box className="right-side-my custom-right-my-side">
            <Box className="milestone_search_box">
              <TextField
                type="text"
                className="cus-milestone_search_box"
                placeholder="Search"
                onChange={(e) => handleSearch(e)}
                value={searchQuery}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon />
                    </InputAdornment>
                  ),
                }}
              />
            </Box>
            <div className=" cus-row  custom-row">
              {/* <MDBCol size="md-6 cus-main-md"> */}
              <div className="due_date">
                <label className="start-list">Due Date</label>
                <PmsRangePicker
                  className="search-rangepicker"
                  format="DD-MM-YYYY"
                  onChange={onChange}
                />
              </div>
              {/* </MDBCol> */}
              {/*  <MDBCol size="md-6 cus-main-md"> */}
              <div className="status_drop">
                <label className="start-list">Status</label>
                <Select
                  placeholder="Status"
                  mode="multiple"
                  className="select-dropdown cus-select-dropdown leave-4"
                  onChange={(e) => {
                    console.log(e);
                    setStatus(e);
                  }}
                >
                  <Option value="DRAFT">DRAFT</Option>
                  <Option value="OPEN">OPEN</Option>
                  <Option value="IN PROGRESS">IN PROGRESS</Option>
                  <Option value="DEVELOPMENT DONE">DEVELOPMENT DONE</Option>
                  <Option value="REJECTED">REJECTED</Option>
                  <Option value="ON HOLD">ON HOLD</Option>
                  <Option value="READY FOR QA">READY FOR QA</Option>
                  <Option value="REOPEN">REOPEN</Option>
                  <Option value="READY FOR UAT">READY FOR UAT</Option>
                  <Option value="CLOSE">CLOSE</Option>
                </Select>
              </div>

              {/* </MDBCol> */}
            </div>
          </Box>
        </Box>

        <hr />

        <Box className="bottom_main">
          <Box className="bottom_header1">
            {filteredMilestoneList?.length > 0 ||
            dateTesting?.length > 0 ||
            endDateFilter?.length > 0 ||
            searchQuery?.length > 0 ||
            status?.length > 0 ? (
              <Box className="tableContainer">
                <TableContainer
                  component={Paper}
                  className="cus-table-container1 story-report-two"
                >
                  <Table aria-label="collapsible table" className="table">
                    <TableHead>
                      <TableRow>
                        <TableCell />
                        <TableCell>Story</TableCell>
                        <TableCell>
                          <PersonIcon className="person-icon"></PersonIcon>
                          PM
                        </TableCell>
                        <TableCell>
                          <PersonIcon className="person-icon"></PersonIcon>
                          CH
                        </TableCell>
                        <TableCell>
                          {" "}
                          <img
                            className="status_icon"
                            src={Png.status}
                            alt="Status"
                          />
                          Status
                        </TableCell>
                        <TableCell>
                          {" "}
                          <CgCalendarDates className="person-icon"></CgCalendarDates>
                          Start Date
                        </TableCell>
                        <TableCell>
                          {" "}
                          <CgCalendarDates className="person-icon"></CgCalendarDates>
                          End Date
                        </TableCell>

                        <TableCell>
                          <img className="filter_icon" src={Png.task}></img>
                          Tasks
                        </TableCell>

                        <TableCell>
                          {" "}
                          <BugReportIcon className="filter_icon" />
                          Issues
                        </TableCell>

                        <TableCell>
                          <img
                            className="status_icon"
                            src={Png.status}
                            alt="Status"
                          />
                          DRAFT
                        </TableCell>
                        <TableCell>
                          <img
                            className="status_icon"
                            src={Png.status}
                            alt="Status"
                          />
                          OPEN
                        </TableCell>
                        <TableCell>
                          <img
                            className="status_icon"
                            src={Png.status}
                            alt="Status"
                          />
                          IN PROGRESS
                        </TableCell>
                        <TableCell>
                          <img
                            className="status_icon"
                            src={Png.status}
                            alt="Status"
                          />
                          DEV DONE
                        </TableCell>
                        <TableCell>
                          <img
                            className="status_icon"
                            src={Png.status}
                            alt="Status"
                          />
                          REJECTED
                        </TableCell>
                        <TableCell>
                          <img
                            className="status_icon"
                            src={Png.status}
                            alt="Status"
                          />
                          ON HOLD
                        </TableCell>
                        <TableCell>
                          <img
                            className="status_icon"
                            src={Png.status}
                            alt="Status"
                          />
                          RFQA
                        </TableCell>
                        <TableCell>
                          <img
                            className="status_icon"
                            src={Png.status}
                            alt="Status"
                          />
                          REOPEN
                        </TableCell>
                        <TableCell>
                          <img
                            className="status_icon"
                            src={Png.status}
                            alt="Status"
                          />
                          RFUAT
                        </TableCell>
                        <TableCell>
                          <img
                            className="status_icon"
                            src={Png.status}
                            alt="Status"
                          />
                          CLOSE
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {filteredMilestoneList
                        .slice(
                          page * rowsPerPage,
                          page * rowsPerPage + rowsPerPage
                        )
                        ?.map((row, index) => (
                          <React.Fragment key={index}>
                            <TableRow>
                              <TableCell className="table-data-button-main">
                                <IconButton
                                  aria-label="expand row"
                                  size="small"
                                  className="my-button"
                                  onClick={() =>
                                    setOpenProject(
                                      openProject === row?.project?.Project_name
                                        ? null
                                        : row?.project?.Project_name
                                    )
                                  }
                                >
                                  {openProject ===
                                  row?.project?.Project_name ? (
                                    <KeyboardArrowUpIcon />
                                  ) : (
                                    <KeyboardArrowDownIcon />
                                  )}
                                </IconButton>
                              </TableCell>
                              <TableCell
                                className="project_name"
                                sx={{ fontWeight: "bold", fontSize: "10%" }}
                              >
                                {" "}
                                <MDBIcon
                                  icon="briefcase"
                                  className="status_icon"
                                />
                                {row?.project?.Project_name}
                              </TableCell>
                              <TableCell></TableCell>
                              <TableCell></TableCell>
                              <TableCell></TableCell>
                              <TableCell></TableCell>
                              <TableCell></TableCell>
                              <TableCell></TableCell>

                              <TableCell></TableCell>
                              <TableCell></TableCell>

                              <TableCell></TableCell>
                              <TableCell></TableCell>
                              <TableCell></TableCell>
                              <TableCell></TableCell>
                              <TableCell></TableCell>
                              <TableCell></TableCell>
                              <TableCell></TableCell>
                              <TableCell></TableCell>
                              <TableCell></TableCell>
                              <TableCell></TableCell>
                              <TableCell></TableCell>
                            </TableRow>
                            {openProject === row?.project?.Project_name &&
                              row?.stories?.map((story, index) => (
                                <TableRow key={index}>
                                  <TableCell></TableCell>
                               

                                  <TableCell>
                                    <Tooltip title={story?.Short_summary}>
                                      {story?.Short_summary &&
                                        story?.Short_summary.length > 30 ? (
                                        <div
                                          style={{
                                            display: "-webkit-box",
                                            WebkitLineClamp: 2,
                                            WebkitBoxOrient: "vertical",
                                            overflow: "hidden",
                                            textOverflow: "ellipsis",
                                          }}
                                        >
                                          {`${story?.Task_key} - `} {story?.Short_summary.slice(
                                            0,
                                            30
                                          )}
                                          {story?.Short_summary.slice(
                                            30,
                                            100
                                          )}
                                          ...
                                        </div>
                                      ) : (
                                        story?.Short_summary
                                      )}
                                    </Tooltip>
                                  </TableCell>


                                  <TableCell>
                                    {story?.project?.Project_manager?.replace(
                                      /\([^)]*\)/,
                                      ""
                                    )}
                                  </TableCell>

                                  <TableCell>
                                    {story?.project?.Technical_project_manager?.replace(
                                      /\([^)]*\)/,
                                      ""
                                    )}
                                  </TableCell>

                                  <TableCell>
                                    {story?.Status === "IN PROGRESS" ? (
                                      <Box
                                        style={{
                                          width: "100%",
                                          textAlign: "center",
                                          backgroundColor: "#62AEEB",
                                          color: "white",
                                          display: "inline-block",
                                          padding: "18px 18px",
                                        }}
                                      >
                                        {story?.Status}
                                      </Box>
                                    ) : story?.Status === "DEVELOPMENT DONE" ? (
                                      <Box
                                        style={{
                                          width: "100%",
                                          textAlign: "center",
                                          backgroundColor: "#62AEEB",
                                          color: "white",
                                          display: "inline-block",
                                          padding: "18px 18px",
                                        }}
                                      >
                                        {story?.Status}
                                      </Box>
                                    ) : story?.Status === "OPEN" ? (
                                      <Box
                                        style={{
                                          width: "100%",
                                          textAlign: "center",
                                          backgroundColor: "#CCDEE9",
                                          color: "white",
                                          display: "inline-block",
                                          padding: "  18px 18px",

                                          margin: "0%",
                                          fontWeight: "bold",
                                        }}
                                      >
                                        {story?.Status}
                                      </Box>
                                    ) : story?.Status === "READY FOR QA" ? (
                                      <Box
                                        style={{
                                          width: "100%",
                                          textAlign: "center",
                                          backgroundColor: "#62AEEB",
                                          color: "white",
                                          display: "inline-block",
                                          padding: "18px 18px",
                                        }}
                                      >
                                        {story?.Status}
                                      </Box>
                                    ) : story?.Status === "Ready For QA" ? (
                                      <Box
                                        style={{
                                          width: "100%",
                                          textAlign: "center",
                                          backgroundColor: "#62AEEB",
                                          color: "white",
                                          display: "inline-block",
                                          padding: "18px 18px",
                                        }}
                                      >
                                        {story?.Status}
                                      </Box>
                                    ) : story?.Status === "CLOSE" ? (
                                      <Box
                                        style={{
                                          width: "100%",
                                          textAlign: "center",
                                          backgroundColor: "#5F9030",
                                          color: "white",
                                          display: "inline-block",
                                          padding: "18px 18px ",
                                        }}
                                      >
                                        {story?.Status}
                                      </Box>
                                    ) : story?.Status === "READY FOR UAT" ? (
                                      <Box
                                        style={{
                                          width: "100%",
                                          textAlign: "center",
                                          backgroundColor: "#5F9030",
                                          color: "white",
                                          display: "inline-block",
                                          padding: "18px 18px",
                                        }}
                                      >
                                        {story?.Status}
                                      </Box>
                                    ) : story?.Status === "ON HOLD" ? (
                                      <Box
                                        style={{
                                          width: "100%",
                                          textAlign: "center",
                                          backgroundColor: "#1266F1",
                                          color: "white",
                                          display: "inline-block",
                                          padding: "18px 18px",
                                        }}
                                      >
                                        {story?.Status}
                                      </Box>
                                    ) : story?.Status === "REOPEN" ? (
                                      <Box
                                        style={{
                                          width: "100%",
                                          textAlign: "center",
                                          backgroundColor: "#CCDEE9",
                                          color: "white",
                                          display: "inline-block",
                                          padding: "  18px 18px",
                                          fontWeight: "bold",
                                        }}
                                      >
                                        {story?.Status}
                                      </Box>
                                    ) : story?.Status === "REJECTED" ? (
                                      <Box
                                        style={{
                                          width: "100%",
                                          textAlign: "center",
                                          backgroundColor: "#1266F1",
                                          color: "white",
                                          display: "inline-block",
                                          padding: "18px 18px",
                                        }}
                                      >
                                        {story?.Status}
                                      </Box>
                                    ) : story?.Status === "READY FOR UAT" ? (
                                      <Box
                                        style={{
                                          width: "100%",
                                          textAlign: "center",
                                          backgroundColor: "#5F9030",
                                          color: "white",
                                          display: "inline-block",
                                          padding: "18px 18px",
                                        }}
                                      >
                                        {story?.Status}
                                      </Box>
                                    ) : story?.Status === "DRAFT" ? (
                                      <Box
                                        style={{
                                          width: "100%",
                                          textAlign: "center",
                                          backgroundColor: "#CCDEE9",
                                          color: "white",
                                          display: "inline-block",
                                          padding: "18px 18px",
                                        }}
                                      >
                                        {story?.Status}
                                      </Box>
                                    ) : (
                                      ""
                                    )}
                                  </TableCell>

                                  <TableCell align="center">
                                    {formatDate(story?.Assigned_date2, false)}
                                  </TableCell>
                                  <TableCell align="center">
                                    {formatDate(
                                      story?.Due_date2,
                                      true,
                                      story?.Status
                                    )}
                                  </TableCell>
                                  <TableCell>
                                    <div className="project_table_progress_bar">
                                      <span>{story?.completedTasks}</span>
                                      <Box className="my-progress">
                                        {story?.totalTasks > 0 ? (
                                          <LinearProgress
                                            className="progress_bar"
                                            value={
                                              (story?.completedTasks /
                                                story?.totalTasks) *
                                              100
                                            }
                                            color="success"
                                            variant="determinate"
                                          />
                                        ) : (
                                          <LinearProgress
                                            className="progress_bar"
                                            value={0}
                                            color="success"
                                            variant="determinate"
                                          />
                                        )}
                                        <span className="progress-value">
                                          {story?.totalTasks > 0
                                            ? `${Math.round(
                                                (story?.completedTasks /
                                                  story?.totalTasks) *
                                                  100
                                              )}%`
                                            : "0%"}
                                        </span>
                                      </Box>
                                      <span>{story?.totalTasks}</span>
                                    </div>
                                  </TableCell>

                                  <TableCell>
                                    <div className="project_table_progress_bar">
                                      <span>{story?.completedBugs}</span>
                                      <Box className="my-progress">
                                        {story?.totalBugs > 0 ? (
                                          <LinearProgress
                                            className="progress_bar"
                                            value={
                                              (story?.completedBugs /
                                                story?.totalBugs) *
                                              100
                                            }
                                            color="success"
                                            variant="determinate"
                                          />
                                        ) : (
                                          <LinearProgress
                                            className="progress_bar"
                                            value={0}
                                            color="success"
                                            variant="determinate"
                                          />
                                        )}
                                        <span className="progress-value">
                                          {story?.totalBugs > 0
                                            ? `${Math.round(
                                                (story?.completedBugs /
                                                  story?.totalBugs) *
                                                  100
                                              )}%`
                                            : "0%"}
                                        </span>
                                      </Box>
                                      <span>{story?.totalBugs}</span>
                                    </div>
                                  </TableCell>
                                  <TableCell
                                    className="td-task"
                                    style={{ fontSize: "12px" }}
                                  >
                                    <Typography className="task-main">
                                      Task {story?.statusCounts?.DRAFT?.task}
                                    </Typography>
                                    <Typography className="task-main">
                                      Bug {story?.statusCounts?.DRAFT?.bug}
                                    </Typography>
                                  </TableCell>
                                  <TableCell
                                    className="td-task"
                                    style={{ fontSize: "12px" }}
                                  >
                                    <Typography className="task-main">
                                      Task {story?.statusCounts?.OPEN?.task}
                                    </Typography>
                                    <Typography className="task-main">
                                      Bug {story?.statusCounts?.OPEN?.bug}
                                    </Typography>
                                  </TableCell>

                                  <TableCell
                                    className="td-task"
                                    style={{ fontSize: "12px" }}
                                  >
                                    <Typography className="task-main">
                                      Task{" "}
                                      {
                                        story?.statusCounts?.["IN PROGRESS"]
                                          ?.task
                                      }
                                    </Typography>
                                    <Typography className="task-main">
                                      Bug{" "}
                                      {
                                        story?.statusCounts?.["IN PROGRESS"]
                                          ?.bug
                                      }
                                    </Typography>
                                  </TableCell>

                                  <TableCell
                                    className="td-task"
                                    style={{ fontSize: "12px" }}
                                  >
                                    <Typography className="task-main">
                                      Task{" "}
                                      {
                                        story?.statusCounts?.[
                                          "DEVELOPMENT DONE"
                                        ]?.task
                                      }
                                    </Typography>
                                    <Typography className="task-main">
                                      Bug{" "}
                                      {
                                        story?.statusCounts?.[
                                          "DEVELOPMENT DONE"
                                        ]?.bug
                                      }
                                    </Typography>
                                  </TableCell>

                                  <TableCell
                                    className="td-task"
                                    style={{ fontSize: "12px" }}
                                  >
                                    <Typography className="task-main">
                                      Task {story?.statusCounts?.REJECTED?.task}
                                    </Typography>
                                    <Typography className="task-main">
                                      Bug {story?.statusCounts?.REJECTED?.bug}
                                    </Typography>
                                  </TableCell>

                                  <TableCell
                                    className="td-task"
                                    style={{ fontSize: "12px" }}
                                  >
                                    <Typography className="task-main">
                                      Task{" "}
                                      {story?.statusCounts?.["ON HOLD"]?.task}
                                    </Typography>
                                    <Typography className="task-main">
                                      Bug{" "}
                                      {story?.statusCounts?.["ON HOLD"]?.bug}
                                    </Typography>
                                  </TableCell>

                                  <TableCell
                                    className="td-task"
                                    style={{ fontSize: "12px" }}
                                  >
                                    <Typography className="task-main">
                                      Task{" "}
                                      {
                                        story?.statusCounts?.["Ready For QA"]
                                          ?.task
                                      }
                                    </Typography>
                                    <Typography className="task-main">
                                      Bug{" "}
                                      {
                                        story?.statusCounts?.["Ready For QA"]
                                          ?.bug
                                      }
                                    </Typography>
                                  </TableCell>

                                  <TableCell
                                    className="td-task"
                                    style={{ fontSize: "12px" }}
                                  >
                                    <Typography className="task-main">
                                      Task {story?.statusCounts?.REOPEN?.task}
                                    </Typography>
                                    <Typography className="task-main">
                                      Bug {story?.statusCounts?.REOPEN?.bug}
                                    </Typography>
                                  </TableCell>

                                  <TableCell
                                    className="td-task"
                                    style={{ fontSize: "12px" }}
                                  >
                                    <Typography className="task-main">
                                      Task{" "}
                                      {
                                        story?.statusCounts?.["READT FOR UAT"]
                                          ?.task
                                      }
                                    </Typography>
                                    <Typography className="task-main">
                                      Bug{" "}
                                      {
                                        story?.statusCounts?.["READT FOR UAT"]
                                          ?.bug
                                      }
                                    </Typography>
                                  </TableCell>

                                  <TableCell
                                    className="td-task"
                                    style={{ fontSize: "12px" }}
                                  >
                                    <Typography className="task-main">
                                      Task {story?.statusCounts?.CLOSE?.task}
                                    </Typography>
                                    <Typography className="task-main">
                                      Bug {story?.statusCounts?.CLOSE?.bug}
                                    </Typography>
                                  </TableCell>
                                </TableRow>
                              ))}
                          </React.Fragment>
                        ))}
                    </TableBody>
                  </Table>
                </TableContainer>
                <TablePagination
                  className="pagination"
                  rowsPerPageOptions={[50, 75, 100]}
                  component="div"
                  count={storyList.length}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                />
              </Box>
            ) : (
              <>
                <StoryReportTwoSkeletonFile />
              </>
            )}
          </Box>
        </Box>
      </MDBCard>
    </div>
  );
};

export default StoryReport2;
